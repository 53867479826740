import { useToast } from '@abyss/web/hooks/useToast';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { User } from '@src/features/Users/components/User';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

import { FormModal } from './components/FormModal';
import { Table } from './components/Table';

/**
 * List
 *
 * Provides the user with a screen listing the existing tags.
 *
 * @returns {Element}
 * @constructor
 */
export function Tags() {
  const [showAll, setShowAll] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({});

  const { clearApiCache, useApiMutation, useApiQuery } = useApi();

  const [ListTags, { data, error, isFetching, isLoading, refetch }] = useApiQuery('ListTags');
  const [SaveTag] = useApiMutation('SaveTag');

  const { toast } = useToast();

  /**
   * handleSave
   *
   * Handles the saving of a tag.
   *
   * @param payload
   * @returns {Promise<void>}
   */
  const handleSave = async (payload = {}) => {
    const toastId = 'save-tag';

    toast.show({
      ariaLoadingLabel: 'Saving Tag',
      autoClose: false,
      id: `${toastId}-info`,
      isLoading: true,
      message: 'Tag is preparing to save.',
      title: 'Saving Tag...',
      variant: 'info',
    });

    await SaveTag(payload, {
      onError: () => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: `Unable to save tag.`,
          title: 'Tag Save Failed',
          variant: 'error',
        });
      },
      onSuccess: () => {
        clearApiCache(['ListTags']);
        refetch();
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-success`,
          message: `Tag has been saved.`,
          title: 'Saved Tag',
          variant: 'success',
        });
      },
    });
  };

  return (
    <ErrorHandler location="src/routes/private/Admin/Tags/List/List.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Flex alignItems="center" direction="row">
              <Heading offset={0}>Tags</Heading>
              <User.Capability attributes={['create']} resource="Admin:Tags">
                <Button
                  before={<IconSymbol icon="add" />}
                  css={{
                    marginLeft: themeConfiguration?.theme?.space?.md,
                  }}
                  data-testid="create-button"
                  isDisabled={isLoading || isFetching}
                  onClick={() => {
                    setIsFormModalOpen(true);
                    setCurrentEntity({});
                  }}
                  size="$sm"
                  variant="outline"
                >
                  Create
                </Button>
              </User.Capability>
            </Flex>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            {!showAll && (
              <Table
                error={error}
                handleSave={handleSave}
                headerLeft={
                  <ToggleSwitch
                    isChecked={showAll}
                    label="Show All Tags"
                    onChange={(event) => {
                      return setShowAll(event?.target?.checked);
                    }}
                  />
                }
                isLoading={isLoading || isFetching}
                refetch={refetch}
                requestArgs={{
                  categoryList: [],
                  page: 0,
                  size: 25,
                  sort: 'categoryCode,desc',
                  userDefined: true,
                }}
                requestFunction={ListTags}
                requestKey="ListTags"
                rows={data?.content || []}
                setCurrentEntity={setCurrentEntity}
                setIsFormModalOpen={setIsFormModalOpen}
                totalPages={data?.totalPages || 1}
                totalRecords={data?.totalElements || 0}
              />
            )}

            {showAll && (
              <Table
                error={error}
                handleSave={handleSave}
                headerLeft={
                  <ToggleSwitch
                    isChecked={showAll}
                    label="Show All Tags"
                    onChange={(event) => {
                      return setShowAll(event?.target?.checked);
                    }}
                  />
                }
                isLoading={isLoading || isFetching}
                refetch={refetch}
                requestArgs={{
                  categoryList: [],
                  page: 0,
                  size: 25,
                  sort: 'categoryCode,desc',
                }}
                requestFunction={ListTags}
                requestKey="ListTags"
                rows={data?.content || []}
                setCurrentEntity={setCurrentEntity}
                setIsFormModalOpen={setIsFormModalOpen}
                totalPages={data?.totalPages || 1}
                totalRecords={data?.totalElements || 0}
              />
            )}

            {isFormModalOpen && (
              <FormModal
                currentEntity={currentEntity}
                handleSave={handleSave}
                isOpen={isFormModalOpen}
                setCurrentEntity={setCurrentEntity}
                setIsOpen={setIsFormModalOpen}
              />
            )}
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
}
