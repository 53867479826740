import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';

/**
 * GetExportDetails
 *
 * @TODO Needs description.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetExportDetails = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/action-paths/${thePayload?.actionPathId}/extracts/latest`,
      },
      method: 'GET',
      params: { queryKey: theQueryKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/GetExportDetails.js -> GetExportDetails()', false, error);

    throw error;
  }
};
