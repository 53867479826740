import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { pick } from 'lodash';

/**
 * ListEventTypes
 *
 * Retrieves a list of event types from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListEventTypes = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePagination = pick(payload?.[1], ['page', 'size', 'sort']);

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': '/code-lists/categories/EventType',
      },
      method: 'GET',
      params: { ...{ queryKey: theQueryKey }, ...thePagination },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/ListEventTypes.js -> ListEventTypes()', false, error);

    throw error;
  }
};
