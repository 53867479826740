import { DateInput } from '@abyss/web/ui/DateInput';
import { Layout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Styles } from './includes/styles';

/**
 * DateRange
 *
 * Displays a date range input component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function DateRange(props) {
  const { defaultEndDate, defaultStartDate, isClearable, isRequired, maximumDate, minimumDate, onChange } = props;

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  /**
   * Sync component state with user supplied call back functions.
   */
  useEffect(() => {
    try {
      if (!isUndefined(onChange)) {
        (async () => {
          await onChange({ endDate, startDate });
        })();
      }
    } catch (error) {
      logger.error('src/components/DateRange/DateRange.jsx -> onChange()', false, error);
    }
  }, [startDate, endDate]);

  return (
    <ErrorHandler location="src/components/DateRange/DateRange.jsx">
      <Styles>
        <Layout.Group alignItems="bottom" alignLayout="left" grow space={themeConfiguration?.theme?.space?.md}>
          <DateInput
            excludeDate={(date) => {
              const timestamp = date.getTime();
              const endDateTimestamp = new Date(endDate).getTime();
              return timestamp >= endDateTimestamp;
            }}
            isClearable={isClearable}
            label="Date Range"
            maximumDate={maximumDate}
            minimumDate={minimumDate}
            model="dateRange.start"
            onChange={(date = '') => {
              setStartDate(date);
            }}
            onClear={() => {
              return setStartDate(null);
            }}
            subText=""
            validators={{ required: isRequired }}
            value={startDate}
          />
          <DateInput
            excludeDate={(date) => {
              const timestamp = date.getTime();
              const startDateTimestamp = new Date(startDate).getTime();
              return timestamp <= startDateTimestamp;
            }}
            hideLabel
            isClearable={isClearable}
            label=""
            maximumDate={maximumDate}
            minimumDate={minimumDate}
            model="dateRange.end"
            onChange={(date = '') => {
              setEndDate(date);
            }}
            onClear={() => {
              return setEndDate(null);
            }}
            subText=""
            validators={{ required: true }}
            value={endDate}
          />
        </Layout.Group>
      </Styles>
    </ErrorHandler>
  );
}

DateRange.propTypes = {
  defaultEndDate: PropTypes.string,
  defaultStartDate: PropTypes.string,
  isClearable: PropTypes.bool,
  isRequired: PropTypes.bool,
  maximumDate: PropTypes.string,
  minimumDate: PropTypes.string,
  onChange: PropTypes.func,
};

DateRange.defaultProps = {
  defaultEndDate: null,
  defaultStartDate: null,
  isClearable: true,
  isRequired: false,
  maximumDate: null,
  minimumDate: null,
  onChange: null,
};
