import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { Filters } from '@src/features/Criteria/components/misc/Filters';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Widget: AdditionalCriteria
 *
 * Displays a list of additional criteria filters to apply to an api request.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function AdditionalCriteria(props) {
  const { filterKey, form, isCollapsible } = props;

  return (
    <ErrorHandler location="src/features/Criteria/components/widgets/AdditionalCriteria/AdditionalCriteria.jsx">
      <Widget
        backgroundColor="var(--abyss-colors-gray1)"
        collapsible={isCollapsible}
        icon="control_point_duplicate"
        title="Additional Criteria"
      >
        <Filters filterKey={filterKey} form={form} />
      </Widget>
    </ErrorHandler>
  );
}

AdditionalCriteria.propTypes = {
  filterKey: PropTypes.string,
  form: PropTypes.shape({
    control: PropTypes.shape({}),
    setValue: PropTypes.func,
  }).isRequired,
  isCollapsible: PropTypes.bool,
};

AdditionalCriteria.defaultProps = {
  filterKey: 'filters',
  isCollapsible: false,
};
