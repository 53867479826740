import { CurrentEnvironment } from '@src/components/CurrentEnvironment';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { Header } from './sections/Header';
import { Main } from './sections/Main';

/**
 * Layout: Application
 *
 * Glues together the various pieces of the layout.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Application(props) {
  const { children } = props;

  const stickyRef = useRef(null);

  return (
    <ErrorHandler location="src/layouts/Application/Application.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <div className="app-layout" ref={stickyRef} style={{ height: '100%', position: 'relative' }}>
          <CurrentEnvironment />
          <Header stickyRef={stickyRef} />
          <Main>{children}</Main>
        </div>
      </motion.div>
    </ErrorHandler>
  );
}

Application.propTypes = {
  children: PropTypes.node.isRequired,
};
