import { useApi } from '@src/context/Api';
import { useMemo } from 'react';

/**
 * useSearch
 *
 * Makes API requests to retrieve the assets required for the wizard experience.
 *
 * @returns {{handleSearch: *, errorObject: *, isError: *, isSearching, searchResults: *}}
 */
export const useSearch = () => {
  const { useApiQuery } = useApi();

  const [GetRiskRecord, { data: searchResults, error, isError, isFetching, isLoading }] = useApiQuery('GetRiskRecord', {
    excludedHttpCodes: [404],
  });

  return useMemo(() => {
    return {
      errorObject: error,
      handleSearch: GetRiskRecord,
      isError,
      isSearching: isLoading || isFetching,
      searchResults,
    };
  }, [isLoading, isFetching, searchResults]);
};
