import { config } from '@abyss/web/tools/config';
import axios from 'axios';

/**
 * RemoteLogger
 *
 * Used to output a console message server-side so that Data Dog can capture the output.
 *
 * @param payload
 * @returns {Promise<*>}
 * @constructor
 */
export const RemoteLogger = async (payload) => {
  let response = {};

  try {
    const remoteResponse = await axios.request({
      baseURL: config('API_URL'),
      data: payload,
      method: 'POST',
      url: '/logger',
    });

    response = remoteResponse.data;
  } catch (error) {
    console.error('src/requests/mutations/RemoteLogger.js -> RemoteLogger()', error);
    response = error;
  }

  return response;
};
