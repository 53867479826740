import { Text } from '@abyss/web/ui/Text';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * RiskScore
 *
 * Displays a letter representing the risk score with a color that corresponds to the risk code.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function RiskScore(props) {
  const { riskCode } = props;

  const codes = riskCode?.split('-');
  const letter = codes.shift().toUpperCase();

  return (
    <ErrorHandler location="src/routes/private/Analysis/EidSearch/components/Details/components/RiskScore/RiskScore.jsx">
      {letter === 'R' && (
        <Text color={themeConfiguration?.theme?.colors?.error1} fontWeight="bold">
          <div>{letter} (Red)</div>
        </Text>
      )}

      {letter === 'Y' && (
        <Text color={themeConfiguration?.theme?.colors?.warning1} fontWeight="bold">
          <div>{letter} (Yellow)</div>
        </Text>
      )}

      {letter === 'G' && (
        <Text color={themeConfiguration?.theme?.colors?.success1} fontWeight="bold">
          {letter} (Green)
        </Text>
      )}
    </ErrorHandler>
  );
}

RiskScore.propTypes = {
  riskCode: PropTypes.string,
};

RiskScore.defaultProps = {
  riskCode: '',
};
