import { useForm } from '@abyss/web/hooks/useForm';
import { Divider } from '@abyss/web/ui/Divider';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Filters } from '@src/features/Criteria/components/misc/Filters';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { NameField } from '../../fields/Name';

/**
 * Form: Create
 *
 * This form is used to create a new set of common criteria.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Create(props) {
  const { handleClose, handleSave, setFocusedEntity } = props;

  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    filters: [{ column: '', condition: '', value: '' }],
    id: '',
    isActive: true,
    name: '',
  };

  const form = useForm({ defaultValues });

  const { isDirty, isSubmitting, isValid } = form.formState;

  /**
   * handleSubmit
   *
   * Calls a remote API to save the common criteria.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = useCallback(
    async (submittedValues) => {
      if (!isLoading && isValid && !isSubmitting && isDirty) {
        setIsLoading(true);
        await handleSave(submittedValues);
        setFocusedEntity(submittedValues?.name);
        setIsLoading(false);
      }
    },
    [isLoading, isDirty, isValid, isSubmitting, form?.formState?.errors]
  );

  return (
    <ErrorHandler location="src/routes/private/Admin/CommonCriteria/components/forms/Create/Create.jsx">
      <FormProvider autoComplete="off" onSubmit={handleSubmit} state={form}>
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <NameField form={form} showButton={false} showLabel />
          </Grid.Col>
          <Grid.Col span={{ xs: '100%' }}>
            <Filters form={form} isLoading={isLoading} />
          </Grid.Col>
          <Grid.Col span={{ xs: '100%' }}>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button isDisabled={!isDirty || !isValid || isLoading} type="submit" variant="solid">
                Create
              </Button>
            </Layout.Group>
          </Grid.Col>
        </Grid>
      </FormProvider>
    </ErrorHandler>
  );
}

Create.propTypes = {
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  setFocusedEntity: PropTypes.func,
};

Create.defaultProps = {
  handleClose: () => {},
  handleSave: () => {},
  setFocusedEntity: () => {},
};
