import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '#layout-dashboard': {
    '#layout-dashboard-header': {
      '#layout-dashboard-header-left': {
        '.abyss-descriptors-error-message': {
          bottom: 'initial',
        },
        position: 'relative',
        width: '280px',
      },
      '#layout-dashboard-header-right': {
        padding: 'var(--abyss-space-md)',
        paddingBottom: '0px',
        width: 'calc(100% - 280px)',
      },
      '#layout-dashboard-header-title': {
        marginBottom: 'var(--abyss-space-sm)',
      },
    },
    '#layout-dashboard-main': {
      '.abyss-indicator-root': {
        '.abyss-indicator-container': {
          '.abyss-indicator-label': {
            color: 'var(--abyss-colors-info1)',
            fontWeight: 'bold',
          },
          background: 'var(--abyss-colors-info2)',
          borderColor: 'var(--abyss-colors-info2)',
        },
      },
      '.widget': {
        marginBottom: 'var(--abyss-space-lg)',
      },
      '#layout-dashboard-header-summary:not(:empty)': {
        marginBottom: 'var(--abyss-space-md)',
      },
      '> div': {
        width: '100%',
      },
      width: '100%',
    },
    '#layout-dashboard-sidebar': {
      '.abyss-drawer-menu-container': {
        '&[class*="isOpen-true"]': {
          width: '280px',
        },
      },
    },
    '> .abyss-grid-col': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    margin: 0,
  },
});
