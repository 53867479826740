import { config } from '@abyss/web/tools/config';
import { Box } from '@abyss/web/ui/Box';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { AssignTags, AssociatedEids, AttachActionPaths, RiskCode, RiskScore } from './components';

/**
 * Details
 *
 * Displays a summary of the EID search results.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Details(props) {
  const { actionPaths, associatedEids, eid, handleSearch, ireRiskRecord, riskCodes, tags } = props;

  const [isAssociatedEidsOpen, setIsAssociatedEidsOpen] = useState(false);
  const [isAttachActionPathsOpen, setIsAttachActionPathsOpen] = useState(false);
  const [isAssignTagsOpen, setIsAssignTagsOpen] = useState(false);

  const { hasPermission } = useCurrentUser();

  const canAttachActionPaths = hasPermission('ActionPaths', 'attach');
  const canAssignTags = hasPermission('Analysis:EidSearch', 'assignTags');

  return (
    <ErrorHandler location="src/routes/private/Analysis/EidSearch/components/Details/Details.jsx">
      <Grid css={{ marginLeft: '0px', marginRight: 'calc(var(--abyss-space-md) * -1)' }}>
        <Grid.Col
          css={{ paddingLeft: 0, paddingRight: 0 }}
          span={{
            xs: '100%',
          }}
        >
          <Box color="$tint1" css={{ padding: 'var(--abyss-space-lg)' }}>
            <Grid>
              <Grid.Col
                css={{
                  margin: '0px auto',
                  paddingBottom: 'var(--abyss-space-lg)',
                  paddingTop: 'var(--abyss-space-lg)',
                }}
                span={{ lg: '100%', md: '100%', sm: '100%', xl: '60%', xs: '100%' }}
              >
                <Grid>
                  <Grid.Col
                    css={{
                      borderRight: '1px solid var(--abyss-colors-gray3);',
                      paddingBottom: 'var(--abyss-space-md)',
                      paddingTop: 'var(--abyss-space-md)',
                    }}
                    span={{ xs: '25%' }}
                  >
                    <Layout.Stack alignLayout="center">
                      <div>EID</div>
                      <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                        <Text fontWeight="bold">{eid}</Text>
                      </div>
                    </Layout.Stack>
                  </Grid.Col>

                  <Grid.Col
                    css={{
                      borderRight: '1px solid var(--abyss-colors-gray3);',
                      paddingBottom: 'var(--abyss-space-md)',
                      paddingTop: 'var(--abyss-space-md)',
                    }}
                    span={{ xs: '25%' }}
                  >
                    <Layout.Stack alignLayout="center">
                      <div>Risk Score</div>
                      <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                        <RiskScore riskCode={ireRiskRecord?.riskScore} />
                      </div>
                    </Layout.Stack>
                  </Grid.Col>

                  <Grid.Col
                    css={{
                      borderRight: '1px solid var(--abyss-colors-gray3);',
                      paddingBottom: 'var(--abyss-space-md)',
                      paddingTop: 'var(--abyss-space-md)',
                    }}
                    span={{ xs: '25%' }}
                  >
                    <Layout.Stack alignLayout="center">
                      <div>Risk Code</div>
                      <div style={{ marginTop: themeConfiguration?.theme?.space?.xs }}>
                        <Text fontWeight="bold">
                          <RiskCode riskCode={ireRiskRecord?.riskScore} riskCodes={riskCodes} />
                        </Text>
                      </div>
                    </Layout.Stack>
                  </Grid.Col>

                  <Grid.Col
                    css={{ paddingBottom: 'var(--abyss-space-md)', paddingTop: 'var(--abyss-space-md)' }}
                    span={{ xs: '25%' }}
                  >
                    <Flex
                      alignContent="center"
                      alignItems="center"
                      css={{ height: '100%' }}
                      direction="column"
                      justify="center"
                    >
                      <DropdownMenu
                        after={<IconSymbol icon="keyboard_arrow_down" />}
                        label="Actions"
                        menuItems={[
                          {
                            icon: <IconSymbol icon="open_in_new" variant="outlined" />,
                            onClick: () => {
                              window?.open(`${config('HCM_URL')}/${eid}/`, '_blank').focus();
                            },
                            title: 'Link to HCM',
                          },
                          {
                            disabled: isUndefined(associatedEids) || isEmpty(associatedEids),
                            icon: <IconSymbol icon="visibility" variant="outlined" />,
                            onClick: () => {
                              setIsAssociatedEidsOpen(true);
                            },
                            title: "View Associated EID's",
                          },
                          {
                            disabled: !canAttachActionPaths,
                            icon: <IconSymbol icon="attach_file" variant="outlined" />,
                            onClick: () => {
                              setIsAttachActionPathsOpen(true);
                            },
                            title: 'Attach Action Paths',
                          },
                          {
                            disabled: !canAssignTags,
                            icon: <IconSymbol icon="sell" variant="outlined" />,
                            onClick: () => {
                              setIsAssignTagsOpen(true);
                            },
                            title: 'Assign Tags',
                          },
                        ]}
                        outline
                      />
                    </Flex>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
          </Box>
        </Grid.Col>
      </Grid>

      {isAssociatedEidsOpen && (
        <AssociatedEids
          associatedEids={associatedEids}
          isOpen={isAssociatedEidsOpen}
          setIsOpen={setIsAssociatedEidsOpen}
        />
      )}

      {canAttachActionPaths && isAttachActionPathsOpen && (
        <AttachActionPaths
          eid={eid}
          handleSearch={handleSearch}
          isOpen={isAttachActionPathsOpen}
          recordActionPaths={actionPaths}
          setIsOpen={setIsAttachActionPathsOpen}
        />
      )}

      {canAssignTags && isAssignTagsOpen && (
        <AssignTags
          eid={eid}
          handleSearch={handleSearch}
          isOpen={isAssignTagsOpen}
          recordTags={tags}
          setIsOpen={setIsAssignTagsOpen}
        />
      )}
    </ErrorHandler>
  );
}

Details.propTypes = {
  actionPaths: PropTypes.arrayOf(PropTypes.shape({})),
  associatedEids: PropTypes.arrayOf(PropTypes.string),
  eid: PropTypes.string,
  handleSearch: PropTypes.func,
  ireRiskRecord: PropTypes.shape({
    riskScore: PropTypes.string,
  }),
  riskCodes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string,
      riskScore: PropTypes.number,
    })
  ),
  tags: PropTypes.arrayOf(PropTypes.string),
};

Details.defaultProps = {
  actionPaths: [],
  associatedEids: [],
  eid: '',
  handleSearch: () => {},
  ireRiskRecord: {},
  riskCodes: [],
  tags: [],
};
