import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';

/**
 * RiskRecordActionPathAssociation
 *
 * Associates EID's to Action Paths and vice versa.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const RiskRecordActionPathAssociation = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: thePayload,
      headers: {
        'x-api-endpoint': '/risk-records/manual/associate',
      },
      method: 'POST',
      params: { mutationKey: theMutationKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error(
      'src/requests/mutations/RiskRecordActionPathAssociation.js -> RiskRecordActionPathAssociation()',
      false,
      error
    );

    throw error;
  }
};
