import Admin from './Admin.json';
import Analyst from './Analyst.json';
import Dashboard from './Dashboard.json';
import Operator from './Operator.json';

export const permissions = {
  Admin,
  Analyst,
  Dashboard,
  Operator,
};

/**
 * getRoles
 *
 * Returns the roles.
 *
 * @returns {string[]}
 */
export function getRoles() {
  return Object.keys(permissions);
}

/**
 * getRolesWithAttribute
 *
 * Returns the roles that have the specified attribute.
 *
 * @param resource
 * @param action
 * @param value
 * @returns {*[]}
 */
export function getRolesWithAttribute(resource = '', action = '', value = null) {
  const theRoles = [];
  const roles = getRoles();

  roles.forEach((role) => {
    if (permissions[role]?.[resource]?.[action] === value) {
      theRoles.push(role);
    }
  });

  return theRoles;
}
