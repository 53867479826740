import { defaultValues, useUserContext } from '@src/features/Users/context';
import { isEmpty, isUndefined } from 'lodash';

/**
 * Hook: useCurrentUser
 *
 * Retrieves the current user's information from the IDP.
 *
 * @returns {{emailAddress: string, displayName: string, roles: *[], tenantId: string, localAccountId: string,
 *   homeAccountId: string}}
 */
export const useCurrentUser = () => {
  const userContext = useUserContext();

  if (isUndefined(userContext) || isEmpty(userContext)) {
    return defaultValues;
  }

  return userContext;
};
