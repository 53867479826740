import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes/Routes';
import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Styles } from './includes/styles';

/**
 * SubMenu
 *
 * Provides the user with a sub menu.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function SubMenu(props) {
  const { items } = props;

  const { currentRoute } = useRoutesContext();

  const theItems = useMemo(() => {
    return orderBy(items, ['menuOrder'], ['asc']);
  }, [items]);

  return (
    <ErrorHandler location="src/layouts/Application/sections/Header/components/Navigation/components/SubMenu/SubMenu.jsx">
      {!isEmpty(theItems) && (
        <Styles>
          <NavMenuPrimitives.Columns>
            <NavMenuPrimitives.Column>
              {theItems?.map((subItem) => {
                if (subItem?.showInNavigation === true) {
                  return (
                    <NavMenuPrimitives.MenuItem
                      className={
                        currentRoute?.path === subItem?.path || currentRoute?.path?.includes(subItem?.path)
                          ? 'active'
                          : ''
                      }
                      href={subItem?.path}
                      key={`${subItem?.accessor}-childItem`}
                      title={subItem?.navigationLabel}
                    />
                  );
                }

                return <React.Fragment key={`${subItem?.accessor}-childItem`} />;
              })}
            </NavMenuPrimitives.Column>
          </NavMenuPrimitives.Columns>
        </Styles>
      )}
    </ErrorHandler>
  );
}

PropTypes.SubMenu = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
