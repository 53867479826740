import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Text } from '@abyss/web/ui/Text';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * MenuItem
 *
 * Renders an icon and title for usage in a navigation component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function MenuItem(props) {
  const { iconName, title } = props;

  return (
    <ErrorHandler location="src/layouts/Application/sections/Header/components/Navigation/components/MenuItem/MenuItem.jsx">
      <div className="nav-icon-wrap">
        <IconSymbol icon={iconName} />
        <Text>{title}</Text>
      </div>
    </ErrorHandler>
  );
}

MenuItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
