import { useToast } from '@abyss/web/hooks/useToast';
import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { logger } from '@src/includes/logger';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './includes/styles';

/**
 * RemoveModal
 *
 * Displays a modal popup to remove EID's from the list of associated EID's with the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function RemoveModal(props) {
  const { actionPath, eids, isOpen, refetch, setEids, setIsOpen } = props;

  const { toast } = useToast();

  const { clearApiCache, useApiMutation } = useApi();
  const [RiskRecordActionPathDisassociation] = useApiMutation('RiskRecordActionPathDisassociation');

  /**
   * handleClose
   *
   * Closes the modal popup and resets the EID.
   */
  const handleClose = () => {
    setEids([]);
    setIsOpen(false);
  };

  /**
   * handleDisassociation
   *
   * Sends an api request to disassociate the EID's from the action path.
   *
   * @returns {Promise<void>}
   */
  const handleDisassociation = async () => {
    try {
      const toastId = 'eid-disassociation';
      toast.show({
        ariaLoadingLabel: "Disassociating EID's From Action Path",
        autoClose: false,
        id: `${toastId}-info`,
        isLoading: true,
        message: "Action Path is preparing to disassociate the EID's.",
        title: "Disassociating EID's From Action Path...",
        variant: 'info',
      });

      handleClose();

      const payload = {
        actionPaths: [actionPath?.id],
        eids,
      };

      await RiskRecordActionPathDisassociation(payload, {
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            message: "Unable to disassociate EID's.",
            title: "Disassociating EID's Failed",
            variant: 'error',
          });
        },
        onSuccess: async () => {
          await clearApiCache(['ListRiskRecords']);
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            message: "EID's have been disassociated.",
            title: "Disassociated EID's From Action Path",
            variant: 'success',
          });

          setTimeout(() => {
            refetch();
          }, 750);
        },
      });
    } catch (error) {
      logger.error(
        'src/features/Risk/components/widgets/AssociatedEids/components/RemoveModal/RemoveModal.jsx -> handleDisassociation',
        false,
        error
      );
    }
  };

  return (
    <ErrorHandler location="src/features/Risk/components/widgets/AssociatedEids/components/RemoveModal/RemoveModal.jsx">
      <Modal
        footer={
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button onClick={handleDisassociation} variant="destructive">
                Remove Association
              </Button>
            </Layout.Group>
          </Modal.Section>
        }
        isOpen={isOpen}
        onClose={handleClose}
        title="Confirm Association Removal"
      >
        <Styles>
          <Modal.Section>
            {eids?.length > 1 ? (
              <React.Fragment>
                Are you sure you want to remove the following associated EID's: <strong>{eids?.join(', ')}</strong> ?
              </React.Fragment>
            ) : (
              <React.Fragment>
                Are you sure you want to remove associated EID: <strong>{eids?.[0]}</strong> ?
              </React.Fragment>
            )}
          </Modal.Section>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
}

RemoveModal.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
  }),
  eids: PropTypes.arrayOf(PropTypes.string),
  isOpen: PropTypes.bool,
  refetch: PropTypes.func,
  setEids: PropTypes.func,
  setIsOpen: PropTypes.func,
};

RemoveModal.defaultProps = {
  actionPath: {},
  eids: [],
  isOpen: false,
  refetch: () => {},
  setEids: () => {},
  setIsOpen: () => {},
};
