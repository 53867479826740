import { dayjs } from '@abyss/web/tools/dayjs';
import { Link } from '@abyss/web/ui/Link';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { SourcesTooltip } from '@src/components/common/tooltips';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { isUndefined, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';

/**
 * Table: Events
 *
 * Displays a list of events associated with an action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Table(props) {
  const { assets, rows } = props;

  /**
   * renderCellTitle
   *
   * Displays the title of the event within a cell.
   *
   * @param row
   * @param cell
   * @returns {React.JSX.Element|*}
   */
  const renderCellTitle = ({ cell, row }) => {
    return <Link href={`/notifications/events/${row?.original?.eventId}`}>{cell?.value}</Link>;
  };

  /**
   * renderCellDates
   *
   * Displays the date of the event within a cell.
   *
   * @param cell
   * @returns {Element}
   */
  const renderCellDates = ({ row }) => {
    return (
      <React.Fragment>
        {dayjs(row?.original?.startDate).format('MM/DD/YYYY')} - {dayjs(row?.original?.endDate).format('MM/DD/YYYY')}
      </React.Fragment>
    );
  };

  /**
   * renderCellImpactedSystems
   *
   * Displays the impacted systems within a cell in csv format, with a tooltip on hover when more than one.
   *
   * @param row
   * @param cell
   * @returns {React.JSX.Element|*}
   */
  const renderCellImpactedSystems = ({ cell, row }) => {
    if (row?.original?.impactedSystems?.length > 1) {
      const sources = [];

      row?.original?.impactedSystems?.forEach((impactedSystem) => {
        const system = assets?.ListImpactedSystems?.data?.find((asset) => {
          return asset?.codeId === impactedSystem;
        });

        if (!isUndefined(system)) {
          sources.push(system?.codeDesc);
        }
      });

      return (
        <Tooltip content={<SourcesTooltip sources={sources} />} placement="auto">
          <span className="truncate">{sources.join(', ')}</span>
        </Tooltip>
      );
    }

    return cell.value;
  };

  /**
   * renderCellEventType
   *
   * Displays the event type within a cell.
   *
   * @param cell
   * @returns {*}
   */
  const renderCellEventType = ({ cell }) => {
    const theEventType = assets?.ListEventTypes?.data?.find((eventType) => {
      return eventType?.codeId === cell?.value;
    });

    return theEventType?.codeDesc;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.Header === 'Date(s)') {
        column.Cell = renderCellDates;
      }

      if (column.Header === 'Impacted Systems') {
        column.Cell = renderCellImpactedSystems;
      }

      if (column.Header === 'Title') {
        column.Cell = renderCellTitle;
      }

      if (column.Header === 'Event Type') {
        column.Cell = renderCellEventType;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/Risk/components/widgets/Events/components/Table/Table.jsx">
      <TableComponent
        {...{
          accessor: 'events',
          columns,
          configuration,
          rows,
        }}
      />
    </ErrorHandler>
  );
}

Table.propTypes = {
  assets: PropTypes.shape({
    ListEventTypes: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
    ListImpactedSystems: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
  }),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.string,
      eventId: PropTypes.string,
      eventType: PropTypes.string,
      impactedSystems: PropTypes.arrayOf(PropTypes.string),
      startDate: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

Table.defaultProps = {
  assets: {
    ListEventTypes: {
      data: [],
    },
    ListImpactedSystems: {
      data: [],
    },
  },
  rows: [],
};
