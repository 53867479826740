import { useToast } from '@abyss/web/hooks/useToast';
import { logger } from '@src/includes/logger';
import { requests } from '@src/requests';
import { useMutation } from '@tanstack/react-query';
import { isFunction, isUndefined, merge } from 'lodash';

/**
 * Hook: useApiMutation
 *
 * Dynamic api request function based on mutation key to make on-demand api mutations.
 *
 * @param mutationKey
 * @param options
 * @returns {*[]}
 */
export function useApiMutation(mutationKey = '', options = {}) {
  const { toast } = useToast();

  let mutationFunction;

  if (!isUndefined(requests?.mutations?.[mutationKey])) {
    mutationFunction = requests?.mutations?.[mutationKey];
  }

  const theMutation = merge(
    {},
    {
      excludedHttpCodes: [],
      mutationFn: async (variables) => {
        try {
          let response;

          if (isUndefined(mutationFunction)) {
            throw new Error(`Request not found for mutation key: ${mutationKey}`);
          }

          if (isFunction(mutationFunction)) {
            response = await mutationFunction([mutationKey, variables]);
          }

          return response;
        } catch (errorObject) {
          const { error } = errorObject;
          const excludedHttpCodes = options?.excludedHttpCodes || [];

          if (!excludedHttpCodes?.includes(error?.statusCode)) {
            toast.show({
              autoClose: false,
              id: `useApiMutation-${mutationKey}`,
              message: error?.message,
              title: `API Error - ${mutationKey}`,
              variant: 'error',
            });
          }

          logger.error('src/context/Api/Api.jsx -> useApiMutation', false, error);
          throw errorObject;
        }
      },
      mutationKey: [mutationKey],
    },
    options
  );

  const mutation = useMutation(theMutation);

  return [mutation?.mutateAsync, mutation];
}
