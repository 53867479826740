import { useRouter } from '@abyss/web/hooks/useRouter';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { useCommonCriteria } from '@src/features/Criteria/hooks/useCommonCriteria';
import { useCommonCriteriaVersions } from '@src/features/Criteria/hooks/useCommonCriteriaVersions';
import { abbrNum } from '@src/includes/functions';
import { isEmpty, isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { WarningMessage } from './components/WarningMessage';
import { Styles } from './includes/styles';

/**
 * Modal: Activation
 *
 * Prompts the user with a popup window asking them if they wish to cancel or continue activating the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Activation(props) {
  const { actionPath, handleActivation, isOpen, refetch, setActionPath, setIsOpen } = props;

  const [isChecked, setChecked] = useState(false);

  const router = useRouter();

  const { useApiQuery } = useApi();
  const [GetActionPathCount, { data: riskRecordCount, isFetched, isFetching, isLoading }] =
    useApiQuery('GetActionPathCount');

  const {
    criteriaList,
    data: commonCriteria,
    fetch: fetchCriteria,
    isLoading: isCriteriaLoading,
  } = useCommonCriteria();

  const {
    data: commonCriteriaVersions,
    fetch: fetchCriteriaVersions,
    isLoading: isCriteriaVersionsLoading,
    versionsList,
  } = useCommonCriteriaVersions();

  /**
   * retrieve common criteria saved to the action path
   */
  useEffect(() => {
    if (!isCriteriaLoading && isEmpty(commonCriteria) && !isUndefined(actionPath?.criteria)) {
      fetchCriteria(actionPath?.criteria);
    }
  }, [isCriteriaLoading, commonCriteria, actionPath]);

  /**
   * retrieve common criteria versions saved to the action path
   */
  useEffect(() => {
    if (!isCriteriaVersionsLoading && isEmpty(commonCriteriaVersions) && !isUndefined(actionPath?.criteria)) {
      fetchCriteriaVersions(actionPath?.criteria);
    }
  }, [isCriteriaVersionsLoading, commonCriteriaVersions, actionPath]);

  /**
   * fetch the number of records associated with the action path
   */
  useEffect(() => {
    if (!isFetched && isUndefined(riskRecordCount)) {
      GetActionPathCount({ actionPathId: actionPath?.id });
    }
  }, [isFetched, riskRecordCount]);

  /**
   * loop through criteriaList and versionsList to determine if the versions are a mismatch
   * @type {*[]}
   */
  const needsReview = useMemo(() => {
    const items = {
      entrance: [],
      exit: [],
    };

    criteriaList.forEach((criteria) => {
      const version = versionsList.find((item) => {
        return criteria?.id === item?.commonCriteriaId;
      });

      if (!isUndefined(version) && criteria?.activeVersionNbr !== version?.version) {
        if (criteria?.context.includes('entrance')) {
          items.entrance.push(criteria);
        }

        if (criteria?.context.includes('exit')) {
          items.exit.push(criteria);
        }
      }
    });

    if (isEmpty(items.entrance) && isEmpty(items.exit)) {
      return {};
    }

    return items;
  }, [criteriaList, versionsList]);

  /**
   * handleActivate
   *
   * Sends the api request to activate the action path.
   *
   * @returns {Promise<void>}
   */
  const handleActivate = async () => {
    setIsOpen(false);
    setActionPath({});
    await handleActivation({ ...actionPath, ...{ status: 'ACTIVE' } }, refetch);
  };

  /**
   * handleClose
   *
   * Highlights the row in the table for the action path which was requested to activate, but the user backed out. The
   * goal is to make it easy to identify which row in the table the user attempted to take action on, in-case they
   * change their mind and want to continue with the same one again.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    setIsOpen(false);
    setActionPath({});
    router.navigate(`/`);
    router.navigate(`/action-paths?highlight=${actionPath?.id}`);
  };

  let count = riskRecordCount;

  if (riskRecordCount > 0) {
    count = abbrNum(riskRecordCount);
  }

  return (
    <ErrorHandler location="src/features/ActionPaths/components/modals/Activation/Activation.jsx">
      <Modal
        footer={
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button
                isDisabled={
                  isLoading ||
                  isFetching ||
                  isNull(riskRecordCount) ||
                  (riskRecordCount === 0 && !isChecked) ||
                  !isEmpty(needsReview)
                }
                onClick={handleActivate}
                variant="solid"
              >
                Activate
              </Button>
            </Layout.Group>
          </Modal.Section>
        }
        isOpen={isOpen}
        onClose={handleClose}
        title="Confirm Activation"
      >
        <Styles>
          <Modal.Section>
            {!isEmpty(needsReview) && (
              <React.Fragment>
                {needsReview?.entrance?.map((theCriteria) => {
                  return (
                    <WarningMessage
                      actionPath={actionPath}
                      context="entrance"
                      criteria={theCriteria}
                      key={`entrance-${theCriteria?.id}`}
                    />
                  );
                })}
                {needsReview?.exit?.map((theCriteria, index) => {
                  return (
                    <WarningMessage
                      actionPath={actionPath}
                      context="exit"
                      criteria={theCriteria}
                      key={`exit-${theCriteria?.id}`}
                    />
                  );
                })}
              </React.Fragment>
            )}

            <Layout.Group>
              <div>The number of records associated with this Action Path is:</div>
              <div>
                <strong>
                  {isLoading || isFetching || isNull(count) ? (
                    <LoadingSpinner ariaLoadingLabel="Loading..." isLoading size="$sm" />
                  ) : (
                    <React.Fragment>{count}</React.Fragment>
                  )}
                </strong>
              </div>
            </Layout.Group>

            {count === 0 && (
              <Checkbox
                isChecked={isChecked}
                label="Continue with 0 records"
                onChange={(event) => {
                  return setChecked(event?.target?.checked);
                }}
              />
            )}
          </Modal.Section>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
}

Activation.propTypes = {
  actionPath: PropTypes.shape({
    criteria: PropTypes.shape({
      entrance: PropTypes.shape({
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
        commonIds: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    id: PropTypes.string,
  }),
  handleActivation: PropTypes.func,
  isOpen: PropTypes.bool,
  refetch: PropTypes.func,
  setActionPath: PropTypes.func,
  setIsOpen: PropTypes.func,
};

Activation.defaultProps = {
  actionPath: {
    criteria: {
      entrance: {
        commonCriteriaVersionsIds: [],
        commonIds: [],
      },
    },
    id: '',
  },
  handleActivation: () => {},
  isOpen: false,
  refetch: () => {},
  setActionPath: () => {},
  setIsOpen: () => {},
};
