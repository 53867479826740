import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';

/**
 * RefreshEIMP
 *
 * Refresh EIMP data by putting the EID onto the stream for re-processing
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const RefreshEIMP = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: {},
      headers: {
        'x-api-endpoint': `/eimp/${thePayload?.eid}/refresh`,
      },
      method: 'PUT',
      params: { mutationKey: theMutationKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/mutations/RefreshEIMP.js -> RefreshEIMP()', false, error);

    throw error;
  }
};
