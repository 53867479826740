import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { ErrorHandler } from '@src/components/ErrorHandler';
import React from 'react';

import { HeaderSkeleton } from './components/Skeleton';

/**
 * Section: Header
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Header(props) {
  const { components, hasData, isFetching, isLoading, title } = props;

  const { LeftComponent, RightComponent } = components;

  return (
    <ErrorHandler location="src/layouts/Dashboard/sections/Header/Header.jsx">
      <div id="layout-dashboard-header">
        <Flex>
          <div id="layout-dashboard-header-left">
            <div id="layout-dashboard-header-title">
              <Heading offset={0}>{title}</Heading>
            </div>
            {LeftComponent}
          </div>
          {!isLoading && (
            <div id="layout-dashboard-header-right">
              {isFetching ? (
                <HeaderSkeleton />
              ) : (
                <React.Fragment>
                  {hasData && (
                    <Grid>
                      <Grid.Col
                        span={{
                          xs: '100%',
                        }}
                      >
                        {RightComponent}
                      </Grid.Col>
                    </Grid>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </Flex>
      </div>
    </ErrorHandler>
  );
}

Header.propTypes = {};

Header.defaultProps = {};
