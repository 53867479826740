import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { Divider } from '@abyss/web/ui/Divider';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInputArea } from '@abyss/web/ui/TextInputArea';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { logger } from '@src/includes/logger';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import fields from './includes/fields.json';

const { eids } = fields;

/**
 * AddModal
 *
 * Displays a modal popup to add EID's to the list of associated EID's with the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function AddModal(props) {
  const { actionPath, isOpen, refetch, setIsOpen } = props;

  const { toast } = useToast();

  const { clearApiCache, useApiMutation } = useApi();
  const [RiskRecordActionPathAssociation] = useApiMutation('RiskRecordActionPathAssociation', {
    excludedHttpCodes: [400],
  });

  const defaultValues = {
    eids: [],
  };

  const form = useForm({ defaultValues });

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    form?.reset(defaultValues, {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: false,
      keepIsValid: false,
      keepSubmitCount: true,
      keepTouched: false,
      keepValues: false,
    });

    return setIsOpen(false);
  };

  /**
   * handleAssociation
   *
   * Sends an api request to associate the EID's to the action path.
   *
   * @param theEids
   * @returns {Promise<void>}
   */
  const handleAssociation = async (theEids = []) => {
    try {
      const toastId = 'eid-association';
      toast.show({
        ariaLoadingLabel: "Associating EID's to Action Path",
        autoClose: false,
        id: `${toastId}-info`,
        isLoading: true,
        message: "Action Path is preparing to associate the EID's.",
        title: "Associating EID's to Action Path...",
        variant: 'info',
      });

      const payload = {
        actionPaths: [actionPath?.id],
        eids: theEids,
      };

      await RiskRecordActionPathAssociation(payload, {
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            message: "Unable to associate EID's.",
            title: "Associating EID's Failed",
            variant: 'error',
          });
        },
        onSuccess: (response) => {
          clearApiCache(['ListRiskRecords']);
          toast.hide(`${toastId}-info`);

          if (response?.errorList?.length !== theEids?.length) {
            toast.show({
              id: `${toastId}-success`,
              message: "EID's has been Associated.",
              title: "Associated EID's to Action Path",
              variant: 'success',
            });
          }

          if (!isEmpty(response?.errorList)) {
            toast.show({
              autoClose: false,
              id: `${toastId}-partial-error`,
              message: (
                <Layout.Stack alignItems="left">
                  {response?.errorList?.map((error) => {
                    return <div key={error}>{error}</div>;
                  })}
                </Layout.Stack>
              ),
              title: "Failed to Associate Some EID's",
              variant: 'error',
            });
          }

          setTimeout(() => {
            refetch();
          }, 750);
        },
      });
    } catch (error) {
      logger.error(
        'src/features/Risk/components/widgets/AssociatedEids/components/AddModal/AddModal.jsx -> handleAssociation',
        false,
        error
      );
    }
  };

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @param submittedValues
   * @returns {Promise<void>}
   */
  const handleSubmit = async (submittedValues) => {
    await handleClose();
    const theEids = submittedValues?.eids?.split(', ');
    await handleAssociation(theEids);

    form?.reset(defaultValues, {
      keepDirty: false,
      keepDirtyValues: false,
      keepErrors: false,
      keepIsValid: false,
      keepSubmitCount: true,
      keepTouched: false,
      keepValues: false,
    });
  };

  return (
    <ErrorHandler location="src/features/Risk/components/widgets/AssociatedEids/components/AddModal/AddModal.jsx">
      <Modal isOpen={isOpen} onClose={handleClose} title="Add EID's">
        <FormProvider autoComplete="off" highlighted onSubmit={handleSubmit} state={form}>
          <Modal.Section>
            <Grid>
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <TextInputArea
                  {...eids}
                  disableAutoSize
                  onBlur={(event) => {
                    const value = event?.target?.value
                      .split(',')
                      .map((item) => {
                        return item.trim();
                      })
                      .filter((item) => {
                        return item.length > 0;
                      })
                      .join(', ');

                    form?.setValue('eids', value);
                  }}
                  onPaste={(event) => {
                    const value = event?.target?.value
                      .split(',')
                      .map((item) => {
                        return item.trim();
                      })
                      .join(', ');

                    form?.setValue('eids', value);
                  }}
                  rows={5}
                />
              </Grid.Col>
            </Grid>
          </Modal.Section>
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button isDisabled={isEmpty(form?.getValues('eids'))} type="submit" variant="solid">
                Add
              </Button>
            </Layout.Group>
          </Modal.Section>
        </FormProvider>
      </Modal>
    </ErrorHandler>
  );
}

AddModal.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  refetch: PropTypes.func,
  setIsOpen: PropTypes.func,
};

AddModal.defaultProps = {
  actionPath: {},
  isOpen: false,
  refetch: () => {},
  setIsOpen: () => {},
};
