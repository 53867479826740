import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { _translateCriteriaFilters } from '@src/features/ActionPaths/includes/functions';
import { logger } from '@src/includes/logger';
import { isNil } from 'lodash';

/**
 * GetCommonCriteria
 *
 * @TODO Needs description.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetCommonCriteria = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/criteria/${thePayload?.id}`,
      },
      method: 'GET',
      params: { queryKey: theQueryKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    if (!isNil(remoteResponse?.data?.activeCommonCriteriaVersion?.criteria)) {
      remoteResponse.data.activeCommonCriteriaVersion.criteria = _translateCriteriaFilters(
        remoteResponse?.data?.activeCommonCriteriaVersion?.criteria,
        'incoming'
      );
    }

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/GetCommonCriteria.js -> GetCommonCriteria()', false, error);

    throw error;
  }
};
