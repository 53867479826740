import { Alert } from '@abyss/web/ui/Alert';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes/Routes';
import { useAssets } from '@src/hooks/useAssets';
import { DashboardLayout } from '@src/layouts/Dashboard';
import { isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { Details } from './components/Details';
import { Form } from './components/Form';
import { widgets } from './components/widgets';
import { useSearch } from './hooks/useSearch';

/**
 * EidSearch
 *
 * Find additional information about a specific Risk Record.
 *
 * @returns {Element}
 * @constructor
 */
export function EidSearch() {
  const { currentRoute } = useRoutesContext();
  const { assets, isLoadingAssets } = useAssets(['ListRiskCodes', 'ListTags']);
  const { errorObject, handleSearch, isError, isSearching, searchResults: riskRecord } = useSearch();

  const eid =
    !isUndefined(currentRoute?.params?.eid) && !isEmpty(currentRoute?.params?.eid) ? currentRoute?.params?.eid : '';

  const hasResults = !isUndefined(riskRecord) && !isEmpty(riskRecord);

  /**
   * If an EID is passed in the URL, fetch the risk record.
   */
  useEffect(() => {
    let previousEid = null;

    if (isError && errorObject?.error?.statusCode === 404) {
      const pathPieces = errorObject?.error?.request?.headers?.['x-api-endpoint']?.split('/');
      previousEid = pathPieces[pathPieces.length - 1];
    }

    if (!isEmpty(eid) && previousEid !== eid && !isSearching) {
      handleSearch({ eid });
    }
  }, [eid, handleSearch, isError, errorObject, isSearching]);

  /**
   * check if the EID has a DOB attribute
   */
  const hasDOB = useMemo(() => {
    return riskRecord?.ireRiskRecord?.remediationFindings?.find((attribute) => {
      return attribute.attributeType === 'dob';
    });
  }, [riskRecord?.ireRiskRecord?.remediationFindings]);

  /**
   * check if the EID has an SSN attribute
   */
  const hasSSN = useMemo(() => {
    return riskRecord?.ireRiskRecord?.remediationFindings?.find((attribute) => {
      return attribute.attributeType === 'ssn';
    });
  }, [riskRecord?.ireRiskRecord?.remediationFindings]);

  /**
   * add icon component to each widget
   *
   * @type {any[]}
   */
  const theWidgets = useMemo(() => {
    return Object.values(widgets)
      .sort((a, b) => {
        return a.order - b.order;
      })
      .map((widget) => {
        const theWidget = { ...widget };

        theWidget.icon = <IconSymbol color="#000000" icon={widget?.icon} variant="outlined" />;

        const Component = widgets?.[theWidget?.accessor]?.component;

        theWidget.element = Component ? (
          <Component assets={assets} handleSearch={handleSearch} options={theWidget} riskRecord={riskRecord} />
        ) : null;

        return theWidget;
      })
      .filter((widget) => {
        if (widget?.accessor.includes('dob') && !hasDOB) {
          return false;
        }

        return !(widget?.accessor.includes('ssn') && !hasSSN);
      });
  }, [widgets, hasSSN, hasDOB, riskRecord, assets]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/EidSearch/EidSearch.jsx">
      <DashboardLayout
        hasData={hasResults && !isEmpty(eid)}
        header={{
          LeftComponent: <Form isFetching={isSearching} isLoading={isLoadingAssets} />,
          RightComponent: (
            <Details
              actionPaths={riskRecord?.recordActionPaths}
              associatedEids={riskRecord?.associatedEID}
              eid={riskRecord?.eid}
              handleSearch={handleSearch}
              ireRiskRecord={riskRecord?.ireRiskRecord}
              riskCodes={assets?.ListRiskCodes?.data}
              riskRecord={riskRecord}
              tags={riskRecord?.tags}
            />
          ),
        }}
        isFetching={isSearching}
        isLoading={isLoadingAssets}
        title={currentRoute?.screenTitle}
        widgets={theWidgets}
      />
      {!isEmpty(eid) && !hasResults && !isSearching && (
        <Alert title="Not Found" variant="info">
          The EID you are searching for could not be found.
        </Alert>
      )}
    </ErrorHandler>
  );
}
