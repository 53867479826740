import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isUndefined } from 'lodash';

/**
 * ScheduleExport
 *
 * @TODO Needs description.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ScheduleExport = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': `/action-paths/${thePayload?.actionPathId}/extracts`,
      },
      method: 'POST',
      params: { mutationKey: theMutationKey },
    };

    if (isUndefined(thePayload?.maxRecords)) {
      requestArgs.data = {
        maxRecords: 1000000,
      };
    } else {
      requestArgs.data = {
        maxRecords: thePayload?.maxRecords,
      };
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/mutations/ScheduleExport.js -> ScheduleExport()', false, error);

    throw error;
  }
};
