import { Flex } from '@abyss/web/ui/Flex';
import { Label } from '@abyss/web/ui/Label';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Text } from '@abyss/web/ui/Text';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Loader
 *
 * Reusable loader component
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Loader(props) {
  const { backgroundColor, height, message, verticalAlignment, width } = props;

  const css = {
    backgroundColor,
    position: 'relative',
  };

  if (isEmpty(height)) {
    css.height = '100vh !important';
  }

  if (isEmpty(width)) {
    css.width = '100vw !important';
  }

  return (
    <motion.div
      animate="open"
      initial={{ opacity: 0 }}
      variants={{
        closed: { opacity: 0 },
        open: { opacity: 1 },
      }}
    >
      <Flex
        alignContent="center"
        alignItems="center"
        css={css}
        direction="column"
        justify={!isEmpty(verticalAlignment) ? verticalAlignment : 'center'}
      >
        <Flex alignContent="center" alignItems="center" justify="center">
          <LoadingSpinner ariaLoadingLabel="Loading..." isLoading size="$lg" />
          <Flex
            alignContent="flex-start"
            alignItems="flex-start"
            css={{ marginLeft: 'var(--abyss-space-lg)' }}
            direction="column"
          >
            <Label
              className="xs-margin-bottom"
              css={{ marginBottom: 'var(--abyss-space-xs)' }}
              data-testid="loader-container-abyss-flex-root"
              size="$lg"
            >
              Loading...
            </Label>
            <Text size="$sm">{!isEmpty(message) ? message : 'Your request is being processed.'}</Text>
          </Flex>
        </Flex>
      </Flex>
    </motion.div>
  );
}

Loader.propTypes = {
  backgroundColor: PropTypes.string,
  height: PropTypes.string,
  message: PropTypes.string,
  verticalAlignment: PropTypes.string,
  width: PropTypes.string,
};

Loader.defaultProps = {
  backgroundColor: 'var(--abyss-colors-white)',
  height: '',
  message: '',
  verticalAlignment: '',
  width: '',
};
