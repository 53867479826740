import { Alert } from '@abyss/web/ui/Alert';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Message
 *
 * Displays an alert message with the component path, error message and stack trace.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Message(props) {
  const { error, errorInfo, location } = props;

  return (
    <Alert>
      <Layout.Stack alignItems="left" grow>
        <Layout.Group>
          <div>
            <Text fontWeight="bold">Error:</Text>
          </div>
          <div>
            <code>{error?.toString()}</code>
          </div>
        </Layout.Group>
        <Layout.Group>
          <div>
            <Text fontWeight="bold">Location:</Text>
          </div>
          <div>
            <code>{location}</code>
          </div>
        </Layout.Group>
        <Layout.Group>
          <details className="pre-wrap">{errorInfo?.componentStack?.toString()}</details>
        </Layout.Group>
      </Layout.Stack>
    </Alert>
  );
}

Message.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  errorInfo: PropTypes.shape({
    componentStack: PropTypes.string,
  }),
  location: PropTypes.string,
};

Message.defaultProps = {
  error: {},
  errorInfo: {},
  location: '',
};
