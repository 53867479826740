import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';

/**
 * List
 *
 * Provides the user with a screen listing the existing alerts.
 *
 * @returns {Element}
 * @constructor
 */
export function List() {
  const [showAll, setShowAll] = useState(false);

  const { clearApiCache, useApiQuery } = useApi();

  const [ListAlerts, { data, error, isFetching, isLoading, refetch }] = useApiQuery('ListAlerts');

  /**
   * Invalidate the query cache when the showAll state changes.
   */
  useEffect(() => {
    clearApiCache(['ListAlerts']);
  }, [showAll]);

  return (
    <ErrorHandler location="src/routes/private/Notifications/Alerts/List/List.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Heading>Alerts</Heading>
          </Grid.Col>

          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            {!showAll && (
              <Table
                error={error}
                headerLeft={
                  <ToggleSwitch
                    isChecked={showAll}
                    label="Show Closed Alerts"
                    onChange={(event) => {
                      return setShowAll(event?.target?.checked);
                    }}
                  />
                }
                isLoading={isLoading || isFetching}
                refetch={refetch}
                requestArgs={{
                  alertStatuses: ['TRIGGERED', 'ASSIGNED'],
                  page: 0,
                  size: 25,
                  sort: 'lastModifiedDate,desc',
                }}
                requestFunction={ListAlerts}
                requestKey="ListAlerts"
                rows={data?.content || []}
                totalPages={data?.totalPages || 1}
                totalRecords={data?.totalElements || 0}
              />
            )}

            {showAll && (
              <Table
                error={error}
                headerLeft={
                  <ToggleSwitch
                    isChecked={showAll}
                    label="Show Closed Alerts"
                    onChange={(event) => {
                      return setShowAll(event?.target?.checked);
                    }}
                  />
                }
                isLoading={isLoading || isFetching}
                refetch={refetch}
                requestArgs={{ page: 0, size: 25, sort: 'lastModifiedDate,desc' }}
                requestFunction={ListAlerts}
                requestKey="ListAlerts"
                rows={data?.content || []}
                totalPages={data?.totalPages || 1}
                totalRecords={data?.totalElements || 0}
              />
            )}
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
}
