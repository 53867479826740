import { useRouter } from '@abyss/web/hooks/useRouter';
import { useToast } from '@abyss/web/hooks/useToast';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Wizard } from '@src/features/ActionPaths/components/misc/Wizard';
import { ContextProvider as WizardProvider } from '@src/features/ActionPaths/components/misc/Wizard/context';
import React, { useEffect } from 'react';

/**
 * Route: Manage
 *
 * Provides a step-by-step wizard interface for composing an action path.
 *
 * @returns {Element}
 * @constructor
 */
export function Manage() {
  const router = useRouter();
  const { status: actionPathStatus } = router.getRouteParams();
  const { toast } = useToast();

  /**
   * Redirects the user to the list screen if the action path status is not "draft".
   */
  useEffect(() => {
    if (actionPathStatus !== 'draft') {
      toast.show({
        id: 'action-path-status-error',
        message: (
          <React.Fragment>
            Only allowed to edit an action path with a <code>draft</code> status.
          </React.Fragment>
        ),
        title: 'Invalid Status',
        variant: 'error',
      });

      router?.navigate('/action-paths');
    }
  }, [actionPathStatus]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/Manage/Manage.jsx">
      <WizardProvider>
        <Wizard />
      </WizardProvider>
    </ErrorHandler>
  );
}
