import { Layout } from '@abyss/web/ui/Layout';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import PropTypes from 'prop-types';
import React from 'react';

import { MainSkeleton } from './components/Skeleton';

/**
 * Section: Main
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Main(props) {
  const { currentTarget, hasData, isFetching, targetRef, widgets } = props;

  return (
    <ErrorHandler location="src/layouts/Dashboard/sections/Main/Main.jsx">
      <Layout.Stack
        alignItems="left"
        alignLayout="left"
        data-testid="layout-dashboard-main"
        grow
        id="layout-dashboard-main"
      >
        {isFetching ? (
          <MainSkeleton count={widgets.length} />
        ) : (
          <React.Fragment>
            {hasData && (
              <React.Fragment>
                {widgets.map((widget) => {
                  const { element: WidgetElement } = widget;

                  return (
                    <Widget
                      collapsed={false}
                      collapsible
                      description={widget?.description}
                      icon={widget?.icon}
                      key={widget?.title}
                      title={widget?.title}
                    >
                      <div ref={widget?.accessor === currentTarget ? targetRef : null}>{WidgetElement}</div>
                    </Widget>
                  );
                })}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Layout.Stack>
    </ErrorHandler>
  );
}

Main.propTypes = {
  currentTarget: PropTypes.string,
  isFetching: PropTypes.bool,
  targetRef: PropTypes.shape({ current: PropTypes.instanceOf(PropTypes.element) }),
  widgets: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      collapsed: PropTypes.bool,
      collapsible: PropTypes.bool,
      description: PropTypes.string,
      element: PropTypes.element,
      icon: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

Main.defaultProps = {
  currentTarget: '',
  isFetching: false,
  targetRef: {},
  widgets: [],
};
