import { useToast } from '@abyss/web/hooks/useToast';
import { useApi } from '@src/context/Api';
import { logger } from '@src/includes/logger';
import { useCallback } from 'react';

import modes from './includes/modes.json';

/**
 * useSave
 *
 * Makes an API request to save the common criteria.
 *
 * @param mode
 * @returns {(function({}=, function()=): Promise<void>)|*}
 */
export const useSave = (mode = null) => {
  const currentMode = modes?.[mode];

  const { clearApiCache, useApiMutation } = useApi();
  const [SaveCommonCriteria] = useApiMutation('SaveCommonCriteria');

  const { toast } = useToast();

  return useCallback(async (submittedValues = {}, refetch = () => {}) => {
    try {
      const toastId = `common-criteria-${mode}`;

      toast.show({
        autoClose: false,
        id: `${toastId}-info`,
        isLoading: true,
        message: currentMode?.info?.message,
        title: currentMode?.info?.title,
        variant: 'info',
      });

      const payload = {
        ...submittedValues,
        ...{
          action: mode,
        },
      };

      await SaveCommonCriteria(payload, {
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            message: currentMode?.error?.message,
            title: currentMode?.error?.title,
            variant: 'error',
          });
        },
        onSuccess: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            message: currentMode?.success?.message,
            title: currentMode?.success?.title,
            variant: 'success',
          });
          clearApiCache(['ListCommonCriteria', 'ListCommonCriteriaVersions']);
          refetch();
        },
      });

      clearApiCache([
        'ListActionPaths',
        'ListCommonCriteria',
        'GetActionPath',
        'GetCommonCriteria',
        'GetCommonCriteriaVersion',
      ]);
    } catch (error) {
      logger.error('src/routes/private/Admin/CommonCriteria/hooks/useSave/useSave.js', false, error);
    }
  }, []);
};
