import { TextInput } from '@abyss/web/ui/TextInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import fields from '@src/features/ActionPaths/includes/fields.json';
import { useDebounce } from '@src/hooks';
import { fieldValidator } from '@src/includes/validation';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';

const { name: nameField } = fields;

/**
 * Field: Name
 *
 * Provides the user with the ability to specify an action path name.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Name(props) {
  const { actionPath, form } = props;

  const name = form.getValues('name');

  const { useApiQuery } = useApi();

  const [GetValidActionPathName, { data, isFetching, isLoading }] = useApiQuery('GetValidActionPathName', {
    staleTime: 0,
  });

  /**
   * validateField
   */
  const validateField = () => {
    form.validate(
      'name',
      () => {},
      () => {}
    );
  };

  /**
   * sendRequest
   *
   * Makes a request to a remote API to validateCriteria whether the user provided action path name exists or not.
   *
   * @type {(function(): Promise<void>)|*}
   */
  const sendRequest = useCallback(async () => {
    if ((await form?.trigger('name')) === true && name !== actionPath?.name) {
      await GetValidActionPathName({ name });
    }
  }, [name]);

  /**
   * When the data is received from the API, determine if the name is available or not.
   */
  useEffect(() => {
    if (!isUndefined(data)) {
      const isValid = data;

      if (isValid === true) {
        form?.clearErrors('name');
      }

      if (isValid === false) {
        form?.setError(
          'name',
          { message: 'Name already in use.', type: 'custom' },
          {
            shouldDirty: true,
            shouldFocus: true,
          }
        );
      }
    }
  }, [data, isLoading, isFetching]);

  /**
   * When the name or actionPath.name changes, validate the field.
   */
  useEffect(() => {
    validateField();
  }, [name, actionPath?.name]);

  const debouncedRequest = useDebounce(sendRequest, 250);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/fields/Name/Name.jsx">
      <TextInput
        {...nameField}
        onChange={async (event) => {
          if (event?.target?.value?.length >= 3) {
            if ((await form?.trigger('name')) === true) {
              form?.setError(
                'name',
                { message: 'Verifying name is available...', type: 'custom' },
                { shouldFocus: true }
              );
            }
            debouncedRequest();
          }
        }}
        validators={{
          ...nameField.validators,
          ...{
            validate: {
              customValidator: (value) => {
                return fieldValidator(nameField, value);
              },
            },
          },
        }}
      />
    </ErrorHandler>
  );
}

Name.propTypes = {
  actionPath: PropTypes.shape({
    name: PropTypes.string,
  }),
  form: PropTypes.shape({
    clearErrors: PropTypes.func,
    getValues: PropTypes.func,
    register: PropTypes.func,
    setError: PropTypes.func,
    trigger: PropTypes.func,
    validate: PropTypes.func,
  }),
};

Name.defaultProps = {
  actionPath: {
    name: '',
  },
  form: {
    clearErrors: () => {},
    getValues: () => {},
    register: () => {},
    setError: () => {},
    trigger: () => {},
    validate: () => {},
  },
};
