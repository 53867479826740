import { dayjs } from '@abyss/web/tools/dayjs';
import { Badge } from '@abyss/web/ui/Badge';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * ExportStatus
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function ExportStatus(props) {
  const { exportDetails } = props;

  const status = exportDetails?.status || 'UNKNOWN';

  const statusVariants = {
    ERROR: 'error',
    EXTRACTED: 'success',
    RUNNING: 'warning',
    SCHEDULED: 'info',
    UNKNOWN: 'neutral',
  };

  const statusLabels = {
    ERROR: 'Error',
    EXTRACTED: dayjs(exportDetails?.lastModifiedDate).format('MM/DD/YYYY, HH:mm:ss') || 'Extracted',
    RUNNING: 'In progress',
    SCHEDULED: 'Scheduled',
    UNKNOWN: 'None',
  };

  const statusTooltips = {
    ERROR: 'Please wait a few minutes and try again. If continues to fail, please contact the development team.',
    EXTRACTED: 'The corresponding risk records have exported to SharePoint. Click "View on SharePoint".',
    RUNNING: 'Risk records are currently exporting to SharePoint.',
    SCHEDULED: 'Request to export risk records to SharePoint has been received.',
    UNKNOWN: 'There are currently no exported risk records. Click "Export to SharePoint".',
  };

  return (
    <ErrorHandler location="src/features/Risk/components/tables/RiskRecords/components/Header/components/ExportStatus/ExportStatus.jsx">
      <Layout.Group>
        <div>
          <Text fontWeight="bold">Export Status:</Text>
        </div>
        <Tooltip content={statusTooltips?.[status]}>
          <div>
            <Badge css={{ fontWeight: 'bold' }} outline variant={statusVariants?.[status]}>
              {statusLabels?.[status]}
            </Badge>
          </div>
        </Tooltip>
      </Layout.Group>
    </ErrorHandler>
  );
}

ExportStatus.propTypes = {
  exportDetails: PropTypes.shape({
    lastModifiedDate: PropTypes.string,
    status: PropTypes.string,
  }),
};

ExportStatus.defaultProps = {
  exportDetails: {},
};
