import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isEmpty, omit, pick } from 'lodash';

/**
 * ListActionPaths
 *
 * Retrieves a list of action paths from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListActionPaths = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = omit(payload?.[1], ['page', 'size', 'sort']);
    const thePagination = pick(payload?.[1], ['page', 'size', 'sort']);

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': '/action-paths',
      },
      method: 'GET',
      params: { ...{ queryKey: theQueryKey }, ...thePagination },
    };

    if (!isEmpty(thePayload)) {
      requestArgs.headers['x-api-endpoint'] += '/search';
      requestArgs.method = 'POST';
      requestArgs.data = thePayload;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/ListActionPaths.js -> ListActionPaths()', false, error);

    throw error;
  }
};
