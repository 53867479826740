import { Chart } from '@src/components/Chart';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

/**
 * Widget: RiskCodes
 *
 * Displays the count of enterprise IDs associated with IRE risk codes.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function RiskCodes(props) {
  const { assets, data } = props;

  const riskCodeStats = data?.GetRiskDashboardStatistics?.data?.riskCodeStats;
  const redRiskCodes = assets?.ListRedRiskCodes?.data;
  const riskCodes = assets?.ListRiskCodes?.data;

  /**
   * Process the data and set the labels, red totals, and yellow totals.
   */
  const chartData = useMemo(() => {
    const theData = {
      dataSets: [],
      labels: [],
    };

    const theLabels = [];
    const theRedTotals = [];
    const theYellowTotals = [];

    const red = redRiskCodes?.map((redRiskCode) => {
      return Number(redRiskCode?.codeId);
    });

    const yellow = riskCodes
      ?.filter((riskCode) => {
        return !red.includes(Number(riskCode?.codeId));
      })
      .map((riskCode) => {
        return Number(riskCode?.codeId);
      });

    if (!isEmpty(riskCodeStats)) {
      const items = orderBy(riskCodeStats, ['riskCode'], ['asc']);

      items?.forEach((item) => {
        const riskCode = Number(String(item?.riskCode).replace('RC-', ''));

        if (red.includes(riskCode) || yellow.includes(riskCode)) {
          if (!theLabels.includes(item?.riskCode)) {
            theLabels.push(item?.riskCode);
          }

          if (!theRedTotals.includes(item?.countTotal) && red.includes(riskCode)) {
            theRedTotals.push(item?.countTotal);
          } else {
            theRedTotals.push(0);
          }

          if (!theYellowTotals.includes(item?.countTotal) && yellow.includes(riskCode)) {
            theYellowTotals.push(item?.countTotal);
          } else {
            theYellowTotals.push(0);
          }
        }
      });
    }

    theData.labels = theLabels;

    if (!isEmpty(theRedTotals)) {
      theData.dataSets[0] = {
        backgroundColor: 'rgba(252, 240, 240, 0.75)',
        borderColor: 'rgba(196, 0, 0, 1.0)',
        borderWidth: 1,
        data: theRedTotals,
        grouped: false,
        hoverBackgroundColor: 'rgba(196, 0, 0, 1.0)',
        hoverBorderColor: 'rgba(196, 0, 0, 1.0)',
        label: 'Red',
      };
    }

    if (!isEmpty(theYellowTotals)) {
      theData.dataSets[1] = {
        backgroundColor: 'rgba(255, 252, 240, 0.75)',
        borderColor: 'rgba(194, 78, 20, 1.0)',
        borderWidth: 1,
        data: theYellowTotals,
        grouped: false,
        hoverBackgroundColor: 'rgba(194, 78, 20, 1.0)',
        hoverBorderColor: 'rgba(194, 78, 20, 1.0)',
        label: 'Yellow',
      };
    }

    return theData;
  }, [riskCodeStats, redRiskCodes, riskCodes]);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/Risk/components/widgets/RiskCodes/RiskCodes.jsx">
      <Chart
        dataSets={chartData?.dataSets || []}
        labels={chartData?.labels || []}
        legendLimit={15}
        tableConfiguration={{}}
        type="VERTICAL_BAR_CHART"
      />
    </ErrorHandler>
  );
}

RiskCodes.propTypes = {
  assets: PropTypes.shape({
    ListRedRiskCodes: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({ codeId: PropTypes.string })),
    }),
    ListRiskCodes: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({ codeId: PropTypes.string })),
    }),
  }),
  data: PropTypes.shape({
    riskCodeStats: PropTypes.arrayOf(
      PropTypes.shape({
        countTotal: PropTypes.number,
        riskCode: PropTypes.string,
      })
    ),
  }),
};

RiskCodes.defaultProps = {
  assets: {},
  data: {},
};
