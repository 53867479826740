import { useFormFieldArray } from '@abyss/web/hooks/useFormFieldArray';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes/Routes';
import { User } from '@src/features/Users/components/User';
import { useAssets } from '@src/hooks/useAssets';
import PropTypes from 'prop-types';
import React from 'react';

import { Table } from './components/Table';
import { Styles } from './includes/styles';

/**
 * Filters
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Filters(props) {
  const { filterKey, form, headerLeft, isDisabled, permissions } = props;

  const { assets, isLoadingAssets } = useAssets([
    'ListActionStatuses',
    'ListTags',
    {
      args: { page: 0, size: 9999, sort: 'name,asc', statusList: ['ACTIVE'] },
      key: 'ListActionPaths',
    },
  ]);

  const { currentRoute } = useRoutesContext();

  const { append, fields, remove, replace } = useFormFieldArray({
    control: form.control,
    name: filterKey,
  });

  /**
   * handleReset
   *
   * Resets the user specified filters from the data table.
   *
   * @returns {Promise<void>}
   */
  const handleReset = async () => {
    await form.setValue(filterKey, []);
    replace([]);
  };

  return (
    <ErrorHandler location="src/features/Criteria/components/misc/Filters/Filters.jsx">
      <Styles>
        <Grid>
          <Grid.Col span={{ xs: '50%' }}>
            <Flex
              alignContent="flex-start"
              alignItems="flex-end"
              className="topRow"
              direction="row"
              justify="flex-start"
            >
              {headerLeft}
            </Flex>
          </Grid.Col>
          <Grid.Col css={{ paddingBottom: 0 }} span={{ xs: '50%' }}>
            <Layout.Group alignItems="bottom" alignLayout="right" css={{ height: '100%' }}>
              <User.Capability
                alias="add"
                attributes={permissions?.add?.attributes}
                isAllowed={permissions?.add?.isAllowed}
                resource={permissions?.add?.resource}
              >
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  className={isDisabled ? 'isDisabled-true' : ''}
                  css={{ height: '100%' }}
                  id="addField"
                  isDisabled={isDisabled}
                  onClick={() => {
                    append({ column: '', condition: '', value: '' });
                  }}
                  variant="custom"
                >
                  <Flex alignItems="center" css={{ height: '100%' }}>
                    <div>
                      <IconSymbol icon="add_circle" variant="filled" />
                      <IconSymbol icon="add_circle" variant="outlined" />
                    </div>
                    <div>Add Filter</div>
                  </Flex>
                </Link>
              </User.Capability>
            </Layout.Group>
          </Grid.Col>
          <Grid.Col css={{ paddingTop: 0 }} span={{ xs: '100%' }}>
            <Table
              {...{
                append,
                assets,
                dataKey: `${currentRoute?.slug}-filters`,
                fields,
                filterKey,
                form,
                handleReset,
                isDisabled,
                isLoading: isLoadingAssets,
                permissions,
                remove,
                replace,
              }}
            />
          </Grid.Col>
        </Grid>
      </Styles>
    </ErrorHandler>
  );
}

Filters.propTypes = {
  filterKey: PropTypes.string,
  form: PropTypes.shape({
    control: PropTypes.shape({
      fields: PropTypes.shape({
        append: PropTypes.func,
        remove: PropTypes.func,
        replace: PropTypes.func,
      }),
      setValue: PropTypes.func,
    }),
    setValue: PropTypes.func,
  }),
  headerLeft: PropTypes.element,
  isDisabled: PropTypes.bool,
  permissions: PropTypes.shape({
    add: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
    edit: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
    remove: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
  }),
};

Filters.defaultProps = {
  filterKey: 'filters',
  form: {
    control: {},
    setValue: () => {},
  },
  headerLeft: null,
  isDisabled: false,
  permissions: {
    add: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
    edit: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
    remove: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
  },
};
