import { ErrorHandler } from '@src/components/ErrorHandler';
import { requests } from '@src/requests';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo } from 'react';

import { useApiMutation } from './hooks/useApiMutation';
import { useApiQueries } from './hooks/useApiQueries';
import { useApiQuery } from './hooks/useApiQuery';
import { queryClient } from './includes/configuration';
import { clearCache } from './includes/functions';

/**
 *
 * @type {React.Context<{queryClient: {}, clearApiCache: *, mutations: {}, useApiQuery: *, useApiMutation: *,
 *   useApiQueries: *, queries: {}}>}
 */
const ApiContext = createContext({
  clearApiCache: () => {},
  mutations: {},
  queries: {},
  queryClient: {},
  useApiMutation: () => {},
  useApiQueries: () => {},
  useApiQuery: () => {},
});

export const useApi = () => {
  return useContext(ApiContext);
};

/**
 * ApiProvider
 *
 * Provides a simple way to make api requests throughout the application without reinventing the wheel.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function ApiProvider(props) {
  const { children } = props;

  const value = useMemo(() => {
    return {
      clearApiCache: clearCache,
      mutations: requests?.mutations,
      queries: requests?.queries,
      queryClient,
      useApiMutation,
      useApiQueries,
      useApiQuery,
    };
  }, []);

  return (
    <ErrorHandler location="src/context/Api/Api.jsx">
      <QueryClientProvider client={queryClient}>
        <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorHandler>
  );
}

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
