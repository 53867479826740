import { keepPreviousData, QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      gcTime: Infinity, // disables cache
      retry: false, // Do not retry on failure, otherwise specify integer for number of retries if request fails.
      retryDelay: 0,
      throwOnError: false,
    },
    queries: {
      gcTime: 300000, // 5 minutes - Returns cached response. Triggers API request in background, updating cache.
      placeholderData: keepPreviousData,
      refetchInterval: 0,
      refetchIntervalInBackground: false,
      refetchOnMount: true, // the query will refetch on mount if the data is stale.
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, errorObject) => {
        const { error } = errorObject;

        if ([401, 404].includes(error?.statusCode)) {
          return false; // Don't retry if Unauthorized or Not Found
        }

        return failureCount < 1; // Retry 1 time for all other errors
      },
      retryDelay: 500, // retries half a second after failure
      retryOnMount: true, // retry on mount if the query fails,
      staleTime: 15000, // 15 seconds - Returns cached response. Prevents any additional API requests,
      throwOnError: false,
    },
  },
});
