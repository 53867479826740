import { isEmpty } from 'lodash';

import { queryClient } from './configuration';

/**
 * clearCache
 *
 * Clears the cache for the provided query keys.
 *
 * @param queryKeys
 * @returns {Promise<Awaited<void>[]>|Promise<void>}
 */
export async function clearCache(queryKeys = []) {
  if (isEmpty(queryKeys)) {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await queryClient.invalidateQueries();
  }

  const promises = queryKeys.map(async (queryKey) => {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey?.[0] === queryKey || query.queryKey?.[0].startsWith(queryKey);
      },
    });
  });

  return Promise.all(promises);
}
