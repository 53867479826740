import { Divider } from '@abyss/web/ui/Divider';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { DonutChart } from '@src/features/ActionPaths/components/charts/Donut';
import { isEmpty, orderBy, sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Table } from '../../charts/Donut/components/Table';
import { Styles } from './includes/styles';

/**
 * Widget: ActionTakenCodes
 *
 * Displays information about entity action path statuses.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function ActionTakenCodes(props) {
  const { actionTakenCounts } = props;

  const [theData, setTheData] = useState({});
  const [noData, setNoData] = useState(false);

  /**
   * prepare data for display in the chart and the table
   */
  useEffect(() => {
    if (!isEmpty(actionTakenCounts)) {
      const labels = [];
      const data = [];

      actionTakenCounts?.forEach((item) => {
        labels.push(item?.actionTakenName);

        data.push({
          amount: item?.count,
          percent: item?.perc,
          type: item?.actionTakenName,
        });
      });

      labels.sort((a, b) => {
        return a.localeCompare(b);
      });

      setTheData({ data: orderBy(data, ['type'], ['asc']), labels });
    }
  }, [actionTakenCounts]);

  /**
   * Check if there is no data to display
   */
  useEffect(() => {
    if (isEmpty(theData?.data) || (!isEmpty(theData?.data) && sumBy(theData?.data, 'amount') === 0)) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [theData?.data]);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/widgets/ActionTakenCodes/ActionTakenCodes.jsx">
      <Widget description="Percentage by taken codes" title="Action Taken Codes">
        <Styles>
          {noData ? (
            <Layout.Stack alignLayout="center">
              <div>
                <IconSymbol color="$gray3" icon="bid_landscape_disabled" size="96" />
              </div>
              <div>
                <Text color="$gray4" fontWeight="bold">
                  Data Not Available
                </Text>
              </div>
            </Layout.Stack>
          ) : (
            <DonutChart data={theData?.data} labels={theData?.labels} />
          )}{' '}
          <Divider />
          <Table data={theData?.data} labels={theData?.labels} />
        </Styles>
      </Widget>
    </ErrorHandler>
  );
}

ActionTakenCodes.propTypes = {
  actionTakenCounts: PropTypes.arrayOf(
    PropTypes.shape({
      actionTakenName: PropTypes.string,
      count: PropTypes.number,
      perc: PropTypes.number,
    })
  ),
};

ActionTakenCodes.defaultProps = {
  actionTakenCounts: [],
};
