import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { Navigation } from './components/Navigation';
import { SidebarSkeleton } from './components/Skeleton';

/**
 * Section: Sidebar
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Sidebar(props) {
  const { currentAccessor, hasData, isFetching, isOpen, setCurrentTarget, toggleSidebar, widgets } = props;
  const containerRef = useRef(null);

  return (
    <ErrorHandler location="src/layouts/Dashboard/sections/Sidebar/Sidebar.jsx">
      <div id="layout-dashboard-sidebar">
        {isFetching ? (
          <SidebarSkeleton />
        ) : (
          <React.Fragment>
            {hasData && (
              <div ref={containerRef} style={{ height: '100%' }}>
                <FloatingSection
                  alwaysFloat={false}
                  containerRef={containerRef}
                  css={{
                    'abyss-floating-section-root': {
                      '.abyss-drawer-menu-container': {
                        backgroundColor: 'var(--abyss-colors-gray1)',
                      },
                      boxShadow: 'none',
                    },
                  }}
                  position="top"
                  space={64}
                >
                  <Navigation
                    currentAccessor={currentAccessor}
                    isSidebarOpen={isOpen}
                    setCurrentTarget={setCurrentTarget}
                    toggleSidebar={toggleSidebar}
                    widgets={widgets}
                  />
                </FloatingSection>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </ErrorHandler>
  );
}

Sidebar.propTypes = {
  currentAccessor: PropTypes.string,
  hasDOB: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  hasSSN: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  isFetching: PropTypes.bool,
  isOpen: PropTypes.bool,
  setCurrentTarget: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

Sidebar.defaultProps = {
  currentAccessor: '',
  hasDOB: false,
  hasSSN: false,
  isFetching: false,
  isOpen: false,
  setCurrentTarget: () => {},
  toggleSidebar: () => {},
};
