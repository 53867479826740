import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { User } from '@src/features/Users/components/User';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * CreateWithCriteria
 *
 * Renders a button that when clicked will navigate to the create action path screen with the supplied criteria.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function CreateWithCriteria(props) {
  const { children, criteria } = props;

  const router = useRouter();

  return (
    <ErrorHandler location="src/features/ActionPaths/components/buttons/CreateWithCriteria/CreateWithCriteria.jsx">
      <User.Capability attributes={['create']} resource="ActionPaths">
        <Button
          before={<IconSymbol icon="edit" variant="outlined" />}
          data-testid="create-button"
          onClick={async (event) => {
            event?.preventDefault();
            const encodedCriteria = Buffer.from(JSON.stringify(criteria)).toString('base64');
            router?.navigate(`/action-paths/0/create/automatic/draft/step/1/${encodedCriteria}`);
          }}
          variant="solid"
        >
          {children}
        </Button>
      </User.Capability>
    </ErrorHandler>
  );
}

CreateWithCriteria.propTypes = {
  children: PropTypes.node,
  criteria: PropTypes.shape({}),
};

CreateWithCriteria.defaultProps = {
  children: null,
  criteria: {},
};
