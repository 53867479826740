import { Layout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import React from 'react';

import { RedEntities } from './components/RedEntities';
import { YellowEntities } from './components/YellowEntities';

/**
 * Details
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Details(props) {
  const { data } = props;

  const redEntities = data?.GetRiskDashboardStatistics?.data?.redEntityDetails || {};
  const yellowEntities = data?.GetRiskDashboardStatistics?.data?.yellowEntityDetails || {};

  return (
    <ErrorHandler location="src/routes/private/Dashboards/Risk/components/Details/Details.jsx">
      <Layout.Group alignItems="center" alignLayout="left" space={themeConfiguration?.theme?.space?.md}>
        <RedEntities data={redEntities} />
        <YellowEntities data={yellowEntities} />
      </Layout.Group>
    </ErrorHandler>
  );
}
