import { ActionPaths } from './ActionPaths';
import { Attribute } from './Attribute';
import { Dates } from './Dates';
import { Eimp } from './Eimp';
import { EventTimeline } from './EventTimeline';
import { RemediationEvents } from './RemediationEvents';
import { Tags } from './Tags';
import { TrustedValueHistory } from './TrustedValueHistory';

/**
 * widgets
 *
 * @type {{"attribute-type-ssn": {component: ((function(*): Element)|*), accessor: string, icon: string, description:
 *   string, title: string}, "remediation-events": {component: ((function(*): Element)|*), accessor: string, icon:
 *   string, description: string, title: string}, "trusted-value-history": {component: ((function(*): Element)|*),
 *   accessor: string, icon: string, description: string, title: string}, eimp: {component: ((function(*): Element)|*),
 *   accessor: string, icon: string, description: string, title: string}, dates: {component: ((function(*):
 *   Element)|*), accessor: string, icon: string, description: string, title: string}, "attribute-type-dob":
 *   {component: ((function(*): Element)|*), accessor: string, icon: string, description: string, title: string},
 *   "action-paths": {component: ((function(*): Element)|*), accessor: string, icon: string, description: string,
 *   title: string}, "event-timeline": {component: ((function(*): JSX.Element)|*), accessor: string, icon: string,
 *   description: string, title: string}, tags: {component: ((function(*): Element)|*), accessor: string, icon: string,
 *   description: string, title: string}}}
 */
export const widgets = {
  'action-paths': {
    accessor: 'action-paths',
    component: ActionPaths,
    description: 'History of Action Paths EID has been assigned to.',
    icon: 'create_new_folder',
    order: 4,
    title: 'Action Paths',
  },
  'attribute-type-dob': {
    accessor: 'attribute-type-dob',
    component: Attribute,
    description: 'Identified Attribute with Identity Risk.',
    icon: 'attribution',
    order: 2,
    title: 'DOB Attribute',
  },
  'attribute-type-ssn': {
    accessor: 'attribute-type-ssn',
    component: Attribute,
    description: 'Identified Attribute with Identity Risk.',
    icon: 'numbers',
    order: 3,
    title: 'SSN Attribute',
  },
  dates: {
    accessor: 'dates',
    component: Dates,
    description: 'Date history for EID in Rem Hub.',
    icon: 'date_range',
    order: 9,
    title: 'Dates',
  },
  eimp: {
    accessor: 'eimp',
    component: Eimp,
    description: 'EIMP Merge/Split History and Source data.',
    icon: 'dns',
    order: 5,
    title: 'EIMP',
  },
  'event-timeline': {
    accessor: 'event-timeline',
    component: EventTimeline,
    description: 'Timeline of key risk and remediation events for this EID.',
    icon: 'timeline',
    order: 1,
    title: 'Event Timeline',
  },
  'remediation-events': {
    accessor: 'remediation-events',
    component: RemediationEvents,
    description: 'Summary of key remediation events for EID in Rem Hub.',
    icon: 'build_circle',
    order: 7,
    title: 'Remediation Events',
  },
  tags: {
    accessor: 'tags',
    component: Tags,
    description: 'All Tags triggered by EID.',
    icon: 'label',
    order: 6,
    title: 'Tags',
  },
  'trusted-value-history': {
    accessor: 'trusted-value-history',
    component: TrustedValueHistory,
    description: 'Historical changes to IRE Trusted and Untrusted Attributes.',
    icon: 'check_circle',
    order: 8,
    title: 'Trusted Value History',
  },
};
