import { useToast } from '@abyss/web/hooks/useToast';
import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './includes/styles';

/**
 * Modal: Remove
 *
 * Displays a modal popup to remove notes to the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Remove(props) {
  const { actionPath, isOpen, setIsOpen } = props;

  const { toast } = useToast();

  const { clearApiCache, useApiMutation } = useApi();
  const [SaveActionPathNote] = useApiMutation('SaveActionPathNote');

  /**
   * handleClose
   *
   * Closes the modal popup and resets the EID.
   */
  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   * handleSubmit
   *
   * Handles the form submission.
   *
   * @returns {Promise<void>}
   */
  const handleSubmit = async () => {
    handleClose();

    const toastId = 'actionPath-notes-remove';
    toast.show({
      ariaLoadingLabel: 'Removing Notes from Action Path',
      autoClose: false,
      id: `${toastId}-info`,
      isLoading: true,
      message: 'Action Path is preparing to remove the notes.',
      title: 'Removing Notes from Action Path...',
      variant: 'info',
    });

    const payload = {
      actionPathId: actionPath?.id,
      note: '',
    };

    await SaveActionPathNote(payload, {
      onError: (error) => {
        toast.hide(`${toastId}-info`);
        toast.show({
          id: `${toastId}-error`,
          message: 'Unable to remove notes.',
          title: 'Removing Notes Failed',
          variant: 'error',
        });
      },
      onSuccess: () => {
        clearApiCache(['GetActionPath']);
        toast.hide(`${toastId}-info`);

        toast.show({
          id: `${toastId}-success`,
          message: 'Notes have been removed.',
          title: 'Removed Notes from Action Path',
          variant: 'success',
        });
      },
    });
  };

  return (
    <ErrorHandler location="src/features/ActionPaths/components/widgets/Notes/components/RemoveModal/RemoveModal.jsx.jsx">
      <Modal
        footer={
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button onClick={handleSubmit} variant="destructive">
                Delete
              </Button>
            </Layout.Group>
          </Modal.Section>
        }
        isOpen={isOpen}
        onClose={handleClose}
        title="Delete Note"
      >
        <Styles>
          <Modal.Section>
            Are you sure you want to delete the note for Action Path <strong>{actionPath?.name}</strong>?
          </Modal.Section>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
}

Remove.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

Remove.defaultProps = {
  actionPath: {},
  isOpen: false,
  setIsOpen: () => {},
};
