import { dayjs } from '@abyss/web/tools/dayjs';
import { useApi } from '@src/context/Api';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * useSearch
 *
 * Retrieves all the necessary data to populate the widgets on the Risk Dashboard.
 *
 * @returns {{isLoading: boolean, data: *}}
 */
export const useSearch = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { useApiQueries } = useApi();

  const [theQueries, setTheQueries] = useState([]);

  const queries = useApiQueries(theQueries);

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (!isEmpty(queries)) {
      if (Object.keys(queries).length === theQueries.length) {
        if (
          isEmpty(
            Object.keys(queries).filter((queryKey) => {
              const query = queries[queryKey];
              return !(!query?.isLoading && !query?.isFetching);
            })
          )
        ) {
          setIsLoading(false);
        }
      }
    }
  }, [queries, theQueries]);

  const handleSearch = useCallback((payload = {}) => {
    const requests = [
      {
        args: { date: dayjs(payload?.reportDate).format('YYYY-MM-DD') },
        key: 'GetRiskDashboardStatistics',
      },
      {
        args: { date: dayjs(payload?.reportDate).format('YYYY-MM-DD') },
        key: 'GetUntrustedSourceCount',
      },
      {
        args: {
          endDate: dayjs(payload?.reportDate).format('YYYY-MM-DD'),
          eventTypeCodeList: payload?.eventTypeCodeList,
          page: 0,
          size: 25,
          sort: 'startDate,desc',
          startDate: dayjs(payload?.reportDate).subtract(6, 'months').format('YYYY-MM-DD'),
        },
        key: 'ListEvents',
      },
    ];

    if (requests !== theQueries) {
      setIsLoading(true);
      setTheQueries(requests);
    }
  }, []);

  return useMemo(() => {
    return { data: queries || undefined, handleSearch, isSearching: isLoading };
  }, [isLoading, queries]);
};
