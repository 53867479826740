import { dayjs } from '@abyss/web/tools/dayjs';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { SourcesTooltip } from '@src/components/common/tooltips';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-query';
import { isUndefined, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Filters } from './components/Filters';
import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table
 *
 * Displays a list of events within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Table(props) {
  const {
    assets,
    error,
    isLoading,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    setCurrentEvent,
    setIsFormModalOpen,
    setRequestArgs,
    totalPages,
    totalRecords,
  } = props;

  /**
   * renderCellActions
   *
   * Provides the user with a menu of actionable items to perform on a specific action path within the list of action
   * paths.
   *
   * @param row
   * @returns {Element}
   */
  const renderCellActions = ({ row }) => {
    const menuItems = [
      {
        icon: <IconSymbol icon="edit" />,
        onClick: () => {
          setIsFormModalOpen(true);
          setCurrentEvent(row?.original);
        },
        title: 'Edit',
      },
    ];

    return (
      <div
        style={{
          marginLeft: '-10px',
          marginRight: '-10px',
        }}
      >
        <Layout.Group
          css={{
            height: '100%',
            marginLeft: '2px',
            marginRight: '2px',
          }}
        >
          <DropdownMenu
            before={<IconSymbol color="#6F6F6F" icon="more_vert" size={24} />}
            hideLabel
            menuItems={menuItems}
          />
        </Layout.Group>
      </div>
    );
  };

  /**
   * renderCellTitle
   *
   * Displays the title of the event within a cell.
   *
   * @param row
   * @param cell
   * @returns {React.JSX.Element|*}
   */
  const renderCellTitle = ({ cell, row }) => {
    return <Link href={`/notifications/events/${row?.original?.eventId}`}>{cell?.value}</Link>;
  };

  /**
   * renderCellDates
   *
   * Displays the date of the event within a cell.
   *
   * @param cell
   * @returns {Element}
   */
  const renderCellDates = ({ row }) => {
    return (
      <React.Fragment>
        {dayjs(row?.original?.startDate).format('MM/DD/YYYY')} - {dayjs(row?.original?.endDate).format('MM/DD/YYYY')}
      </React.Fragment>
    );
  };

  /**
   * renderCellImpactedSystems
   *
   * Displays the impacted systems within a cell in csv format, with a tooltip on hover when more than one.
   *
   * @param row
   * @param cell
   * @returns {React.JSX.Element|*}
   */
  const renderCellImpactedSystems = ({ cell, row }) => {
    if (row?.original?.impactedSystems?.length > 1) {
      const sources = [];

      row?.original?.impactedSystems?.forEach((impactedSystem) => {
        const system = assets?.ListImpactedSystems?.data?.find((asset) => {
          return asset?.codeId === impactedSystem;
        });

        if (!isUndefined(system)) {
          sources.push(system?.codeDesc);
        }
      });

      return (
        <Tooltip content={<SourcesTooltip sources={sources} />} placement="auto">
          <span className="truncate">{sources.join(', ')}</span>
        </Tooltip>
      );
    }

    return cell.value;
  };

  /**
   * renderCellCount
   *
   * Displays the count of the event within a cell.
   *
   * @param cell
   * @returns {string}
   */
  const renderCellCount = ({ cell }) => {
    return Number(cell?.value).toLocaleString('en-US');
  };

  /**
   * renderCellEventType
   *
   * Displays the event type within a cell.
   *
   * @param cell
   * @returns {*}
   */
  const renderCellEventType = ({ cell }) => {
    const theEventType = assets?.ListEventTypes?.data?.find((eventType) => {
      return eventType?.codeId === cell?.value;
    });

    return theEventType?.codeDesc;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.Header === 'Date(s)') {
        column.Cell = renderCellDates;
      }

      if (column.Header === 'Impacted Systems') {
        column.Cell = renderCellImpactedSystems;
      }

      if (column.Header === 'Title') {
        column.Cell = renderCellTitle;
      }

      if (column.Header === 'Event Type') {
        column.Cell = renderCellEventType;
      }

      if (column.Header.includes('Count')) {
        column.Cell = renderCellCount;
      }

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Notifications/Events/List/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            columns,
            configuration,
            error,
            headerLeft: <Filters requestArgs={requestArgs} setRequestArgs={setRequestArgs} />,
            isLoading,
            requestArgs,
            requestFunction,
            requestKey,
            rows,
            totalPages,
            totalRecords,
          }}
        />
      </Styles>
    </ErrorHandler>
  );
}

Table.propTypes = {
  assets: PropTypes.shape({
    ListEventTypes: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
    ListImpactedSystems: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
  }),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  requestArgs: PropTypes.shape({
    endDate: PropTypes.string,
    eventTypeCodeList: PropTypes.arrayOf(PropTypes.string),
    startDate: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      endDate: PropTypes.string,
      eventId: PropTypes.string,
      impactedSystems: PropTypes.arrayOf(PropTypes.string),
      startDate: PropTypes.string,
    })
  ),
  setCurrentEvent: PropTypes.func,
  setIsFormModalOpen: PropTypes.func,
  setRequestArgs: PropTypes.func,
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  assets: {},
  error: {},
  isLoading: false,
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  setCurrentEvent: () => {},
  setIsFormModalOpen: () => {},
  setRequestArgs: () => {},
  totalPages: 1,
  totalRecords: 0,
};
