import { logger } from '@src/includes/logger';

import { ACTIONS } from './actions';
import { updateComponent } from './reducers';

/**
 * reducer
 *
 * @TODO Needs description
 *
 * @param state
 * @param action
 * @returns {{}}
 */
export function reducer(state = {}, action = {}) {
  if (action?.type === ACTIONS.UPDATE_COMPONENT) {
    return updateComponent(state, action?.payload);
  }
  logger.error('UNKNOWN ACTION SPECIFIED!!', false, 'src/context/Visibility/includes/reducer.js');
  return state;
}
