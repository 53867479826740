import { useCountdown } from '@abyss/web/hooks/useCountdown';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Modal } from '@abyss/web/ui/Modal';
import { ModalSection } from '@abyss/web/ui/Modal/ModalSection';
import { Text } from '@abyss/web/ui/Text';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIdleTimer } from 'react-idle-timer';

/**
 * Ask user to continue their session after 25 minutes of activity. Giving the user another 5 minutes to confirm
 * activity before logging them out after a total of 30 minutes of inactivity.
 *
 * @dependency: react-idle-timer
 * @documentation: https://idletimer.dev/docs/getting-started/installation
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function IdleTimeout(props) {
  const { handleLogout } = props;

  const countdown = useCountdown({
    onCompleted: handleLogout,
  });

  const modal = useOverlay('IdleTimeOut');

  const { isPrompted } = useIdleTimer({
    crossTab: {
      emitOnAllTabs: false, // current tab only
    },
    debounce: 250, // time before the event is triggered
    events: [
      'DOMMouseScroll',
      'keydown',
      'mousedown',
      'mousemove',
      'mousewheel',
      'MSPointerDown',
      'MSPointerMove',
      'touchmove',
      'touchstart',
      'visibilitychange',
      'wheel',
    ],
    onIdle: () => {
      if (modal.getState()?.isOpen !== true) {
        countdown.setCountdownTime(5 * 60 * 1000); // 5 Minutes
        modal.open();
      }
    },
    startOnMount: true,
    stopOnIdle: false,
    timeout: 25 * 60 * 1000, // 25 minutes
  });

  /**
   * Outputs the remaining countdown time.
   *
   * @type {Function}
   */
  const CountDown = useCallback(() => {
    return (
      <Text>
        {countdown.remainingTime === 0 ? <strong>NOW</strong> : 'in '}
        {countdown.hours > 1 && <strong>{`${countdown.hours} hours `}</strong>}
        {countdown.hours === 1 && <strong>{`${countdown.hours} hour `}</strong>}
        {countdown.minutes > 1 && <strong>{`${countdown.minutes} minutes `}</strong>}
        {countdown.minutes === 1 && <strong>{`${countdown.minutes} minute `}</strong>}
        {countdown.seconds > 1 && <strong>{`${countdown.seconds} seconds `}</strong>}
        {countdown.seconds === 1 && <strong>{`${countdown.seconds} second `}</strong>}
      </Text>
    );
  }, [countdown]);

  /**
   * Reset the countdown timer.
   *
   * @type {(function(): void)|*}
   */
  const handleReset = useCallback(() => {
    countdown.resetCountdown();
  }, [countdown]);

  return (
    <ErrorHandler location="src/components/IdleTimeout/IdleTimeout.jsx">
      {isPrompted && (
        <Modal
          css={{
            'abyss-modal-header-container': { marginTop: 'var(--abyss-space-md)' },
          }}
          model="IdleTimeOut"
          onClose={handleReset}
          title="Are you still there?"
          titleAlign="center"
        >
          <div
            style={{
              background: 'white',
              border: '7px solid white',
              borderRadius: '50%',
              left: '50%',
              position: 'absolute',
              top: '-2%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <IconBrand icon="alert" size="xl" />
          </div>
          <ModalSection
            css={{
              textAlign: 'center',
            }}
          >
            <Text>
              Your session will expire <CountDown /> due to inactivity.
              <br />
              Click Continue to remain signed in.
            </Text>
          </ModalSection>
          <ModalSection>
            <Layout.Stack alignItems="center" alignLayout="center">
              <Button
                onClick={() => {
                  handleReset();
                  modal.close();
                }}
                variant="solid"
              >
                Continue Session
              </Button>
              <Button
                onClick={async () => {
                  handleReset();
                  modal.close();
                  await handleLogout();
                }}
                variant="tertiary"
              >
                <Link
                  href="/logout"
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                >
                  Logout
                </Link>
              </Button>
            </Layout.Stack>
          </ModalSection>
        </Modal>
      )}
    </ErrorHandler>
  );
}

/* PropTypes Validation */
IdleTimeout.propTypes = {
  handleLogout: PropTypes.func,
};

/* Default PropTypes */
IdleTimeout.defaultProps = {
  handleLogout: null,
};
