import { DobUntrustedSources } from './DobUntrustedSources';
import { Events } from './Events';
import { RiskCodes } from './RiskCodes';
import { SsnUntrustedSources } from './SsnUntrustedSources';
import { UntrustedSourceRecords } from './UntrustedSourceRecords';

/**
 * widgets
 *
 * @type {{dobUntrustedSources: {component: ((function(*): Element)|*), accessor: string, icon: string, description:
 *   string, title: string, order: number}, ssnUntrustedSources: {component: ((function(*): Element)|*), accessor:
 *   string, icon: string, description: string, title: string, order: number}, untrustedSourceRecords: {component:
 *   ((function(*): Element)|*), accessor: string, icon: string, description: string, title: string, order: number},
 *   riskCodes: {component: ((function(*): Element)|*), accessor: string, icon: string, description: string, title:
 *   string, order: number}, events: {component: ((function(*): Element)|*), accessor: string, icon: string,
 *   description: string, title: string, order: number}}}
 */
export const widgets = {
  dobUntrustedSources: {
    accessor: 'dobUntrustedSources',
    component: DobUntrustedSources,
    description: 'Total volume of source records that do not match IRE Trusted DOB',
    icon: 'attribution',
    order: 3,
    title: 'DOB Untrusted Sources',
  },
  events: {
    accessor: 'events',
    component: Events,
    description: '',
    icon: 'timeline',
    order: 5,
    title: 'Events',
  },
  riskCodes: {
    accessor: 'riskCodes',
    component: RiskCodes,
    description: 'Count of Enterprise Ids associated with IRE risk code',
    icon: 'code',
    order: 1,
    title: 'Risk Codes',
  },
  ssnUntrustedSources: {
    accessor: 'ssnUntrustedSources',
    component: SsnUntrustedSources,
    description: 'Total volume of source records that do not match IRE Trusted SSN',
    icon: 'numbers',
    order: 4,
    title: 'SSN Untrusted Sources',
  },
  untrustedSourceRecords: {
    accessor: 'untrustedSourceRecords',
    component: UntrustedSourceRecords,
    description: 'Records by source that are with an action path or without',
    icon: 'error',
    order: 2,
    title: 'Untrusted Source Records',
  },
};
