import { every, isArray, isBoolean, isEmpty, isUndefined } from 'lodash';

import rules from './validationRules.json';

/**
 * validateCriteria
 *
 * @param criteria
 * @param value
 * @returns {*|boolean}
 */
export const validateCriteria = (criteria, value) => {
  let pattern = '';

  const labels = [];

  criteria.forEach((rule) => {
    labels.push(rules[rule].label);
    pattern = `${pattern}${rules[rule].criteria}`;
  });

  let errorMessage = 'Only';

  if (labels.length > 1) {
    const lastLabel = labels[labels.length - 1];
    const theLabels = labels.join(', ').replace(`, ${lastLabel}`, ` and ${lastLabel}`);
    errorMessage = `${errorMessage} ${theLabels}`;
  } else {
    errorMessage = `${errorMessage} ${labels[0]}`;
  }

  errorMessage = `${errorMessage} are allowed`;

  const regularExpression = new RegExp(`[^${pattern}]`);
  const status = regularExpression.test(value);

  if (status) {
    return errorMessage;
  }

  return true;
};

/**
 * validateLength
 *
 * @param minimum
 * @param maximum
 * @param value
 * @returns {boolean|string}
 */
export const validateLength = (minimum, maximum, value) => {
  const minimumError = `Value must be more than ${minimum} characters`;
  const maximumError = `Value must be less than ${maximum} characters`;

  if (!isUndefined(minimum) && !isUndefined(maximum)) {
    if (value.length < minimum && value.length > maximum) {
      return `Value must be more than ${minimum} characters and less than ${maximum} characters`;
    }

    if (value.length < minimum) {
      return minimumError;
    }

    if (value.length > maximum) {
      return maximumError;
    }
  }

  if (!isUndefined(minimum) && value.length < minimum) {
    return minimumError;
  }

  if (!isUndefined(maximum) && value.length > maximum) {
    return maximumError;
  }

  return true;
};

/**
 * fieldValidator
 *
 * @param field
 * @param value
 * @returns {*|boolean|boolean}
 */
export const fieldValidator = (field, value) => {
  if (isEmpty(field?.criteria)) {
    return true;
  }

  const { criteria } = field;
  const minimum = field?.minLength;
  const maximum = field?.maxLength;

  if (isArray(value)) {
    const criteriaErrors = value.map((theValue) => {
      return fieldValidator(field, theValue);
    });

    const criteriaError = every(criteriaErrors, (item) => {
      return isBoolean(item);
    });

    if (criteriaError !== true) {
      const error = criteriaErrors.find((item) => {
        if (String(item).includes('Only') && String(item).includes('allowed')) {
          return item;
        }
        return undefined;
      });

      return isUndefined(error) ? true : error;
    }

    const lengthError = every(
      // eslint-disable-next-line sonarjs/function-return-type
      value.map((theValue) => {
        return validateLength(minimum, maximum, theValue);
      })
    );

    if (lengthError !== true) {
      return lengthError;
    }
  } else {
    const criteriaError = validateCriteria(criteria, value);

    if (criteriaError !== true) {
      return criteriaError;
    }

    const lengthError = validateLength(minimum, maximum, value);

    if (lengthError !== true) {
      return lengthError;
    }
  }
  return true;
};
