import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isEmpty, isUndefined } from 'lodash';

/**
 * SaveAlert
 *
 * Saves a single alert with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const SaveAlert = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: {},
      headers: {
        'x-api-endpoint': '/alerts',
      },
      method: 'POST',
      params: { mutationKey: theMutationKey },
    };

    if (!isUndefined(thePayload?.id) && !isEmpty(thePayload?.id)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}`;
    }

    if (!isUndefined(thePayload?.assignedUser)) {
      requestArgs.data.assignedUser = thePayload?.assignedUser;
    }

    if (!isUndefined(thePayload?.notes)) {
      requestArgs.data.notes = thePayload?.notes;
    }

    if (!isUndefined(thePayload?.status)) {
      requestArgs.data.status = thePayload?.status;
    }

    if (!isUndefined(thePayload?.triggeredAlertId)) {
      requestArgs.data.triggeredAlertId = thePayload?.triggeredAlertId;
    }

    if (!isUndefined(thePayload?.id)) {
      requestArgs.data.id = thePayload?.id;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/mutations/SaveAlert.js -> SaveAlert()', false, error);

    throw error;
  }
};
