/* eslint-disable import/no-extraneous-dependencies */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { NotFound } from '@src/components/NotFound';
import { useRoutesContext } from '@src/context/Routes/Routes';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { isEmpty, isNull, merge } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';

import { ListActionPaths } from './ActionPaths/List';
import { ManageActionPath } from './ActionPaths/Manage';
import { ViewActionPath } from './ActionPaths/View';
import { Actions } from './Admin/Actions';
import { AlertConfig } from './Admin/AlertConfig';
import { CodeCategories } from './Admin/CodeCategories';
import { Codes } from './Admin/Codes';
import { CommonCriteria } from './Admin/CommonCriteria';
import { Tags } from './Admin/Tags';
import { EidSearch, RiskAnalysis } from './Analysis';
import { OperationsDashboard } from './Dashboards/Operations';
import { RiskDashboard } from './Dashboards/Risk';
import { TagHistoryDashboard } from './Dashboards/TagHistory';
import { ListAlerts } from './Notifications/Alerts/List';
import { ListEvents } from './Notifications/Events/List';
import { ViewEvent } from './Notifications/Events/View';

/**
 * Routes
 *
 * Configures the routes for the application within an authenticated state.
 *
 * @returns {*}
 * @constructor
 */
export const Routes = () => {
  const { hasAccess, hasPermission, roles: userRoles } = useCurrentUser();
  const { currentRoute, currentRoutes, setCurrentRoute, setCurrentRoutes } = useRoutesContext();

  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;
  const routeParams = router?.getRouteParams();
  const queryString = location?.search;
  const queryParams = Object.fromEntries(new URLSearchParams(queryString));

  /**
   * routerConfiguration
   *
   * Control visibility like this:
   *
   *         visibility: {
   *           disabledEnvironments: ['Stage', 'Production'],
   *           enabledEnvironments: ['Local', 'Development'],
   *         },
   *
   */
  const routerConfiguration = useMemo(() => {
    return [
      {
        element: <Outlet />,
        menuOrder: 0,
        navigationLabel: config('APP_NAME'),
        path: '/',
        screenTitle: config('APP_NAME'),
        showInBreadcrumbs: true,
        showInNavigation: false,
        slug: 'root',
      },
      {
        accessor: 'Dashboards',
        children: [
          {
            accessor: 'Dashboards:Operations',
            element: <OperationsDashboard />,
            menuOrder: 1,
            navigationLabel: 'Operations',
            path: '/dashboards/operations',
            screenTitle: 'Operations Dashboard',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'operations-dashboard',
          },
          {
            accessor: 'Dashboards:Risk',
            element: <RiskDashboard />,
            menuOrder: 2,
            navigationLabel: 'Risk',
            path: '/dashboards/risk',
            screenTitle: 'Risk Dashboard',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'risk-dashboard',
          },
          {
            accessor: 'Dashboards:TagHistory',
            element: <TagHistoryDashboard />,
            menuOrder: 3,
            navigationLabel: 'Tag History',
            path: '/dashboards/tag-history',
            screenTitle: 'Tag History Dashboard',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'tag-history-dashboard',
          },
        ],
        element: <Outlet />,
        hideSubNav: false,
        menuOrder: 1,
        navigationIcon: 'dashboard',
        navigationLabel: 'Dashboards',
        path: '/dashboards',
        screenTitle: 'Dashboards',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'dashboards',
      },
      {
        accessor: 'Analysis',
        children: [
          {
            accessor: 'Analysis:EidSearch',
            children: [
              {
                accessor: 'Analysis:EidSearch',
                element: <EidSearch />,
                menuOrder: 0,
                navigationLabel: 'EID Search',
                path: '/analysis/eid-search/:eid',
                screenTitle: 'EID Search',
                showInBreadcrumbs: false,
                showInNavigation: false,
                slug: 'eid-search',
              },
            ],
            element: <EidSearch />,
            menuOrder: 1,
            navigationLabel: 'EID Search',
            path: '/analysis/eid-search',
            screenTitle: 'EID Search',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'eid-search',
          },
          {
            accessor: 'Analysis:RiskAnalysis',
            children: [
              {
                accessor: 'Analysis:RiskAnalysis',
                element: <RiskAnalysis />,
                menuOrder: 0,
                navigationLabel: 'Risk Analysis',
                path: '/analysis/risk-analysis/:key',
                screenTitle: 'Risk Analysis',
                showInBreadcrumbs: false,
                showInNavigation: false,
                slug: 'risk-analysis',
              },
            ],
            element: <RiskAnalysis />,
            menuOrder: 2,
            navigationLabel: 'Risk Analysis',
            path: '/analysis/risk-analysis',
            screenTitle: 'Risk Analysis',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'risk-analysis',
          },
        ],
        element: <Outlet />,
        hideSubNav: false,
        menuOrder: 2,
        navigationIcon: 'bar_chart',
        navigationLabel: 'Analysis',
        path: '/analysis',
        screenTitle: 'Analysis',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'analysis',
      },
      {
        accessor: 'ActionPaths',
        children: [
          {
            accessor: 'ActionPaths:List',
            element: <ListActionPaths />,
            index: true,
            menuOrder: 0,
            navigationLabel: 'Action Paths',
            path: '/action-paths',
            screenTitle: 'Action Paths',
            showInBreadcrumbs: true,
            showInNavigation: false,
            slug: 'list-action-paths',
          },
          {
            accessor: 'ActionPaths:View',
            element: <ViewActionPath />,
            menuOrder: 0,
            navigationLabel: 'View Action Path',
            path: '/action-paths/:id',
            screenTitle: 'View Action Path',
            showInBreadcrumbs: true,
            showInNavigation: false,
            slug: 'view-action-path',
          },
          {
            accessor: 'ActionPaths:Manage',
            element: <ManageActionPath />,
            exact: true,
            menuOrder: 0,
            navigationLabel: 'Manage',
            path: '/action-paths/:id/:action/:mode/:status/step/:stepNumber',
            screenTitle: 'Manage',
            showInBreadcrumbs: true,
            showInNavigation: false,
            slug: 'manage-action-path',
          },
          {
            accessor: 'ActionPaths:Manage',
            element: <ManageActionPath />,
            exact: true,
            menuOrder: 0,
            navigationLabel: 'Manage',
            path: '/action-paths/:id/:action/:mode/:status/step/:stepNumber/:key',
            screenTitle: 'Manage',
            showInBreadcrumbs: true,
            showInNavigation: false,
            slug: 'manage-action-path',
          },
        ],
        element: <Outlet />,
        hideSubNav: true,
        menuOrder: 3,
        navigationIcon: 'create_new_folder',
        navigationLabel: 'Action Paths',
        path: '/action-paths',
        screenTitle: 'Action Paths',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'list-action-paths',
      },
      {
        accessor: 'Notifications',
        children: [
          {
            accessor: 'Notifications:Alerts',
            element: <ListAlerts />,
            menuOrder: 1,
            navigationIcon: 'notifications',
            navigationLabel: 'Alerts',
            path: '/notifications/alerts',
            screenTitle: 'Alerts',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'notifications-list-alerts',
          },
          {
            accessor: 'Notifications:Events',
            children: [
              {
                accessor: 'Notifications:Events:List',
                element: <ListEvents />,
                menuOrder: 0,
                navigationIcon: 'notifications',
                navigationLabel: 'Events',
                path: '/notifications/events',
                screenTitle: 'Events',
                showInBreadcrumbs: true,
                showInNavigation: false,
                slug: 'notifications-list-events',
              },
              {
                accessor: 'Notifications:Events:View',
                element: <ViewEvent />,
                menuOrder: 0,
                navigationLabel: 'View Event',
                path: '/notifications/events/:eventId',
                screenTitle: 'View Event',
                showInBreadcrumbs: true,
                showInNavigation: false,
                slug: 'notifications-view-event',
              },
            ],
            element: <Outlet />,
            menuOrder: 2,
            navigationIcon: 'notifications',
            navigationLabel: 'Events',
            path: '/notifications/events',
            screenTitle: 'Events',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'notifications-list-events',
          },
        ],
        element: <Outlet />,
        hideSubNav: false,
        menuOrder: 4,
        navigationIcon: 'notifications',
        navigationLabel: 'Notifications',
        path: '/notifications',
        screenTitle: 'Notifications',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'notifications',
      },
      {
        accessor: 'Admin',
        children: [
          {
            accessor: 'Admin:Actions',
            element: <Actions />,
            menuOrder: 1,
            navigationLabel: 'Actions',
            path: '/admin/actions',
            screenTitle: 'Actions',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'admin-actions',
          },
          {
            accessor: 'Admin:AlertConfig',
            element: <AlertConfig />,
            menuOrder: 2,
            navigationLabel: 'Alert Config',
            path: '/admin/alert-config',
            screenTitle: 'Alert Config',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'admin-alert-config',
          },
          {
            accessor: 'Admin:Codes',
            element: <Codes />,
            menuOrder: 3,
            navigationLabel: 'Codes',
            path: '/admin/codes',
            screenTitle: 'Codes',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'admin-codes',
          },
          {
            accessor: 'Admin:CodeCategories',
            element: <CodeCategories />,
            menuOrder: 4,
            navigationLabel: 'Code Categories',
            path: '/admin/code-categories',
            screenTitle: 'Code Categories',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'admin-code-categories',
          },
          {
            accessor: 'Admin:CommonCriteria',
            element: <CommonCriteria />,
            menuOrder: 5,
            navigationLabel: 'Common Criteria',
            path: '/admin/common-criteria',
            screenTitle: 'Common Criteria',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'admin-common-criteria',
          },
          {
            accessor: 'Admin:Tags',
            element: <Tags />,
            menuOrder: 5,
            navigationLabel: 'Tags',
            path: '/admin/tags',
            screenTitle: 'Tags',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'admin-tags',
          },
        ],
        element: <Outlet />,
        hideSubNav: false,
        menuOrder: 5,
        navigationIcon: 'admin_panel_settings',
        navigationLabel: 'Admin',
        path: '/admin',
        screenTitle: 'Admin',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'admin',
      },
      {
        element: <NotFound icon="error" message="The requested resource could not be found." title="Not Found" />,
        menuOrder: 0,
        navigationLabel: 'Not Found',
        path: '*',
        screenTitle: 'Not Found',
        showInBreadcrumbs: true,
        showInNavigation: false,
        slug: 'not-found',
      },
    ].filter((route) => {
      if (['not-found', 'root'].includes(route.slug)) {
        return hasAccess;
      }

      if (!isEmpty(route?.children)) {
        route.children = route.children.filter((childRoute) => {
          return hasPermission(childRoute?.accessor, 'navigate');
        });
      }

      return hasPermission(route?.accessor, 'navigate');
    });
  }, [userRoles, hasAccess]);

  /**
   * routeExists
   *
   * Determines if the current route exists in the router configuration.
   *
   * @type {boolean}
   */
  const routeExists = useMemo(() => {
    const matchRoute = (route, thePath) => {
      return router.matchPath({ path: route.path }, thePath);
    };

    const checkTertiaryRoutes = (tertiaryRoutes, thePath) => {
      return tertiaryRoutes.some((tertiaryRoute) => {
        return matchRoute(tertiaryRoute, thePath);
      });
    };

    const checkSecondaryRoutes = (secondaryRoutes, thePath) => {
      return secondaryRoutes.some((secondaryRoute) => {
        if (matchRoute(secondaryRoute, thePath)) {
          return true;
        }
        if (secondaryRoute.children) {
          return checkTertiaryRoutes(secondaryRoute.children, thePath);
        }
        return false;
      });
    };

    return routerConfiguration
      .filter((route) => {
        return route.path !== '*';
      })
      .some((primaryRoute) => {
        if (matchRoute(primaryRoute, path)) {
          return true;
        }
        if (primaryRoute.children) {
          return checkSecondaryRoutes(primaryRoute.children, path);
        }
        return false;
      });
  }, [routerConfiguration, path]);

  /**
   * Handle Redirects
   */
  useEffect(() => {
    (() => {
      if (!routeExists && path !== '/not-found') {
        return router.navigate('/not-found');
      }

      if (path === '/' || path === '/dashboards') {
        return router.navigate('/dashboards/risk');
      }

      if (path === '/analysis') {
        return router.navigate('/analysis/risk-analysis');
      }

      if (path === '/notifications') {
        return router.navigate('/notifications/alerts');
      }

      if (path.includes('/admin') && !hasPermission('Admin', 'navigate')) {
        return router.navigate('/');
      }

      if (path === '/admin' && hasPermission('Admin', 'navigate')) {
        return router.navigate('/admin/actions');
      }

      return false;
    })();
  }, [path, routeExists]);

  /**
   * getTertiaryRoutes
   *
   * @param routes
   * @param secondaryRoute
   * @param secondaryIndex
   * @param primaryIndex
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getTertiaryRoutes = (routes = [], secondaryRoute = {}, secondaryIndex = 0, primaryIndex = 0) => {
    const theRoutes = routes;
    let theRoute = {};

    secondaryRoute?.children.forEach((route, index) => {
      const match = router.matchPath(
        {
          exact: false,
          path: route.path,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[primaryIndex].current = true;
        theRoutes[primaryIndex].inUrl = true;
        theRoutes[primaryIndex].children[secondaryIndex].current = false;
        theRoutes[primaryIndex].children[secondaryIndex].inUrl = false;
        theRoutes[primaryIndex].children[secondaryIndex].children[index].current = false;
        theRoutes[primaryIndex].children[secondaryIndex].children[index].inUrl = false;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              parent: secondaryRoute,
              queryParams,
              queryString,
              routeParams,
            },
          };
        }
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getSecondaryRoutes
   *
   * @param routes
   * @param primaryRoute
   * @param primaryIndex
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getSecondaryRoutes = (routes = [], primaryRoute = {}, primaryIndex = 0) => {
    let theRoutes = routes;
    let theRoute = {};

    primaryRoute?.children.forEach((route, index) => {
      const match = router.matchPath(
        {
          exact: true,
          path: route.path,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[primaryIndex].current = false;
        theRoutes[primaryIndex].inUrl = false;
        theRoutes[primaryIndex].children[index].current = false;
        theRoutes[primaryIndex].children[index].inUrl = false;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              parent: primaryRoute,
              queryParams,
              queryString,
              routeParams,
            },
          };
        }
      } else if (!isEmpty(route?.children)) {
        const tertiaryRoutes = getTertiaryRoutes(theRoutes, route, index, primaryIndex);
        theRoutes = merge([], theRoutes, tertiaryRoutes.theRoutes);
        theRoute = merge({}, theRoute, tertiaryRoutes.theRoute);
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getPrimaryRoutes
   *
   * @param routes
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getPrimaryRoutes = (routes = []) => {
    let theRoutes = routes.filter((route) => {
      return route.path !== '*';
    });
    let theRoute = {};

    routes
      .filter((route) => {
        return route.path !== '*';
      })
      .forEach((route, index) => {
        const match = router.matchPath(
          {
            exact: true,
            path: route.path,
            strict: false,
          },
          path
        );

        if (!isNull(match)) {
          theRoutes[index].current = false;
          theRoutes[index].inUrl = false;

          if (isEmpty(theRoute)) {
            theRoute = {
              ...route,
              ...match,
              ...{
                parent: null,
                queryParams,
                queryString,
                routeParams,
              },
            };
          }
        } else if (!isEmpty(route?.children)) {
          const secondaryRoutes = getSecondaryRoutes(theRoutes, route, index);
          theRoutes = merge([], theRoutes, secondaryRoutes.theRoutes);
          theRoute = merge({}, theRoute, secondaryRoutes.theRoute);
        }
      });

    return { theRoute, theRoutes };
  };

  /**
   * getRoutes
   *
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getRoutes = () => {
    return getPrimaryRoutes(routerConfiguration);
  };

  /**
   * Determines the current route(s) based on the URL.
   */
  useEffect(() => {
    const { theRoute, theRoutes } = getRoutes();

    if (theRoute !== currentRoute) {
      setCurrentRoute(theRoute);
    }

    if (theRoutes !== currentRoutes) {
      setCurrentRoutes(theRoutes);
    }
  }, [path, routerConfiguration]);

  return useRoutes(routerConfiguration);
};
