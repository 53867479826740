import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import React from 'react';

/**
 * Skeleton
 *
 * @returns {Element}
 * @constructor
 */
export function Skeleton() {
  return (
    <ErrorHandler location="src/layouts/Dashboard/sections/Sidebar/components/Skeleton/Skeleton.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        style={{ width: '280px' }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <AbyssSkeleton data-testid="sidebar-skeleton" height="400px" width="100%" />
            </Layout.Stack>
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
}
