import { GetUserRoles } from './auth/GetUserRoles';
import { GetActionPath } from './GetActionPath';
import { GetActionPathCount } from './GetActionPathCount';
import { GetActionPathStatistics } from './GetActionPathStatistics';
import { GetActionPathTrackerStatus } from './GetActionPathTrackerStatus';
import { GetAggregationView } from './GetAggregationView';
import { GetCommonCriteria } from './GetCommonCriteria';
import { GetCommonCriteriaVersion } from './GetCommonCriteriaVersion';
import { GetEvent } from './GetEvent';
import { GetExportDetails } from './GetExportDetails';
import { GetRiskDashboardStatistics } from './GetRiskDashboardStatistics';
import { GetRiskRecord } from './GetRiskRecord';
import { GetRiskRecordsCount } from './GetRiskRecordsCount';
import { GetRiskRecordTimeline } from './GetRiskRecordTimeline';
import { GetRiskTrendReport } from './GetRiskTrendReport';
import { GetTagHistory } from './GetTagHistory';
import { GetUntrustedSourceCount } from './GetUntrustedSourceCount';
import { GetValidActionPathName } from './GetValidActionPathName';
import { GetValidCriteriaName } from './GetValidCriteriaName';
import { ListActionPaths } from './ListActionPaths';
import { ListActions } from './ListActions';
import { ListActionStatuses } from './ListActionStatuses';
import { ListAlertConfigurations } from './ListAlertConfigurations';
import { ListAlerts } from './ListAlerts';
import { ListChronoUnits } from './ListChronoUnits';
import { ListCodeCategories } from './ListCodeCategories';
import { ListCodes } from './ListCodes';
import { ListCommonCriteria } from './ListCommonCriteria';
import { ListCommonCriteriaVersions } from './ListCommonCriteriaVersions';
import { ListEvents } from './ListEvents';
import { ListEventTypes } from './ListEventTypes';
import { ListImpactedSystems } from './ListImpactedSystems';
import { ListRedRiskCodes } from './ListRedRiskCodes';
import { ListRiskCodes } from './ListRiskCodes';
import { ListRiskRecords } from './ListRiskRecords';
import { ListSources } from './ListSources';
import { ListTags } from './ListTags';
import { ListTrustedValueHistory } from './ListTrustedValueHistory';
import { ListViews } from './ListViews';

/**
 * queries
 *
 * @type {{ListCommonCriteriaVersions: ((function({}=): Promise<*>)|*), GetRiskRecordsCount: ((function({}=):
 *   Promise<*>)|*), GetValidActionPathName: ((function({}=): Promise<*>)|*), GetRiskTrendReport: ((function({}=):
 *   Promise<*>)|*), ListCodeCategories: ((function({}=): Promise<*>)|*), ListCommonCriteria: ((function({}=):
 *   Promise<*>)|*), GetUntrustedSourceCount: ((function({}=): Promise<*>)|*), GetExportDetails: ((function({}=):
 *   Promise<*>)|*), ListRiskCodes: ((function({}=): Promise<*>)|*), GetCommonCriteriaVersion: ((function({}=):
 *   Promise<*>)|*), GetRiskRecordTimeline: ((function({}=): Promise<{timelines: {[p: string]: *}}>)|*),
 *   ListAlertConfigurations: ((function({}=): Promise<*>)|*), ListAlerts: ((function({}=): Promise<*>)|*),
 *   ListActionPaths: ((function({}=): Promise<*>)|*), ListChronoUnits: ((function({}=): Promise<*>)|*), GetTagHistory:
 *   ((function({}=): Promise<*>)|*), GetRiskDashboardStatistics: ((function({}=): Promise<*>)|*), GetActionPathCount:
 *   ((function({}=): Promise<*>)|*), ListActions: ((function({}=): Promise<*>)|*), GetUserRoles: ((function({}=):
 *   Promise<{}>)|*), GetRiskRecord: ((function({}=): Promise<*>)|*), ListSources: ((function({}=): Promise<*>)|*),
 *   ListCodes: ((function({}=): Promise<*>)|*), ListRiskRecords: ((function({}=): Promise<*>)|*), GetCommonCriteria:
 *   ((function({}=): Promise<*>)|*), ListRedRiskCodes: ((function({}=): Promise<*>)|*), ListViews: ((function({}=):
 *   Promise<*>)|*), ListImpactedSystems: ((function({}=): Promise<*>)|*), GetActionPath: ((function({}=):
 *   Promise<*>)|*), GetValidCriteriaName: ((function({}=): Promise<*>)|*), GetActionPathStatistics: ((function({}=):
 *   Promise<*>)|*), ListEvents: ((function({}=): Promise<*>)|*), GetActionPathTrackerStatus: ((function({}=):
 *   Promise<*>)|*), ListEventTypes: ((function({}=): Promise<*>)|*), ListTrustedValueHistory: ((function({}=):
 *   Promise<*>)|*), GetAggregationView: ((function({}=): Promise<*>)|*), ListTags: ((function({}=): Promise<*>)|*),
 *   GetEvent: ((function({}=): Promise<*>)|*), ListActionStatuses: ((function({}=): Promise<*>)|*)}}
 */
export const queries = {
  GetActionPath,
  GetActionPathCount,
  GetActionPathStatistics,
  GetActionPathTrackerStatus,
  GetAggregationView,
  GetCommonCriteria,
  GetCommonCriteriaVersion,
  GetEvent,
  GetExportDetails,
  GetRiskDashboardStatistics,
  GetRiskRecord,
  GetRiskRecordsCount,
  GetRiskRecordTimeline,
  GetRiskTrendReport,
  GetTagHistory,
  GetUntrustedSourceCount,
  GetUserRoles,
  GetValidActionPathName,
  GetValidCriteriaName,
  ListActionPaths,
  ListActions,
  ListActionStatuses,
  ListAlertConfigurations,
  ListAlerts,
  ListChronoUnits,
  ListCodeCategories,
  ListCodes,
  ListCommonCriteria,
  ListCommonCriteriaVersions,
  ListEvents,
  ListEventTypes,
  ListImpactedSystems,
  ListRedRiskCodes,
  ListRiskCodes,
  ListRiskRecords,
  ListSources,
  ListTags,
  ListTrustedValueHistory,
  ListViews,
};
