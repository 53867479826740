import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useWizardContext } from '@src/features/ActionPaths/components/misc/Wizard/context';
import { AdditionalCriteriaWidget } from '@src/features/Criteria/components/widgets/AdditionalCriteria';
import { CommonCriteriaWidget } from '@src/features/Criteria/components/widgets/CommonCriteria';
import { useCommonCriteria } from '@src/features/Criteria/hooks/useCommonCriteria';
import { useCommonCriteriaVersions } from '@src/features/Criteria/hooks/useCommonCriteriaVersions';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

/**
 * EntranceCriteria
 *
 * Provides the user with a screen to specify a list of filters to find risk records by.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function EntranceCriteria(props) {
  const { form, setSelectedCommonEntrance } = props;

  const { action, actionPath, currentStep } = useWizardContext();

  const [initialFetch, setInitialFetch] = useState(false);
  const { criteriaList, fetch: fetchCriteria } = useCommonCriteria();
  const { fetch: fetchCriteriaVersions, versionsList } = useCommonCriteriaVersions();

  /**
   * retrieve common criteria and common criteria versions saved to the action path
   */
  useEffect(() => {
    if (initialFetch === false && !isUndefined(actionPath?.criteria) && !isEmpty(actionPath?.criteria)) {
      fetchCriteria(actionPath?.criteria);
      fetchCriteriaVersions(actionPath?.criteria);
      setInitialFetch(true);
    }
  }, [actionPath, initialFetch]);

  /**
   * loop through criteriaList and versionsList to determine if the versions are a mismatch
   * @type {{}}
   */
  const needsUpdate = useMemo(() => {
    const items = {};

    if (!isEmpty(criteriaList) && !isEmpty(versionsList)) {
      criteriaList
        ?.filter((item) => {
          return item?.isActive;
        })
        .forEach((criteria) => {
          const version = versionsList.find((item) => {
            return criteria?.id === item?.commonCriteriaId;
          });

          if (!isUndefined(version) && criteria?.activeVersionNbr !== version?.version) {
            items[criteria?.id] = {
              from: version,
              to: criteria?.activeCommonCriteriaVersion,
            };
          }
        });
    }

    return items;
  }, [criteriaList, versionsList, actionPath]);

  /**
   * loop through criteriaList and versionsList to determine if the versions have been deactivated
   * @type {{}}
   */
  const needsRemoval = useMemo(() => {
    const items = {};

    if (!isEmpty(criteriaList) && !isEmpty(versionsList)) {
      criteriaList.forEach((criteria) => {
        const version = versionsList.find((item) => {
          return criteria?.id === item?.commonCriteriaId;
        });

        if (!isUndefined(version) && criteria?.isActive === false) {
          items[criteria?.id] = {
            criteria,
            version,
          };
        }
      });
    }

    return items;
  }, [criteriaList, versionsList, actionPath]);

  /**
   * loop through criteriaList and versionsList to determine if action needs to be taken
   * @type {{update: *[], remove: *[]}}
   */
  const needsReview = useMemo(() => {
    const items = {
      remove: [],
      update: [],
    };

    if (!isEmpty(criteriaList) && !isEmpty(versionsList)) {
      criteriaList
        .filter((item) => {
          return item?.isActive;
        })
        .forEach((criteria) => {
          const version = versionsList.find((item) => {
            return criteria?.id === item?.commonCriteriaId;
          });

          if (!isUndefined(version) && criteria?.activeVersionNbr !== version?.version) {
            items.update.push(criteria?.name);
          }
        });

      criteriaList.forEach((criteria) => {
        const version = versionsList.find((item) => {
          return criteria?.id === item?.commonCriteriaId;
        });

        if (!isUndefined(version) && criteria?.isActive === false) {
          items.remove.push(criteria?.name);
        }
      });
    }

    return items;
  }, [criteriaList, versionsList, actionPath]);

  /**
   * check if needsUpdate or needsRemoval is not empty and set error message
   */
  useEffect(() => {
    if (!isEmpty(needsUpdate) || !isEmpty(needsRemoval)) {
      if (isUndefined(form?.formState?.errors?.criteria?.entrance?.commonCriteriaVersionsIds)) {
        form?.setError('criteria.entrance.commonCriteriaVersionsIds', {
          message: 'Common criteria needs review.',
          type: 'invalidCriteria',
        });
      }
    }

    if (
      isEmpty(needsUpdate) &&
      isEmpty(needsRemoval) &&
      !isUndefined(form?.formState?.errors?.criteria?.entrance?.commonCriteriaVersionsIds)
    ) {
      form?.clearErrors('criteria.entrance.commonCriteriaVersionsIds');
    }
  }, [needsUpdate, needsRemoval]);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/steps/EntranceCriteria/EntranceCriteria.jsx">
      <Grid>
        <Grid.Col css={{ paddingTop: 'var(--abyss-space-lg)' }} span={{ xs: '100%' }}>
          <Heading offset={1}>{currentStep?.label}</Heading>
          <p>{currentStep?.description}</p>
        </Grid.Col>
        <Grid.Col span={{ xs: '100%' }}>
          <CommonCriteriaWidget
            context="entrance"
            fetchCriteria={fetchCriteria}
            fetchCriteriaVersions={fetchCriteriaVersions}
            filterKey="criteria.entrance.common"
            form={form}
            needsRemoval={needsRemoval}
            needsReview={needsReview}
            needsUpdate={needsUpdate}
            setSelected={setSelectedCommonEntrance}
            showActions={action === 'edit'}
          />
        </Grid.Col>
        <Grid.Col span={{ xs: '100%' }}>
          <AdditionalCriteriaWidget filterKey="criteria.entrance.additional" form={form} />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
}

EntranceCriteria.propTypes = {
  form: PropTypes.shape({
    clearErrors: PropTypes.func,
    formState: PropTypes.shape({
      errors: PropTypes.shape({
        criteria: PropTypes.shape({
          entrance: PropTypes.shape({
            commonCriteriaVersionsIds: PropTypes.shape({
              message: PropTypes.string,
              type: PropTypes.string,
            }),
          }),
        }),
      }),
    }),
    getValues: PropTypes.func,
    setError: PropTypes.func,
  }),
  setSelectedCommonEntrance: PropTypes.func,
};

EntranceCriteria.defaultProps = {
  form: {},
  setSelectedCommonEntrance: () => {},
};
