import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { motion } from 'framer-motion';
import React from 'react';

import { Table } from './components/Table';

/**
 * Actions
 *
 * Provides the user with a screen listing the existing actions.
 *
 * @returns {Element}
 * @constructor
 */
export function Actions() {
  const { useApiQuery } = useApi();

  const [ListActions, { data, error, isFetching, isLoading }] = useApiQuery('ListActions');

  return (
    <ErrorHandler location="src/routes/private/Admin/Actions/Actions.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Flex alignItems="center" direction="row">
              <Heading offset={0}>Actions</Heading>
            </Flex>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Table
              error={error}
              isLoading={isLoading || isFetching}
              requestArgs={{ page: 0, size: 25, sort: 'lastModifiedDate,desc' }}
              requestFunction={ListActions}
              requestKey="ListActions"
              rows={data?.content || []}
              totalPages={data?.totalPages || 1}
              totalRecords={data?.totalElements || 0}
            />
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
}
