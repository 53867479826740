import { dayjs } from '@abyss/web/tools/dayjs';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined, orderBy } from 'lodash';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import stc from 'string-to-color';

import { Styles } from './includes/styles';

const stringToColor = stc;

const Chart = dynamic(
  () => {
    return import('react-apexcharts');
  },
  { ssr: false }
);

/**
 * CandlestickChart
 *
 * Displays the tag history data in a candlestick chart.
 *
 * @TODO - Refactor this chart to be more dynamic and reusable. Currently specific for tag history dashboard.
 *
 * @param props
 * @returns {React.JSX.Element|string}
 * @constructor
 */
export function CandlestickChart(props) {
  const { data, isLogarithmicScale } = props;

  const [series, setSeries] = useState([]);
  const [colors, setColors] = useState([]);

  /**
   * maps data from the api into a format which the chart can use to display the data
   */
  useEffect(() => {
    const theSeries = [];

    if (!isEmpty(data?.tagInfoList)) {
      orderBy(data?.tagInfoList, ['tag'], ['asc']).forEach((tagInfo) => {
        const dataset = [];
        if (!isEmpty(tagInfo?.deviationInfoList)) {
          orderBy(tagInfo?.deviationInfoList, ['statDate'], ['asc']).forEach((tagData) => {
            const openPrice = tagData?.tagCount - tagData?.diffFromPreviousDay;
            const closePrice = tagData?.tagCount;
            const highPrice = openPrice + tagData?.varianceDetails?.totalAdded;
            const lowPrice = openPrice - tagData?.varianceDetails?.totalRemoved;

            dataset.push({
              count: tagData?.tagCount,
              date: tagData?.statDate,
              decrease: tagData?.varianceDetails?.totalRemoved,
              increase: tagData?.varianceDetails?.totalAdded,
              tag: tagInfo?.tag,
              x: dayjs(tagData?.statDate).format('YYYY-MM-DD'),
              y: [openPrice, highPrice, lowPrice, closePrice],
            });
          });
        }
        if (!isEmpty(dataset)) {
          theSeries.push({ data: dataset, name: tagInfo?.tag });
        }
      });
    }

    if (!isEmpty(theSeries) && theSeries !== series) {
      setSeries(theSeries);
      setColors(
        theSeries.map((tag) => {
          return stringToColor(tag.name);
        })
      );
    }
  }, [data]);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/screens/TagHistory/components/CandlestickChart/CandlestickChart.jsx">
      <Styles>
        <div id="chart">
          {!isUndefined(window) && (
            <Chart
              height="100%"
              options={{
                chart: {
                  id: 'candlestick',
                  toolbar: {
                    show: false,
                  },
                  type: 'candlestick',
                },
                colors,
                legend: {
                  show: true,
                },
                plotOptions: {
                  candlestick: {
                    colors: {
                      downward: themeConfiguration?.theme?.colors?.success1,
                      upward: themeConfiguration?.theme?.colors?.error1,
                    },
                    wick: {
                      useFillColor: false,
                    },
                  },
                },
                title: {
                  align: 'left',
                },
                tooltip: {
                  custom({ dataPointIndex, seriesIndex, w }) {
                    const theData = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

                    return (
                      `<ul style="padding: var(--abyss-space-md)">` +
                      `<li><strong>Tag</strong>: ${theData?.tag}</li>` +
                      `<li><strong>Date</strong>: ${dayjs(theData?.date).format('MM/DD/YYYY')}</li>` +
                      `<li><strong>Count</strong>: ${Number(theData?.count).toLocaleString('en-US')}</li>` +
                      `<li><strong>Increase</strong>: ${Number(theData?.increase).toLocaleString('en-US')}</li>` +
                      `<li><strong>Decrease</strong>: ${Number(theData?.decrease).toLocaleString('en-US')}</li>` +
                      `</ul>`
                    );
                  },
                },
                xaxis: {
                  labels: {
                    format: 'yyyy-MM-dd',
                    rotate: 0,
                    rotateAlways: false,
                    show: true,
                    showDuplicates: false,
                    trim: false,
                  },
                  type: 'datetime',
                },
                yaxis: {
                  labels: {
                    formatter: (value) => {
                      return Number(value).toLocaleString('en-US');
                    },
                  },
                  logarithmic: isLogarithmicScale || false,
                  tooltip: {
                    enabled: true,
                  },
                },
              }}
              series={series}
              type="candlestick"
            />
          )}
        </div>
      </Styles>
    </ErrorHandler>
  );
}

CandlestickChart.propTypes = {
  data: PropTypes.shape({}).isRequired,
  isLogarithmicScale: PropTypes.bool,
};

CandlestickChart.defaultProps = {
  isLogarithmicScale: false,
};
