import { useToast } from '@abyss/web/hooks/useToast';
import { logger } from '@src/includes/logger';
import { requests } from '@src/requests';
import { useQueries } from '@tanstack/react-query';
import { isFunction, isObject, isString, isUndefined, merge } from 'lodash';

/**
 * Hook: useApiQueries
 *
 * Make multiple api requests in parallel.
 *
 * @param queries
 * @returns {{}}
 */
export function useApiQueries(queries = []) {
  const { toast } = useToast();

  const theQueries = useQueries({
    queries: queries.map((query) => {
      const queryKey = [];

      if (isString(query)) {
        queryKey.push(query);
      }

      if (isObject(query)) {
        queryKey.push(query?.key);
        queryKey.push(query?.args);
      }

      let requestKey = queryKey?.[0];
      if (queryKey?.[0].includes('-')) {
        const keyItems = queryKey?.[0].split('-');
        requestKey = keyItems?.[0];
      }

      let queryFunction;

      if (!isUndefined(requests?.queries?.[requestKey])) {
        queryFunction = requests?.queries?.[requestKey];
      }

      return merge(
        {},
        {
          excludedHttpCodes: [],
          queryFn: async (args) => {
            try {
              let response;

              if (isUndefined(queryFunction)) {
                throw new Error(`Request not found for query key: ${queryKey[0]}`);
              }

              if (isFunction(queryFunction)) {
                response = await queryFunction(args.queryKey);
              }

              return {
                [queryKey[0]]: response,
              };
            } catch (errorObject) {
              const { error } = errorObject;

              const excludedHttpCodes = query?.options?.excludedHttpCodes || [];

              if (!excludedHttpCodes?.includes(error?.statusCode)) {
                toast.show({
                  autoClose: false,
                  id: `useApiQueries-${queryKey[0]}`,
                  message: error?.message,
                  title: `API Error - ${queryKey[0]}`,
                  variant: 'error',
                });
              }

              logger.error('src/context/Api/Api.jsx -> useApiQueries', false, error);
              throw errorObject;
            }
          },
          queryKey,
        },
        query?.options || {}
      );
    }),
  });

  const response = {};

  theQueries.forEach((theQuery) => {
    if (isUndefined(theQuery?.data)) {
      return false;
    }
    const query = { ...theQuery };
    const keys = Object.keys(theQuery?.data);
    query.data = theQuery?.data?.[keys[0]];

    response[keys[0]] = query;
    return true;
  });

  return response;
}
