import { useRouter } from '@abyss/web/hooks/useRouter';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Badge } from '@abyss/web/ui/Badge';
import { Box } from '@abyss/web/ui/Box';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { SourcesTooltip } from '@src/components/common/tooltips';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-query';
import { ModeBadge } from '@src/features/ActionPaths/components/misc/ModeBadge';
import { ActivationModal } from '@src/features/ActionPaths/components/modals/Activation';
import { DeletionModal } from '@src/features/ActionPaths/components/modals/Deletion';
import { DuplicationModal } from '@src/features/ActionPaths/components/modals/Duplication';
import { useDeletion } from '@src/features/ActionPaths/hooks/useDeletion';
import { useDuplication } from '@src/features/ActionPaths/hooks/useDuplication';
import { useSave } from '@src/features/ActionPaths/hooks/useSave';
import { useCurrentUser } from '@src/features/Users/hooks/useCurrentUser';
import { isEmpty, isUndefined, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useMemo, useState } from 'react';

import configuration from './includes/configuration.json';
import { Styles } from './includes/styles';

/**
 * Table: ListDetails
 *
 * Displays a list of action paths within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function ActionPaths(props) {
  const {
    error,
    headerLeft,
    isLoading,
    refetch,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    totalPages,
    totalRecords,
  } = props;

  const router = useRouter();
  const location = router?.getLocation();
  const urlParams = new URLSearchParams(location?.search);
  const highlight = urlParams.getAll('highlight');

  const [reload, setReload] = useState(false);
  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false);
  const [isDuplicationModalOpen, setIsDuplicationModalOpen] = useState(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [currentActionPath, setCurrentActionPath] = useState({});

  const { handleSave: handleActivation } = useSave('activate');
  const { handleSave: handleDeactivation } = useSave('deactivate');
  const { handleDuplication } = useDuplication();
  const { handleDeletion } = useDeletion();

  const { hasPermission } = useCurrentUser();

  const canActivate = hasPermission('ActionPaths', 'activate');
  const canDeactivate = hasPermission('ActionPaths', 'deactivate');
  const canDuplicate = hasPermission('ActionPaths', 'duplicate');
  const canEdit = hasPermission('ActionPaths', 'edit');
  const canDelete = hasPermission('ActionPaths', 'delete');

  /**
   * renderCell
   *
   * Displays the cell contents and determines if it should be highlighted or not.
   *
   * @param content
   * @param row
   * @returns {JSX.Element|*}
   */
  const renderCell = (content, row) => {
    if (highlight.includes(row?.original?.id)) {
      return (
        <Box
          color="$tint2"
          css={{
            marginLeft: '-10px',
            marginRight: '-10px',
            padding: 0,
            position: 'absolute',
          }}
        >
          <Layout.Group
            css={{
              height: '100%',
              marginLeft: '6px',
              marginRight: '6px',
            }}
          >
            {content}
          </Layout.Group>
        </Box>
      );
    }
    return content;
  };

  /**
   * renderCellName
   *
   * Displays the name of the action path within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellName = ({ cell, row }) => {
    if (String(row?.original?.status).toLowerCase() === 'active') {
      return renderCell(<Link href={`/action-paths/${row?.original?.id}`}>{cell?.value}</Link>, row);
    }
    return renderCell(cell?.value, row);
  };

  /**
   * renderCellLastUpdated
   *
   * Displays the date and time of when the action path was last updated within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellLastUpdated = ({ cell, row }) => {
    return renderCell(dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss'), row);
  };

  /**
   * renderCellLastUpdatedBy
   *
   * Displays the email address of the person who last modified the action path within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellLastUpdatedBy = ({ cell, row }) => {
    return renderCell(cell?.value, row);
  };

  /**
   * renderCellStatus
   *
   * Displays the current status of the action path within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellStatus = ({ cell, row }) => {
    const badges = {
      active: 'success',
      draft: 'neutral',
      inactive: 'error',
    };

    if (!isUndefined(badges[cell?.value.toLowerCase()])) {
      return renderCell(
        <Badge outline variant={badges[cell?.value.toLowerCase()]}>
          <span style={{ textTransform: 'capitalize' }}>{cell?.value.toLowerCase()}</span>
        </Badge>,
        row
      );
    }

    return renderCell(cell.value, row);
  };

  /**
   * renderCellRemediationType
   *
   * @TODO Needs description.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderRemediationType = ({ cell, row }) => {
    return renderCell(cell.value, row);
  };

  /**
   * renderCellSourcesRemediated
   *
   * Displays a concatenated/truncated list of impacted sources within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellSourcesRemediated = ({ cell, row }) => {
    if (!isEmpty(row?.original?.assignments)) {
      const sources = [];

      row?.original?.assignments.forEach((assignment) => {
        if (!sources.includes(assignment?.impactedSource)) {
          sources.push(assignment?.impactedSource);
        }
      });

      return renderCell(
        <Tooltip content={<SourcesTooltip sources={sources} />} placement="auto">
          <span className="truncate">{sources.join(', ')}</span>
        </Tooltip>,
        row
      );
    }

    return renderCell(cell.value, row);
  };

  /**
   * renderCellMode
   *
   * displays the mode of the action path within a cell.
   *
   * @param row
   * @param cell
   * @returns {JSX.Element|*}
   */
  const renderCellMode = ({ row }) => {
    return renderCell(<ModeBadge actionPath={row?.original} />, row);
  };

  /**
   * canBeActivated
   *
   * Verifies everything needed exists before allowing the user to click activate.
   *
   * @param row
   * @returns {boolean}
   */
  const canBeActivated = (row) => {
    if (row?.original?.manualAssociation === true) {
      return true;
    }

    return (
      !isEmpty(row?.original?.criteria?.entrance?.merged) &&
      !isEmpty(row?.original?.assignments) &&
      row?.original?.status === 'DRAFT'
    );
  };

  /**
   * renderCellActions
   *
   * Provides the user with a menu of actionable items to perform on a specific action path within the list of action
   * paths.
   *
   * @param row
   * @returns {JSX.Element}
   */
  const renderCellActions = ({ row }) => {
    const menuItems = [
      {
        disabled: !canDeactivate,
        icon: <IconSymbol icon="remove_circle_outline" variant="outlined" />,
        isSeparated: true,
        onClick: async () => {
          await handleDeactivation({ ...row?.original, ...{ status: 'INACTIVE' } }, refetch);
        },
        title: 'Deactivate',
      },
      {
        disabled: !canActivate || canBeActivated(row) !== true,
        icon: <IconSymbol icon="check_circle_outline" variant="outlined" />,
        isSeparated: true,
        onClick: async () => {
          setIsActivationModalOpen(true);
          setCurrentActionPath(row?.original);
        },
        title: 'Activate',
      },
      {
        disabled: !canDuplicate,
        icon: <IconSymbol icon="content_copy" variant="outlined" />,
        onClick: async () => {
          setIsDuplicationModalOpen(true);
          setCurrentActionPath(row?.original);
        },
        title: 'Duplicate',
      },
      {
        disabled: !canEdit || !!['ACTIVE', 'INACTIVE'].includes(row?.original?.status),
        icon: <IconSymbol icon="edit" variant="outlined" />,
        onClick: async () => {
          const id = row?.original?.id;
          const mode = row?.original?.manualAssociation === true ? 'manual' : 'automatic';
          const status = String(row?.original?.status).toLowerCase();
          router?.navigate(`/action-paths/${id}/edit/${mode}/${status}/step/1`);
        },
        title: 'Edit',
      },
      {
        disabled: !canDelete || row?.original?.status !== 'DRAFT',
        icon: <IconSymbol icon="delete" variant="outlined" />,
        onClick: async () => {
          setIsDeletionModalOpen(true);
          setCurrentActionPath(row?.original);
        },
        title: 'Delete',
      },
    ].filter((menuItem) => {
      if (menuItem.title === 'Deactivate' && row?.original?.status === 'INACTIVE') {
        return false;
      }
      if (menuItem.title === 'Activate' && row?.original?.status === 'ACTIVE') {
        return false;
      }
      return !(['Deactivate'].includes(menuItem.title) && !['ACTIVE'].includes(row?.original?.status));
    });

    return (
      <div
        style={{
          marginLeft: '-10px',
          marginRight: '-10px',
        }}
      >
        <Layout.Group
          css={{
            height: '100%',
            marginLeft: '2px',
            marginRight: '2px',
          }}
        >
          <DropdownMenu
            before={<IconSymbol color="#6F6F6F" icon="more_vert" size={24} variant="outlined" />}
            hideLabel
            menuItems={menuItems}
          />
        </Layout.Group>
      </div>
    );
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      switch (column.Header) {
        case 'actions':
          column.Cell = renderCellActions;
          break;
        case 'Last Updated':
          column.Cell = renderCellLastUpdated;
          break;
        case 'Last Updated By':
          column.Cell = renderCellLastUpdatedBy;
          break;
        case 'Mode':
          column.Cell = renderCellMode;
          break;
        case 'Name':
          column.Cell = renderCellName;
          break;
        case 'Remediation Type':
          column.Cell = renderRemediationType;
          break;
        case 'Source(s) Remediated':
          column.Cell = renderCellSourcesRemediated;
          break;
        case 'Status':
          column.Cell = renderCellStatus;
          break;
        default:
          break;
      }

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      return column;
    });
  }, [canDelete, canDuplicate, canEdit, canDeactivate, canActivate]);

  /**
   * Reset the reload status if it was already set once.
   */
  useLayoutEffect(() => {
    if (reload === true) {
      setReload(false);
    }
  }, [reload]);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/tables/ActionPaths/ActionPaths.jsx">
      <Styles>
        <TableComponent
          {...{
            columns,
            configuration,
            error,
            headerLeft,
            isLoading,
            reload,
            requestArgs,
            requestFunction,
            requestKey,
            rows,
            setReload,
            totalPages,
            totalRecords,
          }}
        />
        {isActivationModalOpen && !isEmpty(currentActionPath) && (
          <ActivationModal
            actionPath={currentActionPath}
            handleActivation={handleActivation}
            isOpen={isActivationModalOpen}
            refetch={refetch}
            setActionPath={setCurrentActionPath}
            setIsOpen={setIsActivationModalOpen}
          />
        )}
        {isDuplicationModalOpen && !isEmpty(currentActionPath) && (
          <DuplicationModal
            actionPath={currentActionPath}
            handleDuplication={handleDuplication}
            isOpen={isDuplicationModalOpen}
            refetch={refetch}
            setActionPath={setCurrentActionPath}
            setIsOpen={setIsDuplicationModalOpen}
          />
        )}
        <DeletionModal
          actionPath={currentActionPath}
          handleDeletion={handleDeletion}
          isOpen={isDeletionModalOpen}
          refetch={refetch}
          setActionPath={setCurrentActionPath}
          setIsOpen={setIsDeletionModalOpen}
        />
      </Styles>
    </ErrorHandler>
  );
}

ActionPaths.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.number,
  }),
  headerLeft: PropTypes.node,
  isLoading: PropTypes.bool,
  refetch: PropTypes.func,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      assignments: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          impactedSource: PropTypes.string,
        })
      ),
      entranceCriteria: PropTypes.string,
      id: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      manualAssociation: PropTypes.bool,
      name: PropTypes.string,
      remediationType: PropTypes.string,
      status: PropTypes.string,
    })
  ),
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

ActionPaths.defaultProps = {
  error: {},
  headerLeft: null,
  isLoading: false,
  refetch: () => {},
  requestArgs: {},
  requestFunction: () => {},
  requestKey: '',
  rows: [],
  totalPages: 1,
  totalRecords: 0,
};
