import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  fieldset: {
    '.abyss-flex-root': {
      '.item': {
        minWidth: '200px',
        padding: '0px var(--abyss-space-lg)',
        textAlign: 'center',
      },
      h4: {
        color: 'var(--abyss-colors-black) !important',
        marginTop: 'var(--abyss-space-sm)',
      },
    },
    background: 'var(--abyss-colors-error2)',
    border: '1px solid var(--abyss-colors-error1)',
    borderRadius: '4px',
    legend: {
      background: 'var(--abyss-colors-error2)',
      border: '1px solid var(--abyss-colors-error1)',
      borderRadius: '4px',
      color: 'var(--abyss-colors-error1)',
      fontWeight: 'var(--abyss-fontWeights-bold)',
      h6: {
        color: 'var(--abyss-colors-error1) !important',
        fontSize: '0.875rem !important',
        whiteSpace: 'nowrap',
      },
      padding: '2px 8px',
    },
    padding: 'var(--abyss-space-lg) var(--abyss-space-md)',
  },
});
