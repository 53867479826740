import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { useApi } from '@src/context/Api';
import { User } from '@src/features/Users/components/User';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { AddModal } from './components/AddModal';
import { RemoveModal } from './components/RemoveModal';
import { Table } from './components/Table';

/**
 * Widget: AssociatedEids
 *
 * Allows adding/removing EID's to/from an active/manual action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function AssociatedEids(props) {
  const { actionPath } = props;

  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [eids, setEids] = useState([]);

  const { useApiQuery } = useApi();

  const [ListRiskRecords, { data: riskRecords, error, isFetching, isLoading, refetch }] =
    useApiQuery('ListRiskRecords');

  return (
    <ErrorHandler location="src/features/Risk/components/widgets/AssociatedEids/AssociatedEids.jsx">
      <LoadingOverlay
        ariaLoadingLabel="Your request is being processed."
        isLoading={isLoading || isFetching}
        loadingMessage="Your request is being processed."
        loadingTitle="Loading..."
      >
        <Widget
          button={
            <User.Capability attributes={['attach']} resource="ActionPaths">
              <Button
                before={<IconSymbol icon="add" />}
                css={{
                  marginLeft: themeConfiguration?.theme?.space?.md,
                }}
                onClick={() => {
                  return setShowAddModal(true);
                }}
                size="$sm"
                variant="outline"
              >
                Add EID
              </Button>
            </User.Capability>
          }
          description="Other Entities that this record is connected to"
          title="Assosciated EID's"
        >
          <Table
            error={error}
            isLoading={isLoading || isFetching}
            refetch={refetch}
            requestArgs={{
              criteria: {
                entrance: {
                  additional: [
                    {
                      column: 'ACTION_PATH_ID',
                      condition: 'EQ',
                      value: actionPath?.id,
                    },
                  ],
                },
              },
              page: 0,
              size: 5,
              sort: 'eid,asc',
            }}
            requestFunction={ListRiskRecords}
            requestKey="ListRiskRecords"
            rows={riskRecords?.content || []}
            setEids={setEids}
            setIsOpen={setShowRemoveModal}
            totalPages={riskRecords?.totalPages || 1}
            totalRecords={riskRecords?.totalElements || 0}
          />
        </Widget>
      </LoadingOverlay>
      <User.Capability attributes={['attach']} method="hide" resource="ActionPaths">
        <AddModal actionPath={actionPath} isOpen={showAddModal} refetch={refetch} setIsOpen={setShowAddModal} />
      </User.Capability>
      <User.Capability attributes={['detach']} method="hide" resource="ActionPaths">
        <RemoveModal
          actionPath={actionPath}
          eids={eids}
          isOpen={showRemoveModal}
          refetch={refetch}
          setEids={setEids}
          setIsOpen={setShowRemoveModal}
        />
      </User.Capability>
    </ErrorHandler>
  );
}

AssociatedEids.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
  }),
};

AssociatedEids.defaultProps = {
  actionPath: {},
};
