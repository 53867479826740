import { useToast } from '@abyss/web/hooks/useToast';
import { logger } from '@src/includes/logger';
import { requests } from '@src/requests';
import { useQuery } from '@tanstack/react-query';
import { isFunction, isUndefined, merge } from 'lodash';
import { useState } from 'react';

/**
 * Hook: useApiQuery
 *
 * Dynamic api request function based on query key to make on-demand api requests.
 *
 * @param queryKey
 * @param options
 * @returns {*[]}
 */
export function useApiQuery(queryKey = '', options = {}) {
  const [enabled, setEnabled] = useState(false);
  const [queryArgs, setQueryArgs] = useState({});
  const { toast } = useToast();

  let queryFunction;

  if (!isUndefined(requests?.queries?.[queryKey])) {
    queryFunction = requests?.queries?.[queryKey];
  }

  const theQuery = merge(
    {},
    {
      enabled,
      excludedHttpCodes: [],
      queryFn: async (args) => {
        try {
          let response;
          if (isUndefined(queryFunction)) {
            throw new Error(`Request not found for query key: ${queryKey}`);
          }

          if (isFunction(queryFunction)) {
            response = await queryFunction(args.queryKey);
          }

          setEnabled(false);
          return response;
        } catch (errorObject) {
          setEnabled(false);

          const { error } = errorObject;
          const excludedHttpCodes = options?.excludedHttpCodes || [];

          if (!excludedHttpCodes?.includes(error?.statusCode)) {
            toast.show({
              autoClose: false,
              id: `useApiQuery-${queryKey}`,
              message: error?.message,
              title: `API Error - ${queryKey}`,
              variant: 'error',
            });
          }

          logger.error('src/context/Api/Api.jsx -> useApiQuery', false, error);
          throw errorObject;
        }
      },
      queryKey: [queryKey, queryArgs],
    },
    options
  );

  const query = useQuery(theQuery);

  /**
   * requestFunction
   *
   * Sets a state value to define request args and enable the query to make a fetch request with those args.
   *
   * @param args
   * @returns {Promise<void>}
   */
  const requestFunction = (args) => {
    setQueryArgs(args);
    setEnabled(true);
  };

  return [requestFunction, query];
}
