import { config } from '@abyss/web/tools/config';
import { useMsal } from '@azure/msal-react';
import { useApi } from '@src/context/Api';
import { permissions } from '@src/features/Users/includes/permissions';
import { isArray, isUndefined, merge, uniq } from 'lodash';
import { concat } from 'lodash/array';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo } from 'react';

/**
 * defaultValues
 *
 * @type {{emailAddress: (string), displayName: (string), hasAccess: (boolean|null), hasPermission:
 *   defaultValues.hasPermission, roles: ((string|string)[]|*[]), hasRoles: (boolean|null), tenantId: string,
 *   currentRole: (string), msalAccount: {}, localAccountId: string, homeAccountId: string}}
 */
export const defaultValues = {
  currentRole: config('APP_ENV') === 'local' ? 'Admin' : '',
  displayName: config('APP_ENV') === 'local' ? 'Local User' : '',
  emailAddress: config('APP_ENV') === 'local' ? 'user@email.local' : '',
  hasAccess: config('APP_ENV') === 'local' ? true : null,
  hasPermission: () => {},
  hasRoles: config('APP_ENV') === 'local' ? true : null,
  homeAccountId: '',
  localAccountId: '',
  msalAccount: {},
  roles: config('APP_ENV') === 'local' ? [config('AUTHORIZATION_ROLE'), 'Role.Admin'] : [],
  tenantId: '',
};

/**
 * UserContext
 *
 * @type {React.Context<{emailAddress: string, displayName: string, hasAccess: (boolean|null), hasPermission:
 *   defaultValues.hasPermission, roles: (string[]|*[]), hasRoles: (boolean|null), tenantId: string, currentRole:
 *   string, msalAccount: {}, localAccountId: string, homeAccountId: string}>}
 */
const UserContext = createContext(defaultValues);

/**
 * useUserContext
 *
 * @returns {{emailAddress: string, displayName: string, hasAccess: (boolean|null), hasPermission:
 *   defaultValues.hasPermission, roles: (string[]|*[]), hasRoles: (boolean|null), tenantId: string, currentRole:
 *   string, msalAccount: {}, localAccountId: string, homeAccountId: string}}
 */
export const useUserContext = () => {
  return useContext(UserContext);
};

/**
 * UserProvider
 *
 * Provides the user context to the application.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function UserProvider(props) {
  const { children } = props;

  const { useApiQuery } = useApi();
  const [GetUserRoles, { data: delegatedRoles }] = useApiQuery('GetUserRoles');

  const { instance } = useMsal();

  /**
   * hasAccess
   *
   * Determine if the user has access to the application.
   *
   * @type {boolean}
   */
  const hasAccess = useMemo(() => {
    let theAccess = defaultValues.hasAccess;

    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();

      if (activeAccount && activeAccount.idTokenClaims && activeAccount.idTokenClaims.roles) {
        theAccess = activeAccount.idTokenClaims.roles.includes(config('AUTHORIZATION_ROLE'));
      } else {
        theAccess = false;
      }
    }

    return theAccess;
  }, [instance]);

  const userRoles = useMemo(() => {
    let theRoles = defaultValues.roles;
    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();
      theRoles = activeAccount?.idTokenClaims?.roles;
    }
    return theRoles;
  }, [instance]);

  /**
   * roles
   *
   * Retrieve the delegated roles on behalf of the service user from the API and merge them with the user roles
   *
   * @type {[string,string,string,string,string]|[]}
   */
  const roles = useMemo(() => {
    let theRoles = concat([], defaultValues.roles, userRoles);

    if (config('APP_ENV') !== 'local') {
      (async () => {
        if (hasAccess === true && isUndefined(delegatedRoles)) {
          await GetUserRoles();
        } else if (isArray(delegatedRoles) && hasAccess === true) {
          theRoles = concat([], defaultValues.roles, userRoles, delegatedRoles);
        }
      })();
    }

    return uniq(theRoles);
  }, [hasAccess, delegatedRoles, userRoles]);

  const hasRoles = useMemo(() => {
    return roles.length > 1;
  }, [roles]);

  /**
   * account
   *
   * Synchronize the account from IDP with user context.
   *
   * @type {string}
   */
  const account = useMemo(() => {
    const theAccount = {
      displayName: defaultValues.displayName,
      emailAddress: defaultValues.emailAddress,
      homeAccountId: defaultValues.homeAccountId,
      localAccountId: defaultValues.localAccountId,
      msalAccount: defaultValues.msalAccount,
      tenantId: defaultValues.tenantId,
    };

    if (config('APP_ENV') !== 'local') {
      const activeAccount = instance.getActiveAccount();
      theAccount.displayName = activeAccount?.idTokenClaims?.name;
      theAccount.emailAddress = activeAccount?.idTokenClaims?.email;
      theAccount.homeAccountId = activeAccount?.homeAccountId;
      theAccount.localAccountId = activeAccount?.localAccountId;
      theAccount.msalAccount = activeAccount;
      theAccount.tenantId = activeAccount?.tenantId;
    }

    return theAccount;
  }, [instance]);

  /**
   * Determine the current role of the user.
   */
  const currentRole = useMemo(() => {
    const permissionRoles = Object.keys(permissions);

    const matchingRoles = permissionRoles.filter((permissionRole) => {
      return roles.includes(`Role.${permissionRole}`);
    });

    return matchingRoles?.[0];
  }, [roles, permissions]);

  /**
   * hasPermission
   *
   * Determine if the user has a specific permission.
   *
   * @type {function(null=, null=): *}
   */
  const hasPermission = useCallback(
    (resource = null, attribute = null) => {
      return (hasAccess && hasRoles && permissions?.[currentRole]?.[resource]?.[attribute]) || false;
    },
    [hasAccess, currentRole, permissions, hasRoles]
  );

  /**
   * User
   *
   * The current user data.
   */
  const user = useMemo(() => {
    return merge({}, defaultValues, { currentRole, hasAccess, hasRoles, roles, ...account, hasPermission });
  }, [account, roles, hasAccess, currentRole, hasRoles, hasPermission]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
