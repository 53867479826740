import { Badge } from '@abyss/web/ui/Badge';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { abbrNum } from '@src/includes/functions';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * TotalRecords
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function TotalRecords(props) {
  const { riskRecordCount } = props;

  let count = isNull(riskRecordCount) ? 0 : riskRecordCount;

  if (riskRecordCount > 0) {
    count = abbrNum(riskRecordCount);
  }

  return (
    <ErrorHandler location="src/features/Risk/components/tables/RiskRecords/components/Header/components/TotalRecords/TotalRecords.jsx">
      <Layout.Group>
        <div>
          <Text fontWeight="bold">Total Records:</Text>
        </div>
        <Tooltip content={`Total: ${Number(riskRecordCount).toLocaleString('en-US')}`}>
          <div>
            <Badge css={{ fontWeight: 'bold' }} outline variant="info">
              {count}
            </Badge>
          </div>
        </Tooltip>
      </Layout.Group>
    </ErrorHandler>
  );
}

TotalRecords.propTypes = {
  riskRecordCount: PropTypes.number,
};

TotalRecords.defaultProps = {
  riskRecordCount: 0,
};
