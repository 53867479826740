import { styled } from '@abyss/web/tools/styled';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * GlobalStyles
 *
 * @TODO - Needs description.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function GlobalStyles(props) {
  const { children } = props;

  const Styles = styled('div', {
    "*[class*='isDisabled-true']": {
      color: 'var(--abyss-colors-gray5) !important',
      cursor: 'not-allowed !important',
    },
    "*[class*='isDisabled-true'] .abyss-icon, *[class*='variant-isDisabled'] .abyss-icon": {
      color: 'var(--abyss-colors-gray5) !important',
      fill: 'var(--abyss-colors-gray5) !important',
    },
    'main#app-main': {
      minHeight: 'calc(100vh + 150px)',
    },
  });

  return (
    <ErrorHandler location="src/styles/GlobalStyles.jsx">
      <Styles>{children}</Styles>
    </ErrorHandler>
  );
}

GlobalStyles.propTypes = {
  children: PropTypes.node,
};

GlobalStyles.defaultProps = {
  children: null,
};
