import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import fieldConfiguration from '@src/features/Criteria/components/misc/Filters/components/Table/includes/fields.json';
import { isArray, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Displays a list of entrance criteria (filters) within a DataTable.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Table(props) {
  const { filterKey, rows } = props;

  const field = fieldConfiguration;

  /**
   * Make fields more "human-readable"
   */
  const theRows = useMemo(() => {
    const filters = [];

    rows.forEach((row) => {
      const theFilter = { ...row };

      const column = field?.column?.options?.find((item) => {
        return item?.value === theFilter?.column;
      });

      if (!isUndefined(column)) {
        theFilter.column = column.label;
      }

      const condition = field?.condition?.options?.find((item) => {
        return item?.value === theFilter?.condition;
      });

      if (!isUndefined(condition)) {
        theFilter.condition = condition.label;
      }

      if (isArray(theFilter?.value)) {
        theFilter.value = theFilter.value.join(', ');
      }

      filters.push(theFilter);
    });

    return filters;
  }, [rows]);

  const renderCellType = ({ value }) => {
    return <span className="type">{value}</span>;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns?.map((column) => {
      const theColumn = column;

      if (theColumn.accessor === 'type') {
        theColumn.Cell = renderCellType;
      }
      return theColumn;
    });
  }, []);

  return (
    <ErrorHandler location="src/features/Criteria/components/widgets/FilterCriteria/components/Table/Table.jsx">
      <TableComponent
        {...{
          columns,
          configuration,
          dataKey: `actionPaths-${filterKey}`,
          rows: theRows,
        }}
      />
    </ErrorHandler>
  );
}

Table.propTypes = {
  filterKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    })
  ),
};

Table.defaultProps = {
  filterKey: 'filters',
  rows: [],
};
