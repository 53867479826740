import { DateInput } from '@abyss/web/ui/DateInput';
import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import React from 'react';

import { FormSkeleton } from './components/Skeleton';
import fields from './includes/fields.json';

const { reportDate: fieldReportDate } = fields;

/**
 * Form
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Form(props) {
  const { isLoading, reportDate, setReportDate } = props;

  return (
    <ErrorHandler location="src/routes/private/Dashboards/Risk/components/Form/Form.jsx">
      <Grid css={{ margin: 0 }}>
        <Grid.Col
          css={{ paddingLeft: 0 }}
          span={{
            xs: '100%',
          }}
        >
          {isLoading ? (
            <FormSkeleton />
          ) : (
            <DateInput {...fieldReportDate} onChange={setReportDate} value={reportDate} />
          )}
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
}
