import { dayjs } from '@abyss/web/tools/dayjs';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { abbrNum } from '@src/includes/functions';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { isEmpty, isUndefined, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import stc from 'string-to-color';

const stringToColor = stc;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

/**
 * LineChart
 *
 * Displays the risk trend report data in a line chart.
 *
 * @TODO - Refactor this chart to be more dynamic and reusable. Currently specific for tag history dashboard.
 *
 * @param props
 * @returns {React.JSX.Element|*|string}
 * @constructor
 */
export function LineChart(props) {
  const { data, isLogarithmicScale } = props;

  const chartRef = useRef(null);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);

  /**
   * Define the labels for the chart. (x axis)
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const theLabels = map(data, 'date');

      if (theLabels !== labels) {
        setLabels(theLabels);
      }
    }
  }, [data]);

  /**
   * Define the datasets for the chart. (y axis)
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const theDatasets = [];

      const items = {};

      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (!key.includes('-count') && key !== 'date') {
            if (isUndefined(items[key])) {
              items[key] = [];
            }
            items[key].push(item[`${key}-count`]);
          }
        });
      });

      Object.keys(items).forEach((key) => {
        const color = stringToColor(key);
        const dataset = {
          backgroundColor: color,
          borderColor: color,
          borderJoinStyle: 'bevel',
          borderWidth: 2,
          data: items[key],
          label: key,
          pointRadius: 3,
        };

        theDatasets.push(dataset);
      });

      if (theDatasets !== datasets) {
        setDatasets(theDatasets);
      }
    }
  }, [data]);

  return (
    <ErrorHandler location="src/components/Chart/components/LineChart/LineChart.jsx">
      <Line
        data={{
          datasets,
          labels,
        }}
        options={{
          animation: false,
          layout: {
            padding: {
              top: 36,
            },
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              align: 'middle',
              position: 'bottom',
              title: {
                display: true,
                text: 'Tags',
              },
            },
            tooltip: {
              callbacks: {
                footer(context) {
                  const tag = data?.[context?.[0]?.dataIndex];

                  if (isUndefined(tag)) {
                    return '';
                  }

                  const count = data?.[context?.[0]?.dataIndex]?.[`${context?.[0]?.dataset?.label}-count`];
                  const increase = tag?.[context?.[0]?.dataset?.label]?.increase;
                  const decrease = tag?.[context?.[0]?.dataset?.label]?.decrease;
                  const date = tag?.date;

                  return `Tag: : ${String(context?.[0]?.dataset?.label)}\nDate: ${dayjs(date).format(
                    'MM/DD/YYYY'
                  )}\nCount: ${Number(count).toLocaleString('en-US')}\nIncrease: ${Number(increase).toLocaleString(
                    'en-US'
                  )}\nDecrease: ${Number(decrease).toLocaleString('en-US')}`;
                },
                label() {
                  return '';
                },
                title() {
                  return '';
                },
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              border: { color: themeConfiguration?.theme?.colors?.gray6, dash: [5, 5] },
              grid: {
                color: themeConfiguration?.theme?.colors?.gray3,
              },
              title: {
                display: true,
                text: 'Date Range',
              },
            },
            y: {
              border: { color: themeConfiguration?.theme?.colors?.gray6, dash: [5, 5] },
              grid: {
                color: themeConfiguration?.theme?.colors?.gray3,
              },
              stacked: false,
              ticks: {
                // Include a dollar sign in the ticks
                callback(value) {
                  return abbrNum(value);
                },
              },
              title: {
                display: true,
                text: 'Count',
              },
              type: isLogarithmicScale ? 'logarithmic' : 'linear',
            },
          },
        }}
        ref={chartRef}
      />
    </ErrorHandler>
  );
}

LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLogarithmicScale: PropTypes.bool,
};

LineChart.defaultProps = {
  isLogarithmicScale: false,
};
