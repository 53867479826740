import { AbyssTheme as themeConfiguration } from '@src/client';
import { chartColors } from '@src/components/Chart/includes/chartColors';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { isEmpty, isUndefined, shuffle } from 'lodash';
import pattern from 'patternomaly';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Pie } from 'react-chartjs-2';

import { getTooltipFooter, getTooltipLabel, getTooltipTitle } from '../../includes/functions';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

/**
 * PieChart
 *
 * A pie chart is a circular statistical graphic divided into sections, each representing a proportionate part of a
 * whole, often used to illustrates percentages or distributions within a dataset.
 *
 * @param props
 * @returns {React.JSX.Element|`\nValue: ${string}\nPercentage: ${*}%\n`|*}
 * @constructor
 */
export function PieChart(props) {
  const { data, legendLimit, showLegend } = props;

  const [labels, setLabels] = useState([]);
  const [dataset, setDataset] = useState([]);

  /**
   * Defines an array of colors and patterns to use for rendering the datasets.
   *
   * @type {Array<CanvasPattern>}
   */
  const patterns = useMemo(() => {
    return pattern.generate(
      shuffle(chartColors).map((colorName) => {
        return themeConfiguration?.theme?.colors?.[`${colorName}`];
      })
    );
  }, [themeConfiguration]);

  /**
   * Configure the labels and datasets to be used by the chart.
   */
  useEffect(() => {
    if (!isEmpty(data)) {
      const theLabels = [];
      const theDataset = [];

      data?.forEach((item) => {
        const name = String(item?.name);
        theLabels.push(name);
        theDataset.push(item?.value);
      });

      if (theLabels !== labels) {
        setLabels(theLabels);
      }

      if (theDataset !== dataset) {
        setDataset(theDataset);
      }
    }
  }, [data]);

  return (
    <ErrorHandler location="src/components/Chart/components/PieChart/PieChart.jsx">
      <Pie
        data={{
          datasets: [
            {
              backgroundColor: patterns,
              borderWidth: 1,
              data: dataset,
            },
          ],
          labels,
        }}
        options={{
          animation: {
            duration: 0, // general animation time
          },
          hover: {
            animationDuration: 0, // duration of animations when hovering an item
          },
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: isUndefined(showLegend) ? !isUndefined(legendLimit) && labels.length <= legendLimit : true,
            },
            tooltip: {
              callbacks: {
                footer(args) {
                  return getTooltipFooter(args, data);
                },
                label(args) {
                  return getTooltipLabel(args, data);
                },
                title(args) {
                  return getTooltipTitle(args, data);
                },
              },
            },
          },
          responsive: true,
          responsiveAnimationDuration: 0, // animation duration after a resize
        }}
      />
    </ErrorHandler>
  );
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legendLimit: PropTypes.number,
  showLegend: PropTypes.bool,
};

PieChart.defaultProps = {
  legendLimit: 10,
  showLegend: true,
};
