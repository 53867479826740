import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-data-table-root': {
    '.abyss-table-body': {
      '.abyss-table-row': {
        td: {
          '> div': {
            '> .abyss-tooltip-trigger': {
              '.abyss-number-input-root, .abyss-select-input-root': {
                '.abyss-layout-group': {
                  display: 'none',
                },
                display: 'block',
              },
              height: '100%',
              width: '100%',
            },
            height: '100%',
            width: '100%',
          },
          flex: 'auto !important',
          minWidth: '25% !important',
          padding:
            'calc(var(--abyss-space-lg) - 5px) var(--abyss-space-md) calc(var(--abyss-space-md) + 5px) var(--abyss-space-md)',
          width: '25% !important',
        },
        'td:last-child': {
          flex: 'none !important',
          minWidth: '128px !important',
          width: '128px !important',
        },
      },
    },
    '.abyss-table-head': {
      '.abyss-table-header': {
        th: {
          flex: 'auto !important',
          minWidth: '256px !important',
          width: '256px !important',
        },
        'th:last-child': {
          flex: 'none !important',
          minWidth: '128px !important',
          width: '128px !important',
        },
      },
    },
    marginTop: 'var(--abyss-space-sm)',
  },
  '.removeField': {
    '.abyss-icon': {
      color: 'var(--abyss-colors-error1)',
      marginRight: 'var(--abyss-space-xs)',
      marginTop: 'var(--abyss-space-xs)',
    },
    '&:hover:not([class*="isDisabled-true"])': {
      'span.abyss-icon-symbol:first-of-type': {
        color: 'var(--abyss-colors-error1)',
        display: 'inline-block',
        visibility: 'visible',
      },
      'span.abyss-icon-symbol:last-of-type': {
        display: 'none',
        visibility: 'hidden',
      },
    },
    '&:not([class*="isDisabled-true"])': {
      color: 'var(--abyss-colors-error1)',
    },
    fontWeight: 'bold',
    'span.abyss-icon-symbol:first-of-type': {
      display: 'none',
      visibility: 'hidden',
    },
    'span.abyss-icon-symbol:last-of-type': {
      display: 'inline-block',
      visibility: 'visible',
    },
  },
});
