import { Alert } from '@abyss/web/ui/Alert';
import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { Widget } from '@src/components/Widget';
import { useApi } from '@src/context/Api';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Table } from './components/Table';

/**
 * Widget: CommonCriteria
 *
 * Displays a list of common criteria filters to apply to an api request.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function CommonCriteria(props) {
  const {
    context,
    fetchCriteria,
    fetchCriteriaVersions,
    filterKey,
    form,
    isCollapsible,
    needsRemoval,
    needsReview,
    needsUpdate,
    setSelected,
    showActions,
  } = props;

  const { useApiQuery } = useApi();

  const [ListCommonCriteria, { data, isFetching, isLoading }] = useApiQuery('ListCommonCriteria');

  /**
   * Fetch common criteria from the API.
   */
  useEffect(() => {
    if (isUndefined(data)) {
      ListCommonCriteria({
        isActive: true,
        page: 0,
        size: 9999,
        sort: 'name,asc',
      });
    }
  }, [data]);

  return (
    <ErrorHandler location="src/features/Criteria/components/widgets/CommonCriteria/CommonCriteria.jsx">
      <Widget
        backgroundColor="var(--abyss-colors-gray1)"
        collapsible={isCollapsible}
        icon="settings"
        title="Common Criteria"
      >
        <Grid>
          {isUndefined(data) || isLoading || isFetching ? (
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Loader backgroundColor="transparent" height="100%" width="100%" />
            </Grid.Col>
          ) : (
            [
              <React.Fragment key="needsReview">
                {(!isEmpty(needsReview?.update) || !isEmpty(needsReview?.remove)) && (
                  <React.Fragment>
                    {!isEmpty(needsReview?.remove) && (
                      <Grid.Col key="removeAlert" span={{ xs: '100%' }}>
                        <Alert title="Deprecated Criteria" variant="warning">
                          {needsReview?.remove.join(', ')} {needsReview?.remove.length > 1 ? 'are' : 'is'} no longer
                          supported. Please click the remove button to advance the{' '}
                          {needsReview?.remove.length > 1 ? 'action paths' : 'action path'}.
                        </Alert>
                      </Grid.Col>
                    )}
                    {!isEmpty(needsReview?.update) && (
                      <Grid.Col key="updateAlert" span={{ xs: '100%' }}>
                        <Alert title="Updated Criteria" variant="warning">
                          {needsReview?.update.join(', ')} {needsReview?.update.length > 1 ? 'have' : 'has'} been
                          updated. Please click the update button to advance the{' '}
                          {needsReview?.update.length > 1 ? 'action paths' : 'action path'}.
                        </Alert>
                      </Grid.Col>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>,

              <Grid.Col
                key="table"
                span={{
                  xs: '100%',
                }}
              >
                <Table
                  context={context}
                  fetchCriteria={fetchCriteria}
                  fetchCriteriaVersions={fetchCriteriaVersions}
                  filterKey={filterKey}
                  form={form}
                  needsRemoval={needsRemoval}
                  needsUpdate={needsUpdate}
                  rows={data?.content || []}
                  setSelected={setSelected}
                  showActions={showActions}
                />
              </Grid.Col>,
            ]
          )}
        </Grid>
      </Widget>
    </ErrorHandler>
  );
}

CommonCriteria.propTypes = {
  context: PropTypes.string,
  fetchCriteria: PropTypes.func,
  fetchCriteriaVersions: PropTypes.func,
  filterKey: PropTypes.string,
  form: PropTypes.shape({}),
  isCollapsible: PropTypes.bool,
  needsRemoval: PropTypes.shape({
    criteria: PropTypes.shape({
      activeCommonCriteriaVersion: PropTypes.string,
      activeVersionNbr: PropTypes.number,
      context: PropTypes.string,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      id: PropTypes.string,
      isActive: PropTypes.bool,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      name: PropTypes.string,
      note: PropTypes.string,
    }),
    version: PropTypes.shape({
      commonCriteriaId: PropTypes.string,
      context: PropTypes.string,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      id: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      version: PropTypes.number,
    }),
  }),
  needsReview: PropTypes.shape({
    remove: PropTypes.arrayOf(PropTypes.string),
    update: PropTypes.arrayOf(PropTypes.string),
  }),
  needsUpdate: PropTypes.shape({
    from: PropTypes.shape({
      commonCriteriaId: PropTypes.string,
      context: PropTypes.string,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      id: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      version: PropTypes.number,
    }),
    to: PropTypes.shape({
      commonCriteriaId: PropTypes.string,
      createdBy: PropTypes.string,
      createdDate: PropTypes.string,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      id: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      version: PropTypes.number,
    }),
  }),
  setSelected: PropTypes.func,
  showActions: PropTypes.bool,
};

CommonCriteria.defaultProps = {
  context: 'entrance',
  fetchCriteria: () => {},
  fetchCriteriaVersions: () => {},
  filterKey: 'criteria.entrance',
  form: {},
  isCollapsible: false,
  needsRemoval: {},
  needsReview: {},
  needsUpdate: {},
  setSelected: () => {},
  showActions: false,
};
