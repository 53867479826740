import { Pagination } from '@abyss/web/ui/Pagination';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './includes/styles';

/**
 * NumberedPagination
 *
 * Displays a pagination control for next/previous pages along with controls to navigate to a specific page number.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function NumberedPagination(props) {
  const { pagination } = props;

  return (
    <ErrorHandler location="src/components/Table-query/components/Footer/components/NumberedPagination/NumberedPagination.jsx">
      <Styles>
        <Pagination {...pagination} />
      </Styles>
    </ErrorHandler>
  );
}

NumberedPagination.propTypes = {
  pagination: PropTypes.shape({}),
};

NumberedPagination.defaultProps = {
  pagination: {},
};
