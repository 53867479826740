import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Table } from './components/Table';

/**
 * RecommendationResponses
 *
 * Displays recommendations from Common Intake Platform (CIP)
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function RecommendationResponses(props) {
  const { actionPath } = props;

  const [recommendationResponses, setRecommendationResponses] = useState([]);

  /**
   * Map api response to table data
   */
  useEffect(() => {
    const theRecommendations = [];

    if (!isEmpty(actionPath?.recordActions)) {
      actionPath?.recordActions.forEach((recordAction) => {
        if (!isEmpty(recordAction?.recordReferences)) {
          recordAction?.recordReferences.forEach((recordReference) => {
            theRecommendations.push({
              assignedCode: recordReference?.actionTaken,
              assignedDescription: recordReference?.actionTakenCodeDesc,
              statusCode: recordReference?.status,
              statusDate: recordReference?.lastModifiedDate,
            });
          });
        }
      });
    }

    if (!isEmpty(theRecommendations) && theRecommendations !== recommendationResponses) {
      setRecommendationResponses(theRecommendations);
    }
  }, [actionPath]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/EidSearch/components/widgets/ActionPaths/components/RecommendationResponses/RecommendationResponses.jsx">
      <Grid>
        <Grid.Col span={{ xs: '100%' }}>
          <Table recommendationResponses={recommendationResponses || []} />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
}

RecommendationResponses.propTypes = {
  actionPath: PropTypes.shape({
    recordActions: PropTypes.arrayOf(
      PropTypes.shape({
        recordReferences: PropTypes.arrayOf(
          PropTypes.shape({
            actionTaken: PropTypes.string,
            actionTakenCodeDesc: PropTypes.string,
            lastModifiedDate: PropTypes.string,
            status: PropTypes.string,
          })
        ),
      })
    ),
  }),
};

RecommendationResponses.defaultProps = {
  actionPath: {
    recordActions: [],
  },
};
