import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { User } from '@src/features/Users/components/User';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { CreationModal } from '../../modals/Creation';

/**
 * Button: Create
 *
 * This button is used to create a new entity.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Create(props) {
  const { children, refetch, setFocusedEntity } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <ErrorHandler location="src/routes/private/Admin/CommonCriteria/components/buttons/Create/Create.jsx">
      <User.Capability attributes={['create']} resource="Admin:CommonCriteria">
        <Button
          before={<IconSymbol icon="add" />}
          css={{
            marginLeft: 'var(--abyss-space-md)',
          }}
          onClick={() => {
            setIsOpen(true);
          }}
          size="$sm"
          variant="outline"
        >
          {children}
        </Button>
      </User.Capability>
      {isOpen && (
        <CreationModal isOpen={isOpen} refetch={refetch} setFocusedEntity={setFocusedEntity} setIsOpen={setIsOpen} />
      )}
    </ErrorHandler>
  );
}

Create.propTypes = {
  children: PropTypes.node,
  refetch: PropTypes.func,
  setFocusedEntity: PropTypes.func,
};

Create.defaultProps = {
  children: null,
  refetch: () => {},
  setFocusedEntity: () => {},
};
