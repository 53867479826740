/* eslint-disable sonarjs/function-return-type */
import { dayjs } from '@abyss/web/tools/dayjs';
import { DateInput } from '@abyss/web/ui/DateInput';
import { Flex } from '@abyss/web/ui/Flex';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Link } from '@abyss/web/ui/Link';
import { NumberInput } from '@abyss/web/ui/NumberInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { TextInput } from '@abyss/web/ui/TextInput';
import { TagSelectionField } from '@src/components/common/fields/TagSelection';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { User } from '@src/features/Users/components/User';
import { fieldValidator } from '@src/includes/validation';
import { isEmpty, isNil, isUndefined, merge, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';

import { NotFound } from '../NotFound';
import configuration from './includes/configuration.json';
import fieldConfiguration from './includes/fields.json';
import { Styles } from './includes/styles';

/**
 * Table
 *
 * Provides the user with a table to specify filters to search risk records by.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Table(props) {
  const { append, assets, dataKey, fields, filterKey, form, handleReset, isDisabled, isLoading, permissions, remove } =
    props;

  const actionPaths = assets?.ListActionPaths?.data || [];
  const actionStatuses = assets?.ListActionStatuses?.data || [];
  const tags = assets?.ListTags?.data || [];

  const filters = form.getValues(filterKey);

  const { column: fieldColumn, condition: fieldCondition, value: fieldValue } = fieldConfiguration;

  /**
   * fieldValue.status.options
   *
   * This is a memoized value that is used to populate the status options for the select input.
   */
  fieldValue.status.options = useMemo(() => {
    return actionStatuses?.map((actionStatus) => {
      return {
        label: actionStatus?.codeDesc,
        value: actionStatus?.codeId,
      };
    });
  }, [actionStatuses]);

  /**
   * fieldValue.tags.options
   *
   * This is a memoized value that is used to populate the tags options for the select input.
   *
   * @type {*[]}
   */
  fieldValue.tags.options = useMemo(() => {
    const tagOptions = [];

    if (!isEmpty(tags?.tagsList)) {
      Object.keys(tags?.tagsList).forEach((categoryCode) => {
        const tagOption = {
          items: [],
          section: '',
        };
        tags?.tagsList[categoryCode].forEach((tag) => {
          if (isEmpty(tagOption.section)) {
            tagOption.section = tag?.categoryDesc;
          }
          const tagItem = {
            label: `${tag?.code} (${tag?.categoryCode})`,
            tags: [tag.categoryDesc, tag.description],
            value: tag?.tag,
          };
          if (!tagOption.items.includes(tagItem)) {
            tagOption.items.push(tagItem);
          }
        });

        if (!tagOptions.includes(tagOption)) {
          tagOptions.push(tagOption);
        }
      });
    }

    return tagOptions;
  }, [tags]);

  /**
   * fieldValue.actionPath.options
   *
   * This is a memoized value that is used to populate the tags options for the select input.
   *
   * @type {*[]}
   */
  fieldValue.actionPath.options = useMemo(() => {
    let actionPathOptions = [];

    if (!isEmpty(actionPaths?.content)) {
      actionPathOptions = [
        {
          items: actionPaths?.content
            ?.filter((actionPath) => {
              return actionPath?.manualAssociation === false;
            })
            .map((actionPath) => {
              return {
                label: `${actionPath?.name} (automatic)`,
                value: actionPath?.id,
              };
            }),
          section: 'Automatic',
        },
        {
          items: actionPaths?.content
            ?.filter((actionPath) => {
              return actionPath?.manualAssociation === true;
            })
            .map((actionPath) => {
              return {
                label: `${actionPath?.name} (manual)`,
                value: actionPath?.id,
              };
            }),
          section: 'Manual',
        },
      ];
    }

    return actionPathOptions;
  }, [actionPaths]);

  /**
   * fieldColumn.options
   *
   * Sort the field options by label.
   *
   * @type {Array}
   */
  fieldColumn.options = useMemo(() => {
    return orderBy(fieldColumn?.options, ['label'], ['asc']);
  }, [fieldColumn?.options]);

  /**
   * fieldCondition.options
   *
   * Sort the condition options by label.
   *
   * @type {Array}
   */
  fieldCondition.options = useMemo(() => {
    return orderBy(fieldCondition?.options, ['label'], ['asc']);
  }, [fieldCondition?.options]);

  /**
   * validate after setting initial row.
   */
  useEffect(() => {
    if ([filterKey]?.length === 1) {
      form.validate(
        `${filterKey}.0.column`,
        () => {},
        () => {}
      );
    }
  }, [filters]);

  /**
   * validate when fields changes
   */
  useEffect(() => {
    form.validate(
      `${filterKey}.0.column`,
      () => {},
      () => {}
    );
  }, [fields]);

  /**
   * validateField
   *
   * Validate specified form field(s).
   *
   * @param index
   * @param field
   */
  const validateField = (index, field) => {
    form.validate(
      `${filterKey}[${index}][${field}]`,
      () => {},
      () => {}
    );
  };

  /**
   * Validate all repeatable fields within a row when a value changes.
   */
  useEffect(() => {
    if (!isNil(filters) && !isEmpty(filters)) {
      filters.forEach((filter, index) => {
        validateField(index, 'column');
        if (!isEmpty(filter?.column) && isUndefined(form?.formState?.errors?.[filterKey]?.[index]?.column)) {
          validateField(index, 'condition');
          if (!isEmpty(filter?.condition) && isUndefined(form?.formState?.errors?.[filterKey]?.[index]?.condition)) {
            if (['CREATED_DATE', 'LAST_MODIFIED_DATE'].includes(filter?.column)) {
              form?.setValue(`${filterKey}[${index}][value]`, dayjs(filter?.value).format('MM/DD/YYYY'), {
                shouldDirty: true,
              });
            }

            if (filter?.column?.toLowerCase().includes('count')) {
              form?.setValue(`${filterKey}[${index}][value]`, String(filter?.value));
            }

            validateField(index, 'value');
          }
        }
      });
    }
  }, [filters, form?.formState?.errors]);

  /**
   * renderCellField
   *
   * Displays the field select input.
   *
   * @param args
   * @returns {JSX.Element}
   */
  const renderCellField = (args) => {
    const { row } = args;
    const { index } = row;

    return (
      <User.Capability
        alias="edit"
        attributes={permissions?.edit?.attributes}
        isAllowed={permissions?.edit?.isAllowed}
        resource={permissions?.edit?.resource}
      >
        <SelectInput
          {...fieldColumn}
          isDisabled={isDisabled}
          model={`${filterKey}[${index}][column]`}
          onChange={(columnValue) => {
            form?.setValue(`${filterKey}[${index}][condition]`, '');

            if (['ACTION_PATH_ID', 'TAGS'].includes(columnValue)) {
              form?.setValue(`${filterKey}[${index}][value]`, []);
            } else {
              form?.setValue(`${filterKey}[${index}][value]`, '');
            }

            validateField(index, 'column');
            validateField(index, 'condition');
          }}
          validators={{
            ...fieldColumn?.validators,
            ...{
              validate: {
                customValidator: (value) => {
                  return fieldValidator(fieldColumn, value);
                },
              },
            },
          }}
        />
      </User.Capability>
    );
  };

  /**
   * renderCellCondition
   *
   * Displays the condition select input.
   *
   * @param args
   * @returns {JSX.Element}
   */
  const renderCellCondition = (args) => {
    const { row } = args;
    const { index } = row;

    const isFieldDisabled =
      !isUndefined(form?.formState?.errors?.[filterKey]?.[index]?.column) || isEmpty(row?.original?.column);

    return (
      <User.Capability
        alias="edit"
        attributes={permissions?.edit?.attributes}
        isAllowed={permissions?.edit?.isAllowed}
        resource={permissions?.edit?.resource}
      >
        <SelectInput
          {...fieldCondition}
          isDisabled={isDisabled || isFieldDisabled}
          model={`${filterKey}[${index}][condition]`}
          onChange={() => {
            validateField(index, 'condition');
            validateField(index, 'column');
            validateField(index, 'value');
          }}
          options={fieldCondition?.options.filter((option) => {
            if (row?.original?.column === 'ACTION_PATH_ID') {
              return ['ALL', 'ANY', 'NONE', 'NOTALL', 'ONLY'].includes(option?.value);
            }

            if (row?.original?.column === 'ACTION_STATUS') {
              return ['EQ', 'NE'].includes(option?.value);
            }

            if (row?.original?.column === 'TAGS') {
              return ['ALL', 'ANY', 'NONE', 'NOTALL', 'ONLY'].includes(option?.value);
            }

            if (row?.original?.column.toLowerCase().includes('count')) {
              return ['EQ', 'GT', 'GTE', 'LT', 'LTE', 'NE'].includes(option?.value);
            }

            if (row?.original?.column.toLowerCase().includes('date')) {
              return ['EQ', 'GT', 'GTE', 'LT', 'LTE', 'NE'].includes(option?.value);
            }

            return option;
          })}
          validators={{
            ...fieldCondition?.validators,
            ...{
              validate: {
                customValidator: (value) => {
                  return fieldValidator(fieldCondition, value);
                },
              },
            },
          }}
        />
      </User.Capability>
    );
  };

  /**
   * renderCellValue
   *
   * Dynamically displays value input fields based on the field and condition selected.
   *
   * @param args
   * @returns {JSX.Element}
   */
  const renderCellValue = (args) => {
    const { row } = args;
    const { index } = row;

    const isFieldDisabled =
      !isUndefined(form?.formState?.errors?.[filterKey]?.[index]?.column) ||
      isEmpty(row?.original?.column) ||
      !isUndefined(form?.formState?.errors?.[filterKey]?.[index]?.condition) ||
      isEmpty(row?.original?.condition);

    return (
      <User.Capability
        alias="edit"
        attributes={permissions?.edit?.attributes}
        isAllowed={permissions?.edit?.isAllowed}
        resource={permissions?.edit?.resource}
      >
        {row?.original?.column === 'ACTION_PATH_ID' && (
          <SelectInputMulti
            {...fieldValue?.actionPath}
            isDisabled={isDisabled || isFieldDisabled}
            model={`${filterKey}[${index}][value]`}
            onChange={() => {
              validateField(index, 'condition');
              validateField(index, 'column');
              validateField(index, 'value');
            }}
            validators={{
              ...fieldValue?.actionPath?.validators,
              ...{
                validate: {
                  customValidator: (value) => {
                    return fieldValidator(fieldValue?.actionPath, value);
                  },
                },
              },
            }}
          />
        )}

        {row?.original?.column === 'ACTION_STATUS' && (
          <SelectInput
            {...fieldValue?.status}
            isDisabled={isDisabled || isFieldDisabled}
            model={`${filterKey}[${index}][value]`}
            onChange={() => {
              validateField(index, 'condition');
              validateField(index, 'column');
              validateField(index, 'value');
            }}
            validators={{
              ...fieldValue?.status?.validators,
              ...{
                validate: {
                  customValidator: (value) => {
                    return fieldValidator(fieldValue?.status, value);
                  },
                },
              },
            }}
          />
        )}

        {row?.original?.column === 'TAGS' && (
          <TagSelectionField
            form={form}
            handleChange={() => {
              validateField(index, 'condition');
              validateField(index, 'column');
              validateField(index, 'value');
            }}
            isDisabled={isDisabled || isFieldDisabled}
            model={`${filterKey}[${index}][value]`}
            showLabel={false}
            showSelectAll={false}
            validators={{
              ...fieldValue?.tags?.validators,
              ...{
                validate: {
                  customValidator: (value) => {
                    return fieldValidator(fieldValue?.tags, value);
                  },
                },
              },
            }}
          />
        )}

        {row?.original?.column.toLowerCase().includes('count') && (
          <NumberInput
            {...fieldValue?.count}
            isDisabled={isDisabled || isFieldDisabled}
            model={`${filterKey}[${index}][value]`}
            onChange={() => {
              validateField(index, 'condition');
              validateField(index, 'column');
              validateField(index, 'value');
            }}
            validators={{
              ...fieldValue?.count?.validators,
              ...{
                validate: {
                  customValidator: (value) => {
                    return fieldValidator(fieldValue?.count, value);
                  },
                },
              },
            }}
          />
        )}

        {row?.original?.column.toLowerCase().includes('date') && (
          <DateInput
            {...fieldValue?.date}
            isDisabled={isDisabled || isFieldDisabled}
            model={`${filterKey}[${index}][value]`}
            onChange={(dateValue) => {
              form?.setValue(`${filterKey}[${index}][value]`, dayjs(dateValue?.value).format('MM/DD/YYYY'), {
                shouldDirty: true,
              });
              validateField(index, 'condition');
              validateField(index, 'column');
              validateField(index, 'value');
            }}
            validators={{
              ...fieldValue?.date?.validators,
              ...{
                validate: {
                  customValidator: (value) => {
                    return fieldValidator(fieldValue?.date, value);
                  },
                },
              },
            }}
          />
        )}

        {!['ACTION_PATH_ID', 'ACTION_STATUS', 'TAGS'].includes(row?.original?.column) &&
          !row?.original?.column.toLowerCase().includes('date') &&
          !row?.original?.column.toLowerCase().includes('count') && (
            <TextInput
              {...fieldValue?.default}
              isDisabled={isDisabled || isFieldDisabled}
              model={`${filterKey}[${index}][value]`}
              onChange={() => {
                validateField(index, 'condition');
                validateField(index, 'column');
                validateField(index, 'value');
              }}
              validators={{
                ...fieldValue?.default?.validators,
                ...{
                  validate: {
                    customValidator: (value) => {
                      return fieldValidator(fieldValue?.default, value);
                    },
                  },
                },
              }}
            />
          )}
      </User.Capability>
    );
  };

  /**
   * renderCellActions
   *
   * Displays a button and icon to remove a field/row.
   *
   * @param args
   * @returns {JSX.Element}
   */
  const renderCellActions = (args) => {
    const { row, rows } = args;
    const { index } = row;

    return (
      <User.Capability
        alias="remove"
        attributes={permissions?.remove?.attributes}
        isAllowed={permissions?.remove?.isAllowed}
        resource={permissions?.remove?.resource}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className={isDisabled ? 'removeField isDisabled-true' : 'removeField'}
          isDisabled={isDisabled}
          onClick={() => {
            if (rows.length === 1) {
              handleReset();
            } else {
              remove(index);
            }
          }}
          variant="custom"
        >
          <Flex alignItems="center">
            <div>
              <IconSymbol icon="cancel" variant="filled" />
              <IconSymbol icon="cancel" variant="outlined" />
            </div>
            <div>Remove</div>
          </Flex>
        </Link>
      </User.Capability>
    );
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'Field') {
        column.Cell = renderCellField;
      }

      if (column.Header === 'Condition') {
        column.Cell = renderCellCondition;
      }

      if (column.Header === 'Value') {
        column.Cell = renderCellValue;
        column.Filters = filters;
      }

      if (column.accessor === 'actions') {
        column.Cell = renderCellActions;
      }

      return column;
    });
  }, []);

  /**
   * reset
   *
   * This function is intended to be passed to the common table component. It will reset the form state fields
   *
   * @param values
   */
  const reset = useCallback(
    (values) => {
      let theValues = values;

      if (isEmpty(values)) {
        theValues = filters;
      }

      form?.resetField(filterKey, { defaultValue: theValues });
      theValues.forEach((formFields, index) => {
        Object.keys(formFields).forEach((column) => {
          form.setValue(`${filterKey}[${index}][${column}]`, formFields[column]);
        });
      });
    },
    [filters]
  );

  return (
    <ErrorHandler location="src/features/Criteria/components/misc/Filters/components/Table/Table.jsx">
      <Styles>
        <TableComponent
          {...{
            columns,
            configuration: merge({}, configuration, {
              data: filters,
              enableGroupBy: false,
              isLoading,
              reorderRows: false,
            }),
            dataKey,
            noDataMessage: (
              <NotFound
                button={
                  /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                  <Link
                    className={isDisabled ? 'isDisabled-true' : ''}
                    css={{ height: '100%' }}
                    id="addField"
                    isDisabled={isDisabled}
                    onClick={() => {
                      append({ column: '', condition: '', value: '' });
                    }}
                    variant="custom"
                  >
                    <Flex alignItems="center" css={{ height: '100%' }}>
                      <div>
                        <IconSymbol icon="add_circle" variant="filled" />
                        <IconSymbol icon="add_circle" variant="outlined" />
                      </div>
                      <div>Add Filter</div>
                    </Flex>
                  </Link>
                }
                icon="filter_list_off"
                message="Get started by adding a new criteria filter."
                title="No Filters Added"
              />
            ),
            reset,
            rows: filters,
          }}
        />
      </Styles>
    </ErrorHandler>
  );
}

Table.propTypes = {
  append: PropTypes.func,
  assets: PropTypes.shape({
    ListActionPaths: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            manualAssociation: PropTypes.bool,
            name: PropTypes.string,
          })
        ),
      }),
    }),
    ListActionStatuses: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          codeDesc: PropTypes.string,
          codeId: PropTypes.string,
        })
      ),
    }),
    ListTags: PropTypes.shape({
      data: PropTypes.shape({
        tagsList: PropTypes.shape({
          [PropTypes.string]: PropTypes.arrayOf(
            PropTypes.shape({
              categoryCode: PropTypes.string,
              categoryDesc: PropTypes.string,
              code: PropTypes.string,
              tag: PropTypes.string,
            })
          ),
        }),
      }),
    }),
  }),
  dataKey: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.string,
      Cell: PropTypes.func,
      Filters: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      Header: PropTypes.string,
    })
  ),
  filterKey: PropTypes.string,
  form: PropTypes.shape({
    formState: PropTypes.shape({
      errors: PropTypes.shape({
        filters: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.shape({
              message: PropTypes.string,
            }),
            condition: PropTypes.shape({
              message: PropTypes.string,
            }),
            value: PropTypes.shape({
              message: PropTypes.string,
            }),
          })
        ),
      }),
    }),
    getValues: PropTypes.func,
    resetField: PropTypes.func,
    setValue: PropTypes.func,
    validate: PropTypes.func,
  }),
  handleReset: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  permissions: PropTypes.shape({
    add: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
    edit: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
    remove: {
      attributes: [PropTypes.arrayOf(PropTypes.string)],
      isAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([null])]),
      resource: PropTypes.string,
    },
  }),
  remove: PropTypes.func,
  replace: PropTypes.func,
};

Table.defaultProps = {
  append: () => {},
  assets: {},
  dataKey: '',
  fields: [],
  filterKey: 'filters',
  form: {
    formState: {
      errors: {},
    },
    getValues: () => {},
    resetField: () => {},
    setValue: () => {},
    validate: () => {},
  },
  handleReset: () => {},
  isDisabled: false,
  isLoading: false,
  permissions: {
    add: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
    edit: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
    remove: {
      attributes: [],
      isAllowed: null,
      resource: '',
    },
  },
  remove: () => {},
  replace: () => {},
};
