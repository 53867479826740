import { config } from '@abyss/web/tools/config';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Link } from '@abyss/web/ui/Link';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { AssignmentsTable } from '@src/features/ActionPaths/components/tables/Assignments';
import { TrustedSourcesTable } from '@src/features/Risk/components/tables/TrustedSources';
import { UntrustedSourcesTable } from '@src/features/Risk/components/tables/UntrustedSources';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Skeleton } from './components/Skeleton';

/**
 * ExpansionRow
 *
 * Expands a table row on click.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function ExpansionRow(props) {
  const { row } = props;

  const { useApiQuery } = useApi();

  const [GetRiskRecord, { data, isFetching, isLoading }] = useApiQuery('GetRiskRecord');

  /**
   * Fetches additional data about the risk record when the row is expanded/opened.
   */
  useEffect(() => {
    if (isUndefined(data)) {
      GetRiskRecord({ eid: row?.eid });
    }
  }, [row]);

  if (isUndefined(data) || isLoading || isFetching) {
    return <Skeleton />;
  }

  return (
    <ErrorHandler location="src/features/Risk/components/tables/RiskRecords/components/ExpansionRow/ExpansionRow.jsx">
      <Grid css={{ margin: 0 }}>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          <Heading offset={2}>Record View</Heading>
          <p>
            <strong>EID:</strong>{' '}
            <Link href={`${config('HCM_URL')}/${row?.eid}/`} openNewWindow>
              {row?.eid}
            </Link>
          </p>
        </Grid.Col>
        <Grid.Col
          span={{
            xs: '50%',
          }}
        >
          <AssignmentsTable record={data} row={row} />
        </Grid.Col>
        <Grid.Col
          span={{
            xs: '25%',
          }}
        >
          <TrustedSourcesTable record={data} row={row} />
        </Grid.Col>
        <Grid.Col
          span={{
            xs: '25%',
          }}
        >
          <UntrustedSourcesTable record={data} row={row} />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
}

ExpansionRow.propTypes = {
  row: PropTypes.shape({
    eid: PropTypes.string,
  }),
};

ExpansionRow.defaultProps = {
  row: {},
};
