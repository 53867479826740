import { Badge } from '@abyss/web/ui/Badge';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Displays the risk trend report data in a table format.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Table(props) {
  const { data } = props;

  /**
   * renderCellValue
   *
   * Displays the count value and color coded percentage of change.
   *
   * @param args
   * @returns {Element}
   */
  const renderCellValue = (args) => {
    const { cell } = args;

    const colors = {
      black: 'neutral',
      green: 'success',
      red: 'error',
    };

    let theColor = colors.black;

    const percentageChange = cell?.value?.percChange;

    if (percentageChange > 10.0) {
      theColor = colors.red;
    }

    if (percentageChange < -10.0) {
      theColor = colors.green;
    }

    if (percentageChange < 10.0 && percentageChange > -10.0) {
      theColor = colors.black;
    }

    return (
      <Flex
        alignContent="flex-end"
        alignItems="flex-end"
        css={{ padding: 'var(--abyss-space-sm)' }}
        direction="column"
        justify=""
      >
        <div>
          <Text>{Number(cell?.value?.value).toLocaleString('en-US')}</Text>
        </div>
        <div>
          <Badge
            css={{ backgroundColor: 'transparent !important', paddingRight: '0px !important' }}
            variant={theColor}
          >{`${percentageChange > 0 ? '+' : ''}${percentageChange}%`}</Badge>
        </div>
      </Flex>
    );
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    const theColumns = [
      {
        accessor: 'date',
        Header: 'Date',
        sticky: '0px',
      },
      {
        accessor: 'total',
        Cell: renderCellValue,
        Header: 'Total',
      },
    ];

    data?.series?.forEach((item) => {
      theColumns?.push({
        accessor: item?.sourceCode,
        Cell: renderCellValue,
        Header: item?.sourceCode,
      });
    });

    return theColumns;
  }, [data]);

  /**
   * Rows for table.
   */
  const rows = useMemo(() => {
    const dates = {};
    data?.series?.forEach((item) => {
      item?.dataPoints?.forEach((theData) => {
        if (isUndefined(dates?.[theData?.date])) {
          dates[theData?.date] = {};
        }
        if (isUndefined(dates?.[theData?.date][item?.sourceCode])) {
          dates[theData?.date][item?.sourceCode] = {};
        }
        dates[theData?.date][item?.sourceCode] = theData;
      });
    });

    data?.sumSeries?.dataPoints?.forEach((theData) => {
      if (isUndefined(dates?.[theData?.date])) {
        dates[theData?.date] = {};
      }
      if (isUndefined(dates?.[theData?.date].total)) {
        dates[theData?.date].total = {};
      }
      dates[theData?.date].total = theData;
    });

    const theRows = [];

    Object.keys(dates).forEach((theDate) => {
      const theSources = [];
      Object.keys(dates[theDate]).forEach((theSource) => {
        theSources.push({
          [theSource]: dates[theDate][theSource],
        });
      });

      const temp = {
        date: theDate,
      };

      theSources.forEach((source) => {
        const keys = Object.keys(source);
        const key = keys[0];
        temp[key] = source[key];
      });

      theRows.push(temp);
    });

    return theRows;
  }, [data]);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/Operations/components/Table/Table.jsx">
      <TableComponent
        {...{
          columns,
          configuration,
          rows,
          stickyHeaders: true,
        }}
      />
    </ErrorHandler>
  );
}

Table.propTypes = {
  data: PropTypes.shape({
    series: PropTypes.arrayOf(
      PropTypes.shape({
        dataPoints: PropTypes.arrayOf(
          PropTypes.shape({
            date: PropTypes.string,
            percChange: PropTypes.number,
            value: PropTypes.number,
          })
        ),
        sourceCode: PropTypes.string,
      })
    ),
    sumSeries: PropTypes.shape({
      dataPoints: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          percChange: PropTypes.number,
          value: PropTypes.number,
        })
      ),
    }),
  }),
};

Table.defaultProps = {
  data: {},
};
