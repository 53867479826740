import { ErrorHandler } from '@src/components/ErrorHandler';
import { hexToRgb } from '@src/includes/functions';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import stc from 'string-to-color';

const stringToColor = stc;

/**
 * LegendHeight
 *
 * Adds spacing between the legend and the chart.
 *
 * @type {{id: string, beforeInit(*): void}}
 */
const LegendHeight = {
  beforeInit(chart) {
    const origFit = chart.legend.fit;

    // eslint-disable-next-line no-param-reassign
    chart.legend.fit = function fit() {
      origFit.bind(chart.legend)();
      this.height += 50;
    };
  },
  id: 'LegendHeight',
};

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LegendHeight);

/**
 * Chart: Donut
 *
 * Displays a donut chart.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Donut(props) {
  const { data, labels } = props;

  return (
    <ErrorHandler location="src/features/ActionPaths/components/charts/Donut/Donut.jsx">
      <div
        style={{
          height: '100%',
          margin: '0px auto',
          maxHeight: '325px',
          maxWidth: '520px',
          minHeight: '260px',
          position: 'relative',
        }}
      >
        <Doughnut
          data={{
            datasets: [
              {
                backgroundColor: labels?.map((label) => {
                  return hexToRgb(stringToColor(label), 1.0);
                }),
                borderColor: labels?.map((label) => {
                  return hexToRgb(stringToColor(label), 1.0);
                }),
                borderWidth: 1,
                data:
                  Object.keys(data)?.map((key) => {
                    const item = data[key];
                    return item?.amount;
                  }) || [],
                label: '# of records',
              },
            ],
            labels: labels || [],
          }}
          options={{
            animation: {
              duration: 0, // general animation time
            },
            hover: {
              animationDuration: 0, // duration of animations when hovering an item
            },
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                labels: {
                  boxHeight: 16,
                  boxWidth: 16,
                  generateLabels: (chart) => {
                    return chart.data.labels.map((label, i) => {
                      return {
                        fillStyle: chart.data.datasets[0].backgroundColor[i],
                        hidden: chart.data.datasets[0].data[i] === 0 ? true : !chart.getDataVisibility(i),
                        index: i,
                        text: label,
                      };
                    });
                  },
                  useBorderRadius: false,
                },
                position: 'top',
              },
            },
            responsive: true,
            responsiveAnimationDuration: 0, // animation duration after a resize
          }}
        />
      </div>
    </ErrorHandler>
  );
}

Donut.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      percent: PropTypes.number,
      type: PropTypes.string,
    })
  ),
  labels: PropTypes.arrayOf(PropTypes.string),
};

Donut.defaultProps = {
  data: [],
  labels: [],
};
