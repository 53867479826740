import { Alert } from '@abyss/web/ui/Alert';
import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { Table } from './components/Table';

/**
 * Remediation
 *
 * Displays remediation information about the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function RemediationEvents(props) {
  const { riskRecord } = props;

  const remediationEvents = riskRecord?.remediation?.remediationEvents || [];

  return (
    <ErrorHandler location="src/routes/private/Analysis/EidSearch/components/widgets/RemediationEvents/RemediationEvents.jsx">
      {isEmpty(remediationEvents) ? (
        <Grid.Col span={{ lg: '100%', md: '100%', sm: '100%', xs: '100%' }}>
          <Alert title="There is no remediation data affiliated with this risk record." variant="info" />
        </Grid.Col>
      ) : (
        <Grid>
          <Grid.Col span={{ xs: '100%' }}>
            <Table rows={remediationEvents} />
          </Grid.Col>
        </Grid>
      )}
    </ErrorHandler>
  );
}

RemediationEvents.propTypes = {
  riskRecord: PropTypes.shape({
    remediation: PropTypes.shape({
      remediationEvents: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          description: PropTypes.string,
          user: PropTypes.string,
        })
      ),
    }),
  }),
};

RemediationEvents.defaultProps = {
  riskRecord: {
    remediation: {
      remediationEvents: [],
    },
  },
};
