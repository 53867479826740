import { dayjs } from '@abyss/web/tools/dayjs';
import { Table as AbyssTable } from '@abyss/web/ui/Table';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * Table
 *
 * Table component to display the alert information
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Table(props) {
  const { alert } = props;

  return (
    <ErrorHandler location="src/routes/private/Notifications/Alerts/List/components/EditModal/components/Table/Table.jsx">
      <AbyssTable.Container style={{ tableLayout: 'fixed', width: '752px' }} title="">
        <AbyssTable.TableHeader>
          <AbyssTable.TableHeaderRow>
            <AbyssTable.Column style={{ width: '25%' }}>Last Updated</AbyssTable.Column>
            <AbyssTable.Column>Description</AbyssTable.Column>
          </AbyssTable.TableHeaderRow>
        </AbyssTable.TableHeader>
        <AbyssTable.TableBody>
          <AbyssTable.Row>
            <AbyssTable.Cell>{dayjs(alert?.lastModifiedDate).format('MM/DD/YYYY, HH:mm:ss')}</AbyssTable.Cell>
            <AbyssTable.Cell>
              <Tooltip
                content={
                  <div
                    style={{
                      fontFamily: themeConfiguration?.theme?.fonts?.primary,
                      padding: themeConfiguration?.theme?.space?.sm,
                    }}
                  >
                    {alert?.shortDesc}
                  </div>
                }
              >
                <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {alert?.shortDesc}
                </div>
              </Tooltip>
            </AbyssTable.Cell>
          </AbyssTable.Row>
        </AbyssTable.TableBody>
      </AbyssTable.Container>
    </ErrorHandler>
  );
}

Table.propTypes = {
  alert: PropTypes.shape({
    lastModifiedDate: PropTypes.string,
    shortDesc: PropTypes.string,
  }),
};

Table.defaultProps = {
  alert: {},
};
