import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { abbrNum } from '@src/includes/functions';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './includes/styles';

/**
 * YellowEntities
 *
 * Displays the total number of yellow entities.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function YellowEntities(props) {
  const { data } = props;

  return (
    <ErrorHandler location="src/routes/private/Dashboards/RiskDashboard/components/YellowEntities/YellowEntities.jsx">
      <Styles>
        <fieldset>
          <legend>
            <Heading offset={5}>Yellow Entities</Heading>
          </legend>
          <Flex alignContent="center" direction="row" justify="space-evenly">
            <Tooltip content={`Total: ${Number(data?.countTotal).toLocaleString('en-US')}`}>
              <div className="item">
                <small>Total</small>
                <Heading offset={3}>{abbrNum(data?.countTotal)}</Heading>
              </div>
            </Tooltip>
          </Flex>
        </fieldset>
      </Styles>
    </ErrorHandler>
  );
}

YellowEntities.propTypes = {
  data: PropTypes.shape({
    countTotal: PropTypes.number,
  }),
};

YellowEntities.defaultProps = {
  data: {},
};
