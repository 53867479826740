import { config } from '@abyss/web/tools/config';
import { dayjs } from '@abyss/web/tools/dayjs';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Table as AbyssTable } from '@abyss/web/ui/Table';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isNull, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

/**
 * Table
 *
 * Displays change event information in a table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function SplitMerge(props) {
  const { changeData } = props;
  const [changeEvents, setChangeEvents] = useState([]);

  /**
   * Format the change events data to output in a table.
   */
  useEffect(() => {
    const events = [];

    changeData?.changeEvents?.forEach((changeEvent) => {
      const event = { ...changeEvent };
      if (changeEvent?.changeType === 'SPLIT') {
        event.associatedSplitEids = changeData?.associatedSplitEids;
      }

      if (changeEvent?.changeType === 'MERGE') {
        if (isNull(changeData?.mergedInto)) {
          event.associatedMergedEids = changeData?.associatedMergedEids;
        } else {
          event.mergedInto = changeData?.mergedInto;
        }
      }

      events.push(event);
    });

    if (events !== changeEvents) {
      setChangeEvents(events);
    }
  }, [changeData]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/EidSearch/components/widgets/Eimp/components/SplitMerge/SplitMerge.jsx">
      <p>
        <strong>Split / Merge</strong>
      </p>
      <Layout.Group alignItems="top" alignLayout="left">
        {changeEvents?.map((changeEvent) => {
          return (
            <div className="static-table" key={`date-${changeEvent?.changeDate}`} style={{ minWidth: 400 }}>
              <AbyssTable.Container>
                <AbyssTable.TableBody>
                  <AbyssTable.Row>
                    <AbyssTable.Column css={{ minWidth: 200 }}>Change Type</AbyssTable.Column>
                    <AbyssTable.Cell>{changeEvent?.changeType}</AbyssTable.Cell>
                  </AbyssTable.Row>

                  <AbyssTable.Row>
                    <AbyssTable.Column css={{ minWidth: 200 }}>Change Date</AbyssTable.Column>
                    <AbyssTable.Cell>{dayjs(changeEvent?.changeDate).format('MM/DD/YYYY, HH:mm:ss')}</AbyssTable.Cell>
                  </AbyssTable.Row>

                  {!isUndefined(changeEvent?.associatedSplitEids) && (
                    <AbyssTable.Row>
                      <AbyssTable.Column css={{ minWidth: 200 }}>Associated Split EID's</AbyssTable.Column>
                      <AbyssTable.Cell>
                        {changeEvent?.associatedSplitEids?.map((eid, index) => {
                          return (
                            <React.Fragment key={`eid-${eid}`}>
                              <Link href={`${config('HCM_URL')}/${eid}/`} openNewWindow>
                                {eid}
                              </Link>
                              {index < changeEvent?.associatedSplitEids?.length - 1 && ', '}
                            </React.Fragment>
                          );
                        })}
                      </AbyssTable.Cell>
                    </AbyssTable.Row>
                  )}

                  {!isUndefined(changeEvent?.associatedMergedEids) && (
                    <AbyssTable.Row>
                      <AbyssTable.Column css={{ minWidth: 200 }}>Associated Merged EID's</AbyssTable.Column>
                      <AbyssTable.Cell>
                        {changeEvent?.associatedMergedEids?.map((eid, index) => {
                          return (
                            <React.Fragment key={`eid-${eid}`}>
                              <Link href={`${config('HCM_URL')}/${eid}/`} openNewWindow>
                                {eid}
                              </Link>
                              {index < changeEvent?.associatedMergedEids?.length - 1 && ', '}
                            </React.Fragment>
                          );
                        })}
                      </AbyssTable.Cell>
                    </AbyssTable.Row>
                  )}

                  {!isUndefined(changeEvent?.mergedInto) && (
                    <AbyssTable.Row>
                      <AbyssTable.Column css={{ minWidth: 200 }}>EID Merged Into</AbyssTable.Column>
                      <AbyssTable.Cell>
                        <Link
                          href={`${config('HCM_URL')}/${changeEvent?.mergedInto}/`}
                          key={`eid-${changeEvent?.mergedInto}`}
                          openNewWindow
                        >
                          {changeEvent?.mergedInto}
                        </Link>
                      </AbyssTable.Cell>
                    </AbyssTable.Row>
                  )}
                </AbyssTable.TableBody>
              </AbyssTable.Container>
            </div>
          );
        })}
      </Layout.Group>
    </ErrorHandler>
  );
}

SplitMerge.propTypes = {
  changeData: PropTypes.shape({
    associatedMergedEids: PropTypes.arrayOf(PropTypes.string),
    associatedSplitEids: PropTypes.arrayOf(PropTypes.string),
    changeEvents: PropTypes.arrayOf(
      PropTypes.shape({
        associatedMergedEids: PropTypes.arrayOf(PropTypes.string),
        associatedSplitEids: PropTypes.arrayOf(PropTypes.string),
        changeDate: PropTypes.string,
        changeType: PropTypes.string,
        mergedInto: PropTypes.string,
      })
    ),
    mergedInto: PropTypes.string,
  }),
};

SplitMerge.defaultProps = {
  changeData: {},
};
