import { NavMenuPrimitives } from '@abyss/web/ui/NavMenu';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { MenuItem } from '../MenuItem';
import { SubMenu } from '../SubMenu';

/**
 * Menu
 *
 * Provides the user with a menu.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Menu(props) {
  const { items } = props;

  const theItems = useMemo(() => {
    return orderBy(items, ['menuOrder'], ['asc']);
  }, [items]);

  return (
    <ErrorHandler location="src/layouts/Application/sections/Header/components/Navigation/components/Menu/Menu.jsx">
      {!isEmpty(theItems) &&
        theItems?.map((item, index) => {
          const theChildren = item?.children?.filter((child) => {
            return child?.showInNavigation === true;
          });

          if (item?.path !== '/' && item?.showInNavigation === true) {
            if (!isEmpty(theChildren) && !item?.hideSubNav) {
              return (
                <NavMenuPrimitives.Item key={`${item?.accessor}-parentItem`}>
                  <NavMenuPrimitives.Trigger variant="inverted">
                    <MenuItem iconName={item?.navigationIcon} title={item?.navigationLabel} />
                  </NavMenuPrimitives.Trigger>
                  <NavMenuPrimitives.Content>
                    <SubMenu items={theChildren} />
                  </NavMenuPrimitives.Content>
                </NavMenuPrimitives.Item>
              );
            }

            return (
              <NavMenuPrimitives.Link href={item?.path} key={`${item?.accessor}-parentLink`} variant="inverted">
                <MenuItem iconName={item?.navigationIcon} title={item?.navigationLabel} />
              </NavMenuPrimitives.Link>
            );
          }

          return <React.Fragment key={`${item?.accessor}-parentItem`} />;
        })}
    </ErrorHandler>
  );
}

PropTypes.Menu = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
