import { dayjs } from '@abyss/web/tools/dayjs';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRoutesContext } from '@src/context/Routes/Routes';
import { useAssets } from '@src/hooks/useAssets';
import { DashboardLayout } from '@src/layouts/Dashboard';
import { isEmpty, isUndefined } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { Details } from './components/Details';
import { Form } from './components/Form';
import { widgets } from './components/widgets';
import { useSearch } from './hooks/useSearch';

/**
 * Risk
 *
 * Provides the user with a screen to view various statistics relating to risk records.
 *
 * @returns {Element}
 * @constructor
 */
export function Risk() {
  const { currentRoute } = useRoutesContext();

  const [reportDate, setReportDate] = useState(dayjs().format('MM/DD/YYYY'));

  const { assets, isLoadingAssets } = useAssets([
    'ListRiskCodes',
    'ListRedRiskCodes',
    'ListEventTypes',
    'ListImpactedSystems',
  ]);
  const { data, handleSearch, isSearching } = useSearch();

  useEffect(() => {
    const theDate = dayjs(reportDate).format('YYYY-MM-DD');

    if (
      (!isEmpty(reportDate) && isEmpty(data)) ||
      (!isSearching && data?.GetRiskDashboardStatistics?.data?.date !== theDate)
    ) {
      if (!isUndefined(assets?.ListEventTypes?.data)) {
        const eventTypeCodeList =
          assets?.ListEventTypes?.data?.map((eventType) => {
            return eventType?.codeId;
          }) || [];

        handleSearch({ eventTypeCodeList, reportDate });
      }
    }
  }, [assets, isSearching, reportDate, data]);

  const theWidgets = useMemo(() => {
    return Object.values(widgets)
      .sort((a, b) => {
        return a.order - b.order;
      })
      .map((widget) => {
        const theWidget = { ...widget };

        if (widget.accessor === 'events') {
          theWidget.description = `${dayjs(reportDate).subtract(6, 'months').format('MM/DD/YYYY')} - ${dayjs(reportDate).format('MM/DD/YYYY')}`;
        }

        theWidget.icon = <IconSymbol color="#000000" icon={widget?.icon} variant="outlined" />;

        const Component = widgets?.[theWidget?.accessor]?.component;

        theWidget.element = Component ? <Component assets={assets} data={data} options={theWidget} /> : null;

        return theWidget;
      });
  }, [widgets, assets, data]);

  return (
    <ErrorHandler location="src/routes/private/Dashboardss/RiskDashboard/RiskDashboard.jsx">
      <DashboardLayout
        hasData={!isUndefined(data)}
        header={{
          LeftComponent: (
            <Form
              isFetching={isSearching}
              isLoading={isLoadingAssets}
              reportDate={reportDate}
              setReportDate={setReportDate}
            />
          ),
          RightComponent: <Details data={data} />,
        }}
        isFetching={isSearching}
        isLoading={isLoadingAssets}
        title={currentRoute?.screenTitle}
        widgets={theWidgets}
      />
    </ErrorHandler>
  );
}
