import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { fieldValidator } from '@src/includes/validation';
import { isUndefined, orderBy, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import fields from '../includes/fields.json';

/**
 * field: Views
 *
 * Displays a dropdown to select a view.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Views(props) {
  const { assets, form } = props;

  const viewOptions = useMemo(() => {
    const options = assets?.ListViews?.data
      ?.filter((view) => {
        return view?.status === 'ACTIVE';
      })
      ?.map((view) => {
        return {
          label: view?.name,
          value: view?.id,
        };
      });

    options?.push({
      label: 'Risk Records Sample Data',
      value: 'risk-records',
    });

    const sortedOptions = sortBy(options, [
      (view) => {
        return view.label;
      },
    ]);

    const theViews = {};

    sortedOptions.forEach((option) => {
      let section = null;

      if (option?.value.includes('-count')) {
        section = 'Counts';
      } else if (option?.value.includes('-date')) {
        section = 'Dates';
      } else if (option?.value.includes('-tag')) {
        section = 'Tags';
      } else {
        section = 'Other';
      }

      if (isUndefined(theViews[section])) {
        theViews[section] = [];
      }

      if (!theViews[section].includes(option)) {
        theViews[section].push({
          ...option,
          ...{
            label: `${option.label} (${section})`,
          },
        });
      }
    });

    const selectableOptions = [];

    Object.keys(theViews).forEach((section) => {
      selectableOptions.push({
        items: theViews[section],
        section,
      });
    });

    return orderBy(selectableOptions, ['section'], ['asc']);
  }, [assets?.ListViews?.data]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/RiskAnalysis/components/fields/Views/Views.jsx">
      <SelectInputMulti
        {...fields?.views}
        maxListHeight="350px"
        onChange={() => {
          form?.validate(
            `views`,
            () => {},
            () => {}
          );
        }}
        options={viewOptions}
        validators={{
          ...fields?.views?.validators,
          ...{
            validate: {
              customValidator: (value) => {
                return fieldValidator(fields?.views, value);
              },
            },
          },
        }}
      />
    </ErrorHandler>
  );
}

Views.propTypes = {
  assets: PropTypes.shape({
    ListViews: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          status: PropTypes.string,
        })
      ),
    }),
  }),
  form: PropTypes.shape({
    validate: PropTypes.func,
  }),
};

Views.defaultProps = {
  assets: {},
  form: {},
};
