import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';

/**
 * ListChronoUnits
 *
 * Retrieves a list of chronological units from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListChronoUnits = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': '/code-lists/categories/ChronoUnit',
      },
      method: 'GET',
      params: { queryKey: theQueryKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/ListChronoUnits.js -> ListChronoUnits()', false, error);

    throw error;
  }
};
