import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { translateCriteria } from '@src/features/ActionPaths/includes/functions';
import { logger } from '@src/includes/logger';
import { isNil, isUndefined } from 'lodash';

/**
 * GetAggregationView
 *
 * @TODO Needs description.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetAggregationView = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: {
        aggregationFilter: '',
        researchAggregationId: '',
      },
      headers: {
        'x-api-endpoint': `/aggregation/playground`,
      },
      method: 'POST',
      params: { queryKey: theQueryKey },
    };

    if (!isUndefined(thePayload?.view)) {
      requestArgs.data.researchAggregationId = thePayload?.view;
    }

    delete thePayload?.criteria?.entrance?.mergedStr;
    delete thePayload?.criteria?.exit?.mergedStr;

    if (!isNil(thePayload?.criteria)) {
      requestArgs.data.criteria = translateCriteria(thePayload?.criteria, 'outgoing');
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/GetAggregationView.js -> GetAggregationView()', false, error);

    throw error;
  }
};
