import { DrawerMenu } from '@abyss/web/ui/DrawerMenu';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';

/**
 * Navigation
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Navigation(props) {
  const { isSidebarOpen, setCurrentTarget, toggleSidebar, widgets } = props;
  const buttonRef = useRef(null);

  /**
   * handle the click event for the expand/collapse button
   */
  useEffect(() => {
    const button = document.querySelector('.abyss-drawer-menu-expand-button');

    const handleClick = () => {
      if (isNull(isSidebarOpen) || !isSidebarOpen) {
        toggleSidebar(true);
      } else {
        toggleSidebar(false);
      }
    };

    if (button) {
      button.addEventListener('click', handleClick);
      buttonRef.current = button;

      if (isNull(isSidebarOpen)) {
        buttonRef.current.click();
      }
    }

    return () => {
      if (button) button.removeEventListener('click', handleClick);
    };
  }, [isSidebarOpen]);

  const menuItems = useMemo(() => {
    const theMenuItems = widgets.map((widget) => {
      const theMenuItem = { ...widget };

      theMenuItem.icon = <IconSymbol color="#000000" icon={widget?.icon} variant="outlined" />;
      theMenuItem.href = `#${widget?.accessor}`;
      theMenuItem.onClick = () => {
        setCurrentTarget(widget?.accessor);
      };

      return theMenuItem;
    });

    theMenuItems.push({
      accessor: 'back-to-top',
      description: '',
      icon: <IconSymbol color="#000000" icon="arrow_circle_up" variant="outlined" />,
      onClick: () => {
        setCurrentTarget(null);
      },
      title: 'Back to Top',
    });

    return theMenuItems;
  }, [widgets]);

  return (
    <ErrorHandler location="src/layouts/Dashboard/sections/Sidebar/components/Navigation/Navigation.jsx">
      <DrawerMenu menuItems={menuItems} />
    </ErrorHandler>
  );
}

Navigation.propTypes = {
  hasDOB: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  hasSSN: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
  isSidebarOpen: PropTypes.bool,
  setCurrentTarget: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

Navigation.defaultProps = {
  hasDOB: false,
  hasSSN: false,
  isSidebarOpen: false,
  setCurrentTarget: () => {},
  toggleSidebar: () => {},
};
