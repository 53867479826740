import { useVisibilityContext } from '@src/features/Users/context/Visibility';
import { isEmpty, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

/**
 * Default route context values.
 *
 * @type {{setCurrentRoute: defaultValues.setCurrentRoute, setCurrentRoutes: defaultValues.setCurrentRoutes,
 *   currentRoute: {}, currentRoutes: *[]}}
 */
const defaultValues = {
  currentRoute: {},
  currentRoutes: [],
  setCurrentRoute: () => {},
  setCurrentRoutes: () => {},
};

/**
 * Creates the route context.
 *
 * @type {React.Context<{setCurrentRoute: defaultValues.setCurrentRoute, setCurrentRoutes:
 *   defaultValues.setCurrentRoutes, currentRoute: {}, currentRoutes: *[]}>}
 */
const RoutesContext = createContext(defaultValues);

/**
 * Allows components to access the routes context.
 *
 * @returns {{setCurrentRoute: defaultValues.setCurrentRoute, setCurrentRoutes: defaultValues.setCurrentRoutes,
 *   currentRoute: {}, currentRoutes: *[]}}
 */
export const useRoutesContext = () => {
  return useContext(RoutesContext);
};

/**
 * RoutesProvider
 *
 * Handles setting the context as it relates to routes.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function RoutesProvider(props) {
  const { children } = props;

  const [currentRoute, setCurrentRoute] = useState(defaultValues.currentRoute);
  const [currentRoutes, setCurrentRoutes] = useState(defaultValues.currentRoutes);
  const { handleVisibilityComponent } = useVisibilityContext();

  useEffect(() => {
    if (!isEmpty(currentRoutes)) {
      currentRoutes?.forEach((theRoute) => {
        const { visibility } = theRoute;

        if (!isUndefined(visibility)) {
          const { disabledEnvironments, enabledEnvironments } = visibility;

          handleVisibilityComponent(theRoute?.accessor, {
            disabledEnvironments,
            enabledEnvironments,
          });
        } else {
          handleVisibilityComponent(theRoute?.accessor, {});
        }
      });
    }
  }, [currentRoutes]);

  const value = useMemo(() => {
    return {
      currentRoute,
      currentRoutes,
      setCurrentRoute,
      setCurrentRoutes,
    };
  }, [currentRoutes, currentRoute]);

  return <RoutesContext.Provider value={value}>{children}</RoutesContext.Provider>;
}

RoutesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
