import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import React from 'react';

/**
 * Skeleton
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Skeleton(props) {
  const { count } = props;

  return (
    <ErrorHandler location="src/layouts/Dashboard/sections/Main/components/Skeleton/Skeleton.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        style={{ width: '100%' }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          {Array.from({ length: count }).map(() => {
            return (
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <Layout.Stack alignItems="left" grow>
                  <AbyssSkeleton
                    data-testid="main-skeleton"
                    height={themeConfiguration?.theme?.space?.lg}
                    width="15%"
                  />
                  <AbyssSkeleton
                    data-testid="main-skeleton"
                    height={themeConfiguration?.theme?.space?.lg}
                    width="30%"
                  />
                  <AbyssSkeleton data-testid="main-skeleton" height="300px" width="100%" />
                </Layout.Stack>
              </Grid.Col>
            );
          })}
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
}
