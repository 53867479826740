import { Accordion } from '@abyss/web/ui/Accordion';
import { Badge } from '@abyss/web/ui/Badge';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { useApi } from '@src/context/Api';
import { useRoutesContext } from '@src/context/Routes/Routes';
import { motion } from 'framer-motion';
import { isEmpty, isNil, isNull } from 'lodash';
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';

import { CreateButton } from './components/buttons/Create';
import { EditForm } from './components/forms/Edit';
import { NotFound } from './components/NotFound';
import { Styles } from './includes/styles';

/**
 * CommonCriteria
 *
 * Provides the user with a screen listing the common criteria to be used for filtering risk records.
 *
 * @returns {Element}
 * @constructor
 */
export function CommonCriteria() {
  const [showAll, setShowAll] = useState(false);

  const { currentRoute } = useRoutesContext();

  const { useApiQuery } = useApi();
  const [ListCommonCriteria, { data, isFetching, isLoading, refetch }] = useApiQuery('ListCommonCriteria');

  /**
   * Fetch the common criteria data if it is not already loaded.
   */
  useEffect(() => {
    const payload = {
      page: 0,
      size: 9999,
      sort: 'name,asc',
    };

    if (showAll === false) {
      payload.isActive = true;
    }

    ListCommonCriteria(payload);
  }, [showAll]);

  const [focusedEntity, setFocusedEntity] = useState(null);

  const triggerRefs = useRef({});

  triggerRefs.current = useMemo(() => {
    return data?.content?.map((item, index) => {
      const currentRef = triggerRefs?.current?.[index];

      if (!isNil(currentRef)) {
        return currentRef;
      }

      return createRef();
    });
  }, [data, triggerRefs]);

  return (
    <ErrorHandler location="src/routes/private/Admin/CommonCriteria/CommonCriteria.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Styles>
          <Grid>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Flex alignItems="center" direction="row">
                <Heading offset={0}>Common Criteria</Heading>
                <CreateButton focusedEntity={focusedEntity} refetch={refetch} setFocusedEntity={setFocusedEntity}>
                  Create
                </CreateButton>
              </Flex>
            </Grid.Col>

            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <ToggleSwitch
                isChecked={showAll}
                label="Show all Criteria"
                onChange={(event) => {
                  return setShowAll(event?.target?.checked);
                }}
              />
            </Grid.Col>

            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              {isLoading || isFetching ? (
                <Loader verticalAlignment="top" />
              ) : (
                <React.Fragment>
                  {isEmpty(data?.content) ? (
                    <React.Fragment>
                      {showAll === true && (
                        <NotFound
                          button={<CreateButton refetch={refetch}>Create Criteria</CreateButton>}
                          icon="settings"
                          message="Get started by creating common criteria filters."
                          title="No Criteria Found"
                        />
                      )}
                      {showAll === false && (
                        <NotFound
                          button={
                            <Link
                              after={<IconSymbol css={{ fontSize: 'inherit !important' }} icon="double_arrow" />}
                              href={currentRoute?.path}
                              onClick={(event) => {
                                event.preventDefault();
                                setShowAll(true);
                              }}
                              size="$sm"
                            >
                              Show all Criteria
                            </Link>
                          }
                          icon="settings"
                          message="There is currently no activated criteria filters."
                          title="No Activated Criteria"
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <Accordion defaultValue={focusedEntity} isCollapsible>
                      {data?.content?.map((item, index) => {
                        return (
                          <Accordion.Item key={`${item?.name}`} value={item?.name}>
                            <Accordion.Trigger
                              onClick={() => {
                                if (!isNull(focusedEntity)) {
                                  if (item?.name === focusedEntity) {
                                    setFocusedEntity(item?.name);
                                  } else {
                                    setFocusedEntity(null);
                                  }
                                } else {
                                  setFocusedEntity(item?.name);
                                }
                              }}
                              ref={triggerRefs?.current?.[index]}
                            >
                              <Accordion.Header>
                                <Heading color="var(--abyss-colors-gray7)" offset={3}>
                                  <Layout.Group space={12}>
                                    {item?.name}

                                    {item?.activeCommonCriteriaVersion ? (
                                      <Text fontWeight={400} size="$sm">{`Current: v${item.activeVersionNbr}`}</Text>
                                    ) : (
                                      ''
                                    )}

                                    {item?.isActive === true && (
                                      <Badge outline variant="success">
                                        Active
                                      </Badge>
                                    )}

                                    {item?.isActive === false && (
                                      <Badge outline variant="error">
                                        Inactive
                                      </Badge>
                                    )}
                                  </Layout.Group>
                                </Heading>
                              </Accordion.Header>
                            </Accordion.Trigger>
                            <Accordion.Content>
                              <EditForm
                                currentEntity={item}
                                refetch={refetch}
                                setFocusedEntity={setFocusedEntity}
                                setShowAll={setShowAll}
                              />
                            </Accordion.Content>
                          </Accordion.Item>
                        );
                      })}
                    </Accordion>
                  )}
                </React.Fragment>
              )}
            </Grid.Col>
          </Grid>
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
}
