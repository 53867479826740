import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { _translateCriteriaFilters } from '@src/features/ActionPaths/includes/functions';
import { logger } from '@src/includes/logger';
import { isEmpty, isNil, omit, pick } from 'lodash';

/**
 * ListCommonCriteria
 *
 * Retrieves a list of common criteria from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListCommonCriteria = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = omit(payload?.[1], ['page', 'size', 'sort']);
    const thePagination = pick(payload?.[1], ['page', 'size', 'sort']);

    const requestArgs = {
      baseURL: config('API_URL'),
      headers: {
        'x-api-endpoint': '/criteria',
      },
      method: 'GET',
      params: { ...{ queryKey: theQueryKey }, ...thePagination },
    };

    if (!isEmpty(thePayload)) {
      requestArgs.headers['x-api-endpoint'] += '/search';
      requestArgs.method = 'POST';
      requestArgs.data = thePayload;
    }

    const remoteResponse = await Axios.request(requestArgs);

    remoteResponse.data.content = remoteResponse.data.content.map((criteria) => {
      const theCriteria = { ...criteria };

      if (!isNil(theCriteria?.activeCommonCriteriaVersion?.criteria)) {
        theCriteria.activeCommonCriteriaVersion.criteria = _translateCriteriaFilters(
          theCriteria?.activeCommonCriteriaVersion?.criteria,
          'incoming'
        );
      }

      return theCriteria;
    });

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/queries/ListCommonCriteria.js -> ListCommonCriteria()', false, error);

    throw error;
  }
};
