import { useRouter } from '@abyss/web/hooks/useRouter';
import { useApi } from '@src/context/Api';
import { isNil, isUndefined, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { EntranceCriteria } from '../components/steps/EntranceCriteria';
import { ExitCriteria } from '../components/steps/ExitCriteria';
import { RemediateRecords } from '../components/steps/RemediateRecords';
import { ReviewConfirm } from '../components/steps/ReviewConfirm';
import { RiskRecords } from '../components/steps/RiskRecords';

/**
 * Default wizard context values.
 */
const defaultContext = {
  action: '',
  actionPath: {},
  currentStep: {},
  firstStep: {},
  id: '',
  lastStep: {},
  mode: '',
  nextStep: {},
  previousStep: {},
  stepNumber: '',
  steps: [],
  update: () => {},
};

/**
 * Creates the wizard context.
 */
const WizardContext = createContext(defaultContext);

/**
 * Allows components to access the wizard context.
 */
export const useWizardContext = () => {
  return useContext(WizardContext);
};

/**
 * ContextProvider
 *
 * Manages the context of the wizard component.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function ContextProvider(props) {
  const { children, state } = props;

  const router = useRouter();
  const routeParams = router?.getRouteParams();
  const { id, mode } = routeParams;

  const { clearApiCache, useApiQuery } = useApi();
  const [GetActionPath, { data: actionPath, isFetching, isLoading, refetch }] = useApiQuery('GetActionPath');

  /**
   * Fetch the action path if it hasn't been loaded yet.
   */
  useEffect(() => {
    if (!isNil(id) && id !== '0' && isUndefined(actionPath) && !isLoading && !isFetching) {
      GetActionPath({ actionPathId: id });
    }
  }, [id, actionPath, isLoading, isFetching]);

  /**
   * Specifies the steps and components used throughout the wizard/stepper experience.
   */
  const steps = useMemo(() => {
    return [
      {
        component: EntranceCriteria,
        description: 'Specify entrance criteria to filter search results.',
        isManual: false,
        isValid: false,
        key: 'entranceCriteria',
        label: 'Entrance Criteria',
        order: 1,
      },
      {
        component: RiskRecords,
        description: 'Browse and select from a list of risk records to remediate.',
        isManual: false,
        isValid: false,
        key: 'riskRecords',
        label: 'Risk Records',
        order: 2,
      },
      {
        component: RemediateRecords,
        description: 'Specify actions to run on each record source.',
        isManual: true,
        isValid: false,
        key: 'remediateRecords',
        label: 'Remediate Records',
        order: 3,
      },
      {
        component: ExitCriteria,
        description: 'Specify exit criteria to filter search results.',
        isManual: false,
        isValid: false,
        key: 'exitCriteria',
        label: 'Exit Criteria',
        order: 4,
      },
      {
        component: ReviewConfirm,
        description: 'Verify the actions with the filtered records to remediate.',
        isManual: true,
        isValid: false,
        key: 'reviewConfirm',
        label: 'Review & Confirm',
        order: 5,
      },
    ]
      .filter((step) => {
        if (mode === 'manual') {
          return step?.isManual;
        }

        return true;
      })
      .map((step, index) => {
        const theStep = { ...step };

        if (mode === 'manual') {
          theStep.order = index + 1;
        }

        return theStep;
      });
  }, [mode]);

  const stepIndex = Number(routeParams?.stepNumber) - 1 || 0;

  const currentStep = steps?.[stepIndex];
  const firstStep = steps?.[0];
  const lastStep = steps?.[steps.length - 1];
  const nextStep = steps?.[stepIndex + 1];
  const previousStep = steps?.[stepIndex - 1];

  const [theState, setTheState] = useState(
    merge({}, defaultContext, routeParams, {
      actionPath,
      currentStep,
      firstStep,
      lastStep,
      nextStep,
      previousStep,
      steps,
    })
  );

  /**
   * Updates the context state.
   *
   * @param newState
   */
  const update = (newState) => {
    setTheState((previousState) => {
      return merge({}, previousState, newState);
    });
  };

  /**
   * Updates the context state when the state changes.
   */
  useEffect(() => {
    if (state !== theState) {
      update(state);
    }
  }, [state]);

  useEffect(() => {
    (async () => {
      if (!isUndefined(actionPath) && !isLoading && !isFetching) {
        await clearApiCache(['GetActionPath']);
        refetch();
      }
    })();
  }, [currentStep]);

  /**
   * The final values to be returned when using context values.
   */
  const value = useMemo(() => {
    return merge({}, defaultContext, theState, routeParams, {
      actionPath,
      currentStep,
      firstStep,
      lastStep,
      nextStep,
      previousStep,
      steps,
      update,
    });
  }, [
    actionPath,
    currentStep,
    defaultContext,
    firstStep,
    lastStep,
    nextStep,
    previousStep,
    routeParams,
    steps,
    theState,
  ]);

  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>;
}

ContextProvider.propTypes = {
  children: PropTypes.node,
  state: PropTypes.shape({}),
};

ContextProvider.defaultProps = {
  children: null,
  state: {},
};
