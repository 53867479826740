import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Popover } from '@abyss/web/ui/Popover';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useApi } from '@src/context/Api';
import { User } from '@src/features/Users/components/User';
import { isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { PopoverContent } from './components/PopoverContent';

/**
 * ExportSharePoint
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function ExportSharePoint(props) {
  const { currentEntity, exportDetails, exportRefetch, pollingRate, riskRecordCount, setPollingRate } = props;

  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { clearApiCache, useApiMutation } = useApi();

  const [ScheduleExport] = useApiMutation('ScheduleExport');

  /**
   * toggles the enabled/disabled status of the export button.
   */
  useEffect(() => {
    if (isNull(pollingRate) || !['RUNNING', 'SCHEDULED'].includes(exportDetails?.status)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [pollingRate, exportDetails]);

  /**
   * handleScheduleExport
   *
   * @TODO Needs description.
   *
   * @returns {Promise<void>}
   */
  const handleExport = async () => {
    setIsDisabled(true);
    await ScheduleExport(
      { actionPathId: currentEntity?.id },
      {
        onError: () => {
          clearApiCache(['GetExportDetails']);
          exportRefetch();
          setIsDisabled(false);
          setPollingRate(null);
        },
        onSuccess: () => {
          clearApiCache(['GetExportDetails']);
          exportRefetch();
          setIsDisabled(true);
          setPollingRate(5000);
        },
      }
    );
  };

  return (
    <ErrorHandler location="src/features/Risk/components/tables/RiskRecords/components/Header/components/ExportSharePoint/ExportSharePoint.jsx">
      <Popover
        align="start"
        alignOffset={50}
        content={<PopoverContent currentEntity={currentEntity} handleExport={handleExport} setIsOpen={setIsOpen} />}
        onOpenChange={() => {
          setIsOpen(!isOpen);
        }}
        open={isOpen}
        position="bottom"
        width={275}
      >
        <User.Capability attributes={['export']} resource="ActionPaths">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            before={
              <IconMaterial
                color={isDisabled ? 'var(--abyss-colors-gray5)' : 'var(--abyss-colors-interactive1)'}
                icon="file_upload"
              />
            }
            hideIcon
            isDisabled={isDisabled}
            onClick={async (event) => {
              event?.preventDefault();
              if (riskRecordCount > 1000000) {
                setIsOpen(true);
              } else {
                setIsDisabled(true);
                await handleExport();
              }
            }}
          >
            Export to SharePoint
          </Link>
        </User.Capability>
      </Popover>
    </ErrorHandler>
  );
}

ExportSharePoint.propTypes = {
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  exportDetails: PropTypes.shape({
    status: PropTypes.string,
  }),
  exportRefetch: PropTypes.func,
  pollingRate: PropTypes.number,
  riskRecordCount: PropTypes.number,
  setPollingRate: PropTypes.func,
};

ExportSharePoint.defaultProps = {
  currentEntity: {},
  exportDetails: {},
  exportRefetch: () => {},
  pollingRate: null,
  riskRecordCount: 0,
  setPollingRate: () => {},
};
