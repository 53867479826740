import { useRouter } from '@abyss/web/hooks/useRouter';
import { Divider } from '@abyss/web/ui/Divider';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import PropTypes from 'prop-types';
import React from 'react';

import { Styles } from './includes/styles';

/**
 * Modal: Deletion
 *
 * Provides the user with a modal to confirm the deletion of an action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Deletion(props) {
  const { actionPath, handleDeletion, isOpen, refetch, setActionPath, setIsOpen } = props;

  const router = useRouter();

  /**
   * handleDelete
   *
   * Sends the api request to delete the action path.
   *
   * @returns {Promise<void>}
   */
  const handleDelete = async () => {
    setIsOpen(false);
    setActionPath({});
    await handleDeletion(actionPath, refetch);
  };

  /**
   * handleClose
   *
   * Highlights the row in the table for the action path which was requested to activate, but the user backed out. The
   * goal is to make it easy to identify which row in the table the user attempted to take action on, in-case they
   * change their mind and want to continue with the same one again.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    setIsOpen(false);
    setActionPath({});
    router.navigate(`/`);
    router.navigate(`/action-paths?highlight=${actionPath?.id}`);
  };

  return (
    <ErrorHandler location="src/features/ActionPaths/components/modals/Deletion/Deletion.jsx">
      <Modal
        footer={
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button onClick={handleClose} variant="outline">
                Cancel
              </Button>
              <Button onClick={handleDelete} variant="solid">
                Delete
              </Button>
            </Layout.Group>
          </Modal.Section>
        }
        isOpen={isOpen}
        onClose={handleClose}
        title="Confirm Deletion"
      >
        <Styles>
          <Modal.Section>
            Are you sure you want to delete action path: <strong>{actionPath?.name}</strong> ?
          </Modal.Section>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
}

Deletion.propTypes = {
  actionPath: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  handleDeletion: PropTypes.func,
  isOpen: PropTypes.bool,
  refetch: PropTypes.func,
  setActionPath: PropTypes.func,
  setIsOpen: PropTypes.func,
};

Deletion.defaultProps = {
  actionPath: {},
  handleDeletion: () => {},
  isOpen: false,
  refetch: () => {},
  setActionPath: () => {},
  setIsOpen: () => {},
};
