import { AbyssTheme as themeConfiguration } from '@src/client';
import { chartColors } from '@src/components/Chart/includes/chartColors';
import { getTooltipFooter, getTooltipLabel, getTooltipTitle } from '@src/components/Chart/includes/functions';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { abbrNum } from '@src/includes/functions';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LogarithmicScale,
  Title,
  Tooltip,
} from 'chart.js';
import { isEmpty, isUndefined, shuffle } from 'lodash';
import pattern from 'patternomaly';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LogarithmicScale);

/**
 * HorizontalBarChart
 *
 * A horizontal bar chart is a graphical representation of data using horizontal bars o display the relative sizes or
 * frequencies of different categories or values.
 *
 * @param props
 * @returns {React.JSX.Element|`\nValue: ${string}\nPercentage: ${*}%\n`|*|string}
 * @constructor
 */
export function HorizontalBarChart(props) {
  const { data, dataSets, labels, legendLimit, showLegend } = props;

  /**
   * Defines an array of colors and patterns to use for rendering the datasets.
   *
   * @type {Array<CanvasPattern>}
   */
  const patterns = useMemo(() => {
    return pattern.generate(
      shuffle(chartColors).map((colorName) => {
        return themeConfiguration?.theme?.colors?.[`${colorName}`];
      })
    );
  }, [themeConfiguration]);

  const theDataSets = useMemo(() => {
    let items = [];
    if (!isEmpty(data)) {
      data?.forEach((item) => {
        items.push(Number(item?.value));
      });

      return [
        {
          backgroundColor: patterns,
          borderWidth: 1,
          data: items,
          grouped: false,
          label: '',
        },
      ];
    }
    if (!isEmpty(dataSets)) {
      items = dataSets;
    }

    return items;
  }, [data, dataSets]);

  const theLabels = useMemo(() => {
    let items = [];
    if (!isEmpty(data)) {
      data?.forEach((item) => {
        items.push(String(item?.name));
      });
    } else if (!isEmpty(labels)) {
      items = labels;
    }
    return items;
  }, [data, labels]);

  const options = useMemo(() => {
    const theOptions = {
      animation: {
        duration: 0, // general animation time
      },
      hover: {
        animationDuration: 0, // duration of animations when hovering an item
      },
      indexAxis: 'y',
      plugins: {
        legend: {
          display: isUndefined(showLegend) ? !isUndefined(legendLimit) && labels.length <= legendLimit : true,
        },
      },
      responsive: true,
      responsiveAnimationDuration: 0, // animation duration after a resize
      scales: {
        x: {
          ticks: {
            callback(value) {
              return `${abbrNum(Number(value), 2)}`;
            },
          },
        },
      },
    };

    if (!isEmpty(data)) {
      theOptions.plugins.tooltip = {
        callbacks: {
          footer(args) {
            return getTooltipFooter(args, data);
          },
          label(args) {
            return getTooltipLabel(args, data);
          },
          title(args) {
            return getTooltipTitle(args, data);
          },
        },
      };
    }

    return theOptions;
  }, [showLegend, legendLimit, labels, data]);

  return (
    <ErrorHandler location="src/components/Chart/components/HorizontalBarChart/HorizontalBarChart.jsx">
      <Bar
        data={{
          datasets: theDataSets,
          labels: theLabels,
        }}
        options={options}
      />
    </ErrorHandler>
  );
}

HorizontalBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  legendLimit: PropTypes.number,
  showLegend: PropTypes.bool,
};

HorizontalBarChart.defaultProps = {
  legendLimit: 10,
  showLegend: true,
};
