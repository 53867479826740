import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-tabs-root': {
    '.abyss-divider-root': {
      backgroundColor: 'var(--abyss-colors-gray3)',
      marginBottom: 'var(--abyss-space-lg)',
      marginTop: 'var(--abyss-space-md)',
    },
    '.abyss-tabs-tab-active-line': {
      borderColor: 'var(--abyss-colors-accent2)',
    },
    td: {
      '.abyss-layout-group': {
        display: 'none',
        label: {
          display: 'none',
          visibility: 'hidden',
        },
        visibility: 'hidden',
      },
    },
  },
});
