import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '> div': {
    '> .abyss-tooltip-trigger': {
      height: '100%',
      width: '100%',
    },
    height: '100%',
    width: '100%',
  },
});
