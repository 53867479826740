import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { ErrorHandler } from '@src/components/ErrorHandler';
import fields from '@src/features/ActionPaths/includes/fields.json';
import PropTypes from 'prop-types';
import React from 'react';

const { scopeCode: scopeCodeField } = fields;

/**
 * Field: ScopeCode
 *
 * Choose how the actions should be applied to the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function ScopeCode(props) {
  const { form } = props;

  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/fields/ScopeCode/ScopeCode.jsx">
      <RadioGroup
        {...scopeCodeField}
        css={{
          'abyss-radio-group-root': {
            marginTop: '0 !important',
          },
        }}
        onChange={() => {
          form?.validate(
            `remediation[actionPathScopeCode]`,
            () => {},
            () => {}
          );
        }}
      >
        {scopeCodeField?.options?.map((option) => {
          return <RadioGroup.Radio key={option.value} {...option} />;
        })}
      </RadioGroup>
    </ErrorHandler>
  );
}

ScopeCode.propTypes = {
  form: PropTypes.shape({
    validate: PropTypes.func,
  }),
};

ScopeCode.defaultProps = {
  form: {
    validate: () => {},
  },
};
