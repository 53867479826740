import { Accordion } from '@abyss/web/ui/Accordion';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Indicator } from '@abyss/web/ui/Indicator';
import { Layout } from '@abyss/web/ui/Layout';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isUndefined, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Table } from './components/Table';

/**
 * Attribute
 *
 * Displays reported information regarding the risk record's affiliated DOB or SSN.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Attribute(props) {
  const { options, riskRecord } = props;

  let attributeType = null;

  if (options?.accessor.includes('ssn')) {
    attributeType = 'ssn';
  }

  if (options?.accessor.includes('dob')) {
    attributeType = 'dob';
  }

  const ireRiskRecord = riskRecord?.ireRiskRecord;

  const [trustedValue, setTrustedValue] = useState('');
  const [attributeData, setAttributeData] = useState({});

  /**
   * store the attribute values and their respective sources and corresponding record IDs.
   */
  useEffect(() => {
    if (!isUndefined(ireRiskRecord?.remediationFindings)) {
      /**
       * find the attribute
       */
      const theAttribute = ireRiskRecord?.remediationFindings.find((attribute) => {
        return attribute.attributeType === attributeType;
      });

      const theTrustedValue = theAttribute?.trustedValue;

      const values = {
        [theTrustedValue]: [],
      };

      /**
       * store trusted sources and their respective record IDs with the trusted value
       */
      ireRiskRecord?.trustedRecordSources?.forEach((record) => {
        const trustedRecord = {
          id: record?.recordId,
          source: record?.source,
        };

        if (!values[theTrustedValue].includes(trustedRecord)) {
          values[theTrustedValue].push(trustedRecord);
        }
      });

      /**
       * store untrusted values
       */
      theAttribute?.untrustedRecordSources?.forEach((record) => {
        if (isUndefined(values[record?.value])) {
          values[record?.value] = [];
        }
      });

      /**
       * store untrusted sources and their respective record IDs with the untrusted values
       */
      theAttribute?.untrustedRecordSources?.forEach((record) => {
        const untrustedRecord = {
          id: record?.recordId,
          source: record?.source,
        };

        if (!values[record?.value].includes(untrustedRecord)) {
          values[record?.value].push(untrustedRecord);
        }
      });

      if (theTrustedValue !== trustedValue) {
        setTrustedValue(theTrustedValue);
      }

      if (values !== attributeData) {
        setAttributeData(values);
      }
    }
  }, [ireRiskRecord]);

  /**
   * accordionItems
   *
   * prepare the accordion items for the attribute.
   *
   * @type {*[]}
   */
  const accordionItems = useMemo(() => {
    return orderBy(
      Object.keys(attributeData).map((attributeValue) => {
        return {
          sourceCount: Object.keys(attributeData[attributeValue])?.length,
          sources: attributeData[attributeValue],
          type: String(attributeValue) === String(trustedValue) ? 'trusted' : 'untrusted',
          value: attributeValue,
        };
      }),
      ['type'],
      ['asc']
    );
  }, [attributeData]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/EidSearch/components/widgets/Attribute/Attribute.jsx">
      <Accordion isCollapsible type="multiple">
        {accordionItems?.map((item) => {
          const { sourceCount, sources, type, value } = item;

          return (
            <Accordion.Item key={`${item?.value}`} value={item?.value}>
              <Accordion.Trigger>
                <Accordion.Header>
                  <Layout.Group>
                    <div>
                      {type === 'trusted' ? (
                        <IconSymbol color="var(--abyss-colors-success1)" icon="check_circle" variant="outlined" />
                      ) : (
                        <IconSymbol color="var(--abyss-colors-error1)" icon="error" variant="outlined" />
                      )}
                    </div>
                    <div>
                      {sourceCount > 1 ? (
                        <div style={{ width: 'auto' }}>
                          <Indicator color="var(--abyss-colors-info1)" label={sourceCount} showZero={false} withBorder>
                            <div style={{ marginRight: 'var(--abyss-space-sm)' }}> {value}</div>
                          </Indicator>
                        </div>
                      ) : (
                        <React.Fragment>{value}</React.Fragment>
                      )}
                    </div>
                  </Layout.Group>
                </Accordion.Header>
              </Accordion.Trigger>
              <Accordion.Content>
                <Table rows={sources} type={type} />
              </Accordion.Content>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </ErrorHandler>
  );
}

Attribute.propTypes = {
  options: PropTypes.shape({
    accessor: PropTypes.string,
  }),
  riskRecord: PropTypes.shape({
    ireRiskRecord: PropTypes.shape({
      remediationFindings: PropTypes.arrayOf(
        PropTypes.shape({
          attributeType: PropTypes.string,
          trustedValue: PropTypes.string,
          untrustedRecordSources: PropTypes.arrayOf(
            PropTypes.shape({
              recordId: PropTypes.string,
              source: PropTypes.string,
            })
          ),
        })
      ),
      trustedRecordSources: PropTypes.arrayOf(
        PropTypes.shape({
          recordId: PropTypes.string,
          source: PropTypes.string,
        })
      ),
    }),
  }),
};

Attribute.defaultProps = {
  options: {},
  riskRecord: {},
};
