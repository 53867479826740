import { SelectInput } from '@abyss/web/ui/SelectInput';
import { ErrorHandler } from '@src/components/ErrorHandler';
import fields from '@src/features/ActionPaths/includes/fields.json';
import React from 'react';

const { exitCriteriaMethod: exitCriteriaMethodField } = fields;

/**
 * Field: ExitCriteriaMethod
 *
 * Select what defines the action path as resolved/completed.
 *
 * @returns {Element}
 * @constructor
 */
export function ExitCriteriaMethod() {
  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/fields/ExitCriteriaMethod/ExitCriteriaMethod.jsx">
      <SelectInput {...exitCriteriaMethodField} css={{ width: 'auto' }} />
    </ErrorHandler>
  );
}
