import { Alert } from '@abyss/web/ui/Alert';
import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isUndefined } from 'lodash';
import React from 'react';

import { Table } from './components/Table';

/**
 * Widget: Events
 *
 * Displays a list of events associated with an action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Events(props) {
  const { assets, data } = props;

  const events = data?.ListEvents?.data?.content;

  return (
    <ErrorHandler location="src/routes/private/Dashboards/Risk/components/widgets/Events/Events.jsx">
      <Grid>
        <Grid.Col
          span={{
            xs: '100%',
          }}
        >
          {!isUndefined(events) && !isEmpty(events) ? (
            <Table assets={assets} rows={events} />
          ) : (
            <Alert title="No events found in date range." variant="info" />
          )}
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
}

Events.propTypes = {};

Events.defaultProps = {};
