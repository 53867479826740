import { useRouter } from '@abyss/web/hooks/useRouter';
import { useScrollTrigger } from '@abyss/web/hooks/useScrollTrigger';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout as AbyssLayout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import { isNil, isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { Styles } from './includes/styles';
import { Header } from './sections/Header';
import { Main } from './sections/Main';
import { Sidebar } from './sections/Sidebar';

/**
 * Layout: Dashboard
 *
 * Glues together the various pieces of the layout.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Dashboard(props) {
  const { children, hasData, header, isFetching, isLoading, title, widgets } = props;

  const [isSidebarOpen, setIsSidebarOpen] = useState(null);
  const [currentTarget, setCurrentTarget] = useState(null);

  const { scrollIntoView, targetRef } = useScrollTrigger({ offset: 180 });

  const router = useRouter();
  const location = router.getLocation();
  const currentAccessor = location?.hash?.replace('#', '');

  /**
   * handles the click event for the expand/collapse button
   * @param value
   */
  const toggleSidebar = (value = null) => {
    if (!isNull(value)) {
      setIsSidebarOpen(value);
    } else {
      setIsSidebarOpen((prev) => {
        return !prev;
      });
    }
  };

  /**
   * reset when searching
   */
  useEffect(() => {
    setIsSidebarOpen(null);
  }, [isFetching]);

  /**
   * sets the current target to the current accessor
   */
  useLayoutEffect(() => {
    if (!isLoading && !isFetching && hasData && !isNil(currentAccessor) && currentAccessor != currentTarget) {
      setCurrentTarget(currentAccessor);
    }
  }, [isLoading, isFetching, hasData, currentAccessor]);

  /**
   * scrolls to the current target when the current target changes
   */
  useLayoutEffect(() => {
    scrollIntoView();
  }, [currentTarget, targetRef]);

  return (
    <ErrorHandler location="src/layouts/Dashboard/Dashboard.jsx">
      <Styles>
        <motion.div
          animate="open"
          initial={{ opacity: 0 }}
          variants={{
            closed: { opacity: 0 },
            open: { opacity: 1 },
          }}
        >
          <Grid id="layout-dashboard">
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Header
                components={header}
                hasData={hasData}
                isFetching={isFetching}
                isLoading={isLoading}
                title={title}
              />
            </Grid.Col>
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              {!isLoading && (
                <AbyssLayout.Group
                  alignItems="top"
                  css={{
                    '> div': {
                      height: '100%',
                    },
                    '> div:last-child': {
                      width: '100%',
                    },
                    height: '100%',
                  }}
                  space={themeConfiguration?.theme?.space?.md}
                >
                  <Sidebar
                    currentAccessor={currentAccessor}
                    hasData={hasData}
                    isFetching={isFetching}
                    isOpen={isSidebarOpen}
                    setCurrentTarget={setCurrentTarget}
                    toggleSidebar={toggleSidebar}
                    widgets={widgets}
                  />
                  <Main
                    currentTarget={currentTarget}
                    hasData={hasData}
                    isFetching={isFetching}
                    targetRef={targetRef}
                    widgets={widgets}
                  >
                    {children}
                  </Main>
                </AbyssLayout.Group>
              )}
            </Grid.Col>
          </Grid>
        </motion.div>
      </Styles>
    </ErrorHandler>
  );
}

Dashboard.propTypes = {
  children: PropTypes.node,
  hasData: PropTypes.bool,
  header: PropTypes.object,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  widgets: PropTypes.array,
};

Dashboard.defaultProps = {
  children: null,
  hasData: false,
  header: {
    LeftComponent: null,
    RightComponent: null,
  },
  isFetching: false,
  isLoading: false,
  title: '',
  widgets: [],
};
