import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { ViewsField } from '@src/routes/private/Analysis/RiskAnalysis/components/fields/Views';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * widget: Views
 *
 * The Views widget for the Risk Analysis screen.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Views(props) {
  const { assets, children, form } = props;

  return (
    <ErrorHandler location="src/routes/private/Analysis/RiskAnalysis/components/widgets/Views/Views.jsx">
      <Widget backgroundColor="var(--abyss-colors-gray1)" icon="visibility" title="Views">
        <Grid>
          <Grid.Col
            css={{ border: 'none !important' }}
            id="viewsContainer"
            span={{ md: '50%', sm: '100%', xs: '100%' }}
          >
            <Flex alignItems="end" justify="space-evenly">
              <div>
                <ViewsField assets={assets} form={form} />
              </div>
              {!isUndefined(children) && <div>{children}</div>}
            </Flex>
          </Grid.Col>
        </Grid>
      </Widget>
    </ErrorHandler>
  );
}

Views.propTypes = {
  assets: PropTypes.shape({
    ListViews: PropTypes.shape({}),
  }),
  children: PropTypes.node,
  form: PropTypes.shape({
    register: PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
  }),
};

Views.defaultProps = {
  assets: {},
  children: null,
  form: {},
};
