import { Divider } from '@abyss/web/ui/Divider';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Widget } from '@src/components/Widget';
import { DonutChart } from '@src/features/ActionPaths/components/charts/Donut';
import { isEmpty, omit, orderBy, sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Table } from '../../charts/Donut/components/Table';
import { Styles } from './includes/styles';

/**
 * Widget: InQueue
 *
 * Displays information about entity action path statuses.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function InQueue(props) {
  const { statusCodeCounts } = props;

  const [theData, setTheData] = useState({});
  const [noData, setNoData] = useState(false);

  /**
   * prepare data for display in the chart and the table
   */
  useEffect(() => {
    if (!isEmpty(statusCodeCounts)) {
      const labels = [];
      const data = [];

      Object.keys(omit(statusCodeCounts, ['total'])).forEach((status) => {
        if (!status.includes('Perc')) {
          labels.push(status);

          data.push({
            amount: statusCodeCounts[status],
            percent: statusCodeCounts[`${status}Perc`],
            type: status,
          });
        }
      });

      labels.sort((a, b) => {
        return a.localeCompare(b);
      });

      setTheData({ data: orderBy(data, ['type'], ['asc']), labels });
    }
  }, [statusCodeCounts]);

  /**
   * Check if there is no data to display
   */
  useEffect(() => {
    if (isEmpty(theData?.data) || (!isEmpty(theData?.data) && sumBy(theData?.data, 'amount') === 0)) {
      setNoData(true);
    } else {
      setNoData(false);
    }
  }, [theData?.data]);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/widgets/InQueue/InQueue.jsx">
      <Widget description="Entity action path statuses" title="In Queue">
        <Styles>
          {noData ? (
            <Layout.Stack alignLayout="center">
              <div>
                <IconSymbol color="$gray3" icon="bid_landscape_disabled" size="96" />
              </div>
              <div>
                <Text color="$gray4" fontWeight="bold">
                  Data Not Available
                </Text>
              </div>
            </Layout.Stack>
          ) : (
            <DonutChart data={theData?.data} labels={theData?.labels} />
          )}
          <Divider />
          <Table data={theData?.data} labels={theData?.labels} />
        </Styles>
      </Widget>
    </ErrorHandler>
  );
}

InQueue.propTypes = {
  statusCodeCounts: PropTypes.shape({
    active: PropTypes.number,
    activePerc: PropTypes.number,
    canceled: PropTypes.string,
    canceledPerc: PropTypes.number,
    invalidated: PropTypes.string,
    invalidatedPerc: PropTypes.number,
    resolved: PropTypes.string,
    resolvedPerc: PropTypes.number,
    total: PropTypes.number,
  }),
};

InQueue.defaultProps = {
  statusCodeCounts: {},
};
