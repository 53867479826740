import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { AnalyzeRisksButton } from '@src/features/Risk/components/buttons/AnalyzeRisks';
import PropTypes from 'prop-types';
import React from 'react';

import { ExportSharePoint } from './components/ExportSharePoint';
import { ViewSharePoint } from './components/ViewSharePoint';

/**
 * Header
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Header(props) {
  const {
    allowExport,
    criteria,
    currentEntity,
    exportDetails,
    exportRefetch,
    pollingRate,
    riskRecordCount,
    setPollingRate,
    showAnalyzeRisks,
  } = props;

  return (
    <ErrorHandler location="src/features/Risk/components/tables/RiskRecords/components/Header/Header.jsx">
      <Layout.Group
        alignItems="bottom"
        alignLayout="left"
        css={{ height: '100%' }}
        space={themeConfiguration?.theme?.space?.sm}
      >
        <Flex alignItems="top">
          {allowExport === true && [
            <div key="exportSharePoint">
              <ExportSharePoint
                currentEntity={currentEntity}
                exportDetails={exportDetails}
                exportRefetch={exportRefetch}
                pollingRate={pollingRate}
                riskRecordCount={riskRecordCount}
                setPollingRate={setPollingRate}
              />
            </div>,
            <div key="divider">
              <Divider height={24} orientation="vertical" width={1} />
            </div>,
          ]}

          {allowExport === true && (
            <div>
              <ViewSharePoint currentEntity={currentEntity} exportDetails={exportDetails} />
            </div>
          )}

          {allowExport === true && showAnalyzeRisks && (
            <div>
              <Divider height={24} orientation="vertical" width={1} />
            </div>
          )}

          {showAnalyzeRisks === true && (
            <div>
              <AnalyzeRisksButton criteria={criteria} variant="link" />
            </div>
          )}
        </Flex>
      </Layout.Group>
    </ErrorHandler>
  );
}

Header.propTypes = {
  allowExport: PropTypes.bool,
  criteria: PropTypes.shape({
    entrance: PropTypes.shape({
      additional: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    }),
  }),
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  exportDetails: PropTypes.shape({
    exportColumns: PropTypes.arrayOf(PropTypes.string),
    exportDescription: PropTypes.string,
    exportFilters: PropTypes.arrayOf(
      PropTypes.shape({
        column: PropTypes.string,
        condition: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
      })
    ),
    exportName: PropTypes.string,
    exportType: PropTypes.string,
  }),
  exportRefetch: PropTypes.func,
  pollingRate: PropTypes.number,
  riskRecordCount: PropTypes.number,
  setPollingRate: PropTypes.func,
  showAnalyzeRisks: PropTypes.bool,
};

Header.defaultProps = {
  allowExport: false,
  criteria: {
    entrance: {
      additional: [],
    },
  },
  currentEntity: {
    id: '',
    name: '',
  },
  exportDetails: {
    exportColumns: [],
    exportDescription: '',
    exportFilters: [],
    exportName: '',
    exportType: '',
  },
  exportRefetch: () => {},
  pollingRate: 0,
  riskRecordCount: 0,
  setPollingRate: () => {},
  showAnalyzeRisks: false,
};
