import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-table-root': {
    '.abyss-table-body': {
      '.abyss-table-row': {
        'td:last-child': {
          flex: 'auto !important',
        },
      },
    },
    '.abyss-table-head': {
      '.abyss-table-header': {
        'th:last-child': {
          flex: 'auto !important',
        },
      },
    },
  },

  '.disabled-bulk-actions': {
    backgroundColor: 'var(--abyss-colors-white) !important',
    borderColor: 'initial !important',
    borderImage: 'initial !important',
    borderRadius: '4px !important',
    borderStyle: 'none !important',
    borderWidth: 'initial !important',
    fontFamily: 'var(--abyss-fonts-primary) !important',
    fontSize: 'var(--abyss-fontSizes-sm) !important',
    fontWeight: 'var(--abyss-fontWeights-dropDownButtonFontWeight) !important',
    height: '24px !important',
  },

  '.eid-remove-button': {
    '.eid-remove-icon': {
      color: 'var(--abyss-colors-error1) !important',
    },
    '&:hover': {
      background: 'transparent !important',
    },
    background: 'transparent !important',
  },

  '*[class*="isDisabled-false"]': {
    '.abyss-icon': {
      color: 'var(--abyss-colors-error1) !important',
    },
  },
  '*[class*="isDisabled-true"]': {
    '.abyss-icon': {
      color: 'var(--abyss-colors-gray5) !important',
    },
  },
  '#test': {
    paddingLeft: '0px',
    paddingRight: '0px',
    position: 'absolute',
    top: '-56px',
  },
});
