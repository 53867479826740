import { Grid } from '@abyss/web/ui/Grid';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Loader } from '@src/components/Loader';
import { motion } from 'framer-motion';
import { isEmpty, isEqual, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { useWizardContext } from '../../../context';
import { Footer } from './Footer';
import { Header } from './Header';

/**
 * Layout
 *
 * Glues together the various pieces of the layout.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function Layout(props) {
  const { children, defaultValues, form, handleSubmit, isCurrentStepValid, isLoading } = props;
  const { action, actionPath } = useWizardContext();

  const containerRef = useRef();

  const formValues = form?.getValues();

  const hasActionPath = !isUndefined(actionPath) && !isEmpty(actionPath);
  const hasDefaultValues = isEqual(formValues, defaultValues);

  return (
    <ErrorHandler location="src/features/ActionPaths/components/misc/Wizard/components/misc/Layout/Layout.jsx">
      <motion.div
        animate="open"
        initial={{ opacity: 0 }}
        variants={{
          closed: { opacity: 0 },
          open: { opacity: 1 },
        }}
      >
        <Grid>
          {isLoading || (action === 'edit' && hasDefaultValues) || (action === 'edit' && !hasActionPath) ? (
            <Grid.Col
              span={{
                xs: '100%',
              }}
            >
              <Loader verticalAlignment="top" />
            </Grid.Col>
          ) : (
            [
              <Grid.Col
                key="header"
                span={{
                  xs: '100%',
                }}
              >
                <Header form={form} handleSubmit={form?.handleSubmit(handleSubmit, () => {})} />
              </Grid.Col>,
              <Grid.Col
                key="footer"
                span={{
                  xs: '100%',
                }}
              >
                <div ref={containerRef}>
                  {children}
                  <Footer
                    form={form}
                    handleSubmit={form?.handleSubmit(handleSubmit, () => {})}
                    isCurrentStepValid={isCurrentStepValid}
                  />
                </div>
              </Grid.Col>,
            ]
          )}
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  defaultValues: PropTypes.shape({}),
  form: PropTypes.shape({
    getValues: PropTypes.func,
    handleSubmit: PropTypes.func,
  }),
  handleSubmit: PropTypes.func,
  isCurrentStepValid: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Layout.defaultProps = {
  children: null,
  defaultValues: {},
  form: {},
  handleSubmit: () => {},
  isCurrentStepValid: true,
  isLoading: false,
};
