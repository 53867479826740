import { config } from '@abyss/web/tools/config';
import { Axios } from '@src/context/Api/includes/Axios';
import { logger } from '@src/includes/logger';
import { isEmpty, isUndefined } from 'lodash';

/**
 * SaveEvent
 *
 * Saves a single event with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const SaveEvent = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      data: {},
      headers: {
        'x-api-endpoint': '/events',
      },
      method: 'POST',
      params: { mutationKey: theMutationKey },
    };

    if (!isUndefined(thePayload?.eventId) && !isEmpty(thePayload?.eventId)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.eventId}`;
    }

    if (isUndefined(thePayload?.eventTrackerStatus)) {
      thePayload.eventTrackerStatus = 'INACTIVE';
    }

    requestArgs.data = { ...thePayload };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    logger.error('src/requests/mutations/SaveEvent.js -> SaveEvent()', false, error);

    throw error;
  }
};
