import { Chart } from '@src/components/Chart';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

/**
 * Widget: DobUntrustedSources
 *
 * Displays the number of DOB related untrusted sources that are with or without an action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export function DobUntrustedSources(props) {
  const { data } = props;

  const stats = useMemo(() => {
    const theStats = [];

    data?.GetUntrustedSourceCount?.data.forEach((item) => {
      let theItem = {
        countActiveActionPath: 0,
        countInactiveActionPath: 0,
        countNoActiveActionPath: 0,
        countTotal: 0,
        untrustedSource: item?.source,
      };

      const dob = item?.recordCounts.find((record) => {
        return record?.attributeType === 'DOB';
      });

      theItem = { ...theItem, ...(dob?.counts || {}) };

      theStats.push(theItem);
    });

    return theStats;
  }, [data?.GetUntrustedSourceCount?.data]);

  /**
   * Process the data and set the labels, red totals, and yellow totals.
   */
  const chartData = useMemo(() => {
    const theData = {
      dataSets: [],
      labels: [],
    };

    const theLabels = [];
    const actionPathTotals = [];
    const noActionPathTotals = [];

    if (!isEmpty(stats)) {
      const items = orderBy(stats, ['untrustedSource'], ['asc']);

      items?.forEach((item) => {
        if (!theLabels.includes(item?.untrustedSource)) {
          theLabels.push(item?.untrustedSource);
        }

        if (!actionPathTotals.includes(item?.countActiveActionPath)) {
          actionPathTotals.push(item?.countActiveActionPath);
        } else {
          actionPathTotals.push(0);
        }

        if (!noActionPathTotals.includes(item?.countNoActiveActionPath)) {
          noActionPathTotals.push(item?.countNoActiveActionPath);
        } else {
          noActionPathTotals.push(0);
        }
      });
    }

    theData.labels = theLabels;

    if (!isEmpty(actionPathTotals)) {
      theData.dataSets[0] = {
        backgroundColor: 'rgba(111, 193, 177, 0.75)',
        borderColor: 'rgba(0, 124, 137, 1.0)',
        borderWidth: 1,
        data: actionPathTotals,
        grouped: false,
        hoverBackgroundColor: 'rgba(0, 124, 137, 1.0)',
        hoverBorderColor: 'rgba(0, 124, 137, 1.0)',
        label: 'Action Path',
      };
    }

    if (!isEmpty(noActionPathTotals)) {
      theData.dataSets[1] = {
        backgroundColor: 'rgba(128, 97, 188, 0.75)',
        borderColor: 'rgba(66, 44, 136, 1.0)',
        borderWidth: 1,
        data: noActionPathTotals,
        grouped: false,
        hoverBackgroundColor: 'rgba(66, 44, 136, 1.0)',
        hoverBorderColor: 'rgba(66, 44, 136, 1.0)',
        label: 'No Action Path',
      };
    }

    return theData;
  }, [stats]);

  return (
    <ErrorHandler location="src/routes/private/Dashboards/Risk/components/widgets/DobUntrustedSources/DobUntrustedSources.jsx">
      <Chart
        dataSets={chartData?.dataSets}
        labels={chartData?.labels}
        legendLimit={15}
        tableConfiguration={{}}
        type="HORIZONTAL_BAR_CHART"
      />
    </ErrorHandler>
  );
}

DobUntrustedSources.propTypes = {
  data: PropTypes.shape({
    untrustedSourceStats: PropTypes.arrayOf(
      PropTypes.shape({
        countActiveActionPath: PropTypes.number,
        countNoActiveActionPath: PropTypes.number,
        untrustedSource: PropTypes.string,
      })
    ),
  }),
};

DobUntrustedSources.defaultProps = {
  data: {},
};
